import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/frameworks/vue/store";
import { Mutations } from "@/frameworks/vue/store/enums/StoreEnums";
import RouterIframeSync from "./RouterIframeSync.service";
import Services from "@services/Services";
import IsAppAdminMiddleware from "./middlewares/authorization/IsAppAdmin.middleware";
import Container from "typedi";
import UserService from "@services/user/User.service";
import { GraphqlInteractor } from "@interactors/graphql/Graphql.interactor";
import IntercomService from "@services/intercom/Intercom.service";
import HomeInteractor from "@interactors/home/Home.interactor";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        redirect: () => {
            const isAuthenticated =
                Container.get(UserService).isAuthenticated();

            return isAuthenticated ? "/home/personal-homepage" : "/auth/login";
        },
    },
    {
        path: "/home/personal-homepage",
        component: () => import("@/views/Home/Home.vue"),
    },
    {
        path: "/settings/home/personal-homepage",
        component: () => import("@/views/settings/home/HomeOptions.vue"),
    },

    {
        path: "/onboarding/first-time-setup",
        component: () => import("@/views/Onboarding/FirstTimeSetup.vue"),
    },
    {
        path: "/onboarding/advanced-guide",
        component: () => import("@/views/Onboarding/AdvancedGuide.vue"),
    },

    {
        path: "/picking-and-packing/picking-jobs",
        component: () => import("@/views/PickAndPack/PickingJobsView.vue"),
    },
    {
        path: "/picking-and-packing/picking-jobs/:jobId",
        component: () => import("@/views/PickAndPack/PickingJobView.vue"),
    },
    {
        path: "/picking-and-packing/packing-jobs",
        component: () => import("@/views/PickAndPack/PackingJobsView.vue"),
    },
    {
        path: "/picking-and-packing/stock-take",
        component: () => import("@/views/PickAndPack/StockTakeView.vue"),
    },

    {
        path: "/dashboard/overview",
        component: () => import("@/views/Dashboard/Dashboard.vue"),
    },
    {
        path: "/dashboard/orders-and-sales",
        component: () => import("@/views/Dashboard/Dashboard.vue"),
    },
    {
        path: "/settings/dashboard/overview",
        component: () =>
            import("@/views/settings/dashboard/DashboardOptions.vue"),
    },
    {
        path: "/settings/dashboard/orders-and-sales",
        component: () =>
            import("@/views/settings/dashboard/DashboardOptions.vue"),
    },
    {
        path: "/choose-app",
        component: () => import("@/views/account/ChooseApp.vue"),
    },
    {
        path: "/auth/login",
        component: () => import("@/views/account/AccountLogin.vue"),
    },
    {
        path: "/account/create",
        component: () => import("@/views/account/AccountCreate.vue"),
    },

    {
        path: "/cpanel",
        meta: {
            middleware: [IsAppAdminMiddleware],
        },
        component: () => import("@/views/admin/Cpanel.vue"),
    },
    {
        path: "/admin/support-tool",
        meta: {
            middleware: [IsAppAdminMiddleware],
        },
        component: () => import("@/views/support/SupportTool.vue"),
    },

    {
        path: "/:pathMatch(.*)",
        component: () => import("@/views/Blank.vue"),
    },

    {
        path: "/",
        component: () => import("@/views/Blank.vue"),
    },
    {
        path: "/bigcommerce-registartion-success",
        component: () => import("@/views/Bigcommerce/BigCommerceRedirect.vue"),
    },
    /*{
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/error/Error404.vue"),
    },*/
    /*{
        path: "/:pathMatch(.*)*",
        redirect: "/",
    },*/
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(async (to, from) => {
    Services.getDebug().log("ROUTER", "BEFORE", to, from);

    //route /onboarding because routing to /first-time-setup and then to /advanced-guide causes issues
    //or just using route /first-time-setup here causes getting locked out of first time setup guide if its completed
    if (to.path === "/onboarding/") {
        const onboardingProgress = await HomeInteractor.getOnboardingProgress();
        router.push("/onboarding/" + onboardingProgress.route);
    }

    if (/logout/.test(to.path)) {
        const user = Container.get(UserService).getUser();

        user.setAuthenticated(false);
        user.setAuthenticationChecked(false);
        localStorage.clear();
        sessionStorage.clear();

        //clear gql cache
        const client = GraphqlInteractor.getClient();
        client.clearStore();

        //logout intercom
        Container.get(IntercomService).logoutIntercom();
    }

    // reset config to initial state
    store.commit(Mutations.RESET_LAYOUT_CONFIG);
    //store.dispatch(Actions.VERIFY_AUTH);
    Container.get(RouterIframeSync).navigate(to.path);
    // Scroll page to top on every route change
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 100);
});

/**
 * This function might get invoked in multiple locations.
 * If you're trying to debug redirects, cause it's still messy,
 * then the current flow should be as follows:
 *   1. SetupProvider checks if not login route and validates if loggedin, invokes this functions, and handles redirect
 *   2. AccountLogin invokes this function and gets the next route and redirects as needed
 */
export const validateUserSetup = ({
    whereSell,
    whichCarriers,
    featuresLooking,
    planningProducts,
    planningMonthlyOrders,
}: {
    whereSell: string | null;
    whichCarriers: string | null;
    featuresLooking: string | null;
    planningProducts: number | null;
    planningMonthlyOrders: number | null;
}) => {
    const { path } = router.currentRoute.value;
    const isPostReg = /post-reg/.test(path);
    const isBigCommerce = /bigcommerce/.test(path);

    if (isBigCommerce || isPostReg) {
        return;
    }

    if (!planningMonthlyOrders && planningMonthlyOrders !== 0) {
        return "/post-reg-orders";
    }

    if (!planningProducts && planningProducts !== 0) {
        return "/post-reg-products";
    }

    if (!whereSell || !whichCarriers) {
        return "/post-reg-integrations";
    }

    if (!featuresLooking) {
        return "/post-reg-features";
    }
};

export default router;
