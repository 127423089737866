import DebugService from "@services/debug/Debug.service";
import NODE_ENV from "../constants/NODE_ENV";
import ConfigsModel from "../models/Configs.model";

export default class ConfigsModelBuilder {
    public static build(
        configsModel: ConfigsModel,
        debuger: DebugService
    ): void {
        this._buildEnv(configsModel, debuger);
    }

    private static _buildEnv(
        configsModel: ConfigsModel,
        debuger: DebugService
    ): void {
        debuger.log("CONFIGS_MODEL_BUILDER", "BUILDING", "ENV");

        const nodeEnv: NODE_ENV | undefined = process.env.VUE_APP_ENV as
            | NODE_ENV
            | undefined;

        debuger.log("CONFIGS_MODEL_BUILDER", "NODE_ENV", nodeEnv);

        if (nodeEnv === undefined) {
            return;
        }

        configsModel.setEnv(nodeEnv);
    }
}
