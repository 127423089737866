import { sha256 } from 'js-sha256';
import ICryptUtil from './types/ICryptUtil';
import { Service } from 'typedi';

@Service()
export default class CryptUtil implements ICryptUtil{
    // ---> Actions
    public hash(value: string): string {
        return sha256(value);
    }
}
