import axios, { AxiosRequestConfig } from "axios";
import IHttpClient from "./types/IHttpClient";
import IHttpClientOptions from "./types/IHttpClientOptions";
import { Service } from 'typedi';

@Service()
export default class HttpClientUtility implements IHttpClient{
    // ---> Actions
    public async send(options: IHttpClientOptions): Promise<any> {
        return await axios(options as AxiosRequestConfig);
    }
}
