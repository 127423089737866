import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-32c61596")
const _hoisted_1 = { class: "data-container data-container-table rounded overflow-hidden" }
const _hoisted_2 = { cellspacing: "0" }
const _hoisted_3 = { class: "table-header-row" }
const _hoisted_4 = { class: "table-header-row-cell fs-6 fw-bolder" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "table-header-row" }
const _hoisted_7 = { class: "table-header-row-cell fs-6 fw-bolder" }
const _hoisted_8 = { class: "table-body-row" }
const _hoisted_9 = ["onClick"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", _hoisted_2, [
      (_ctx.isArray(_ctx.tableData))
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("tr", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableData[0], (value, key, index) => {
                return _withDirectives((_openBlock(), _createElementBlock("th", {
                  key: String({ key: value }) + index
                }, [
                  _createElementVNode("div", _hoisted_4, _toDisplayString(key), 1)
                ], 512)), [
                  [_vShow, String(key) !== '__typename']
                ])
              }), 128))
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableData, (accountDataBlock, index) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: String(accountDataBlock) + index,
                class: "table-body-row"
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(accountDataBlock, (value, key, index) => {
                  return _withDirectives((_openBlock(), _createElementBlock("td", {
                    key: String({ key: value }) + index
                  }, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["table-body-row-cell fs-7", [{ 'fw-bold': (value === undefined || value === null || value === '') }]])
                    }, [
                      (_ctx.isObjectOrArray(value))
                        ? (_openBlock(), _createElementBlock("span", {
                            key: 0,
                            onClick: ($event: any) => (_ctx.handleOpenDataModal(key, value)),
                            class: "p-2 bg-blue border border-light rounded-circle"
                          }, null, 8, _hoisted_5))
                        : _createCommentVNode("", true),
                      _createTextVNode(" " + _toDisplayString(value === undefined || value === null || value === '' ? "NULL" :
                                _ctx.getDateIfTimestamp(key, value)), 1)
                    ], 2)
                  ], 512)), [
                    [_vShow, String(key) !== '__typename']
                  ])
                }), 128))
              ]))
            }), 128))
          ], 64))
        : (_ctx.isObject(_ctx.tableData))
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createElementVNode("tr", _hoisted_6, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableData, (value, key, index) => {
                  return _withDirectives((_openBlock(), _createElementBlock("th", {
                    key: String({ key: value }) + index
                  }, [
                    _createElementVNode("div", _hoisted_7, _toDisplayString(key), 1)
                  ], 512)), [
                    [_vShow, String(key) !== '__typename']
                  ])
                }), 128))
              ]),
              _createElementVNode("tr", _hoisted_8, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableData, (value, key, index) => {
                  return _withDirectives((_openBlock(), _createElementBlock("td", {
                    key: String({ key: value }) + index
                  }, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["table-body-row-cell fs-7", [{ 'fw-bold': (value === undefined || value === null || value === '') }]])
                    }, [
                      (_ctx.isObjectOrArray(value))
                        ? (_openBlock(), _createElementBlock("span", {
                            key: 0,
                            onClick: ($event: any) => (_ctx.handleOpenDataModal(key, value)),
                            class: "p-2 bg-blue border border-light rounded-circle"
                          }, null, 8, _hoisted_9))
                        : _createCommentVNode("", true),
                      _createTextVNode(" " + _toDisplayString(value === undefined || value === null || value === '' ? "NULL" :
                                _ctx.getDateIfTimestamp(key, value)), 1)
                    ], 2)
                  ], 512)), [
                    [_vShow, String(key) !== '__typename']
                  ])
                }), 128))
              ])
            ], 64))
          : _createCommentVNode("", true)
    ])
  ]))
}