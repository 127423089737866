import { Service } from "typedi";

@Service()
export default class FormatsUtility {
    // ---> Actions
    public abbreviateNumber(x: number): {
        value: string;
        suffix: string;
        isFormatted: boolean;
    } {
        let value: string;
        let suffix: string;
        let isFormatted: boolean;

        if (x >= 1000 && x < 1000000) {
            value = (x / 1000).toFixed(2);
            suffix = "k";
            isFormatted = true;
        } else if (x >= 1000000 && x < 1000000000) {
            value = (x / 1000000).toFixed(2);
            suffix = "m";
            isFormatted = true;
        } else if (x >= 1000000000 && x < 1000000000000) {
            value = (x / 1000000000).toFixed(2);
            suffix = "b";
            isFormatted = true;
        } else {
            value = String(x);
            suffix = "";
            isFormatted = false;
        }

        return {
            value,
            suffix,
            isFormatted,
        };
    }
}
