import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTLoader = _resolveComponent("KTLoader")!
  const _component_search = _resolveComponent("search")!
  const _component_KTAsideOld = _resolveComponent("KTAsideOld")!
  const _component_KTAsideNew = _resolveComponent("KTAsideNew")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_KTLoader, {
      class: _normalizeClass({ 'loader-hidden': !_ctx.loaderEnabled }),
      logo: _ctx.loaderLogo
    }, null, 8, ["class", "logo"]),
    _createVNode(_component_search),
    (_ctx.asideEnabled && _ctx.isUiToggleEnabled)
      ? (_openBlock(), _createBlock(_component_KTAsideOld, {
          key: 0,
          lightLogo: _ctx.themeLightLogo,
          darkLogo: _ctx.themeDarkLogo
        }, null, 8, ["lightLogo", "darkLogo"]))
      : (_ctx.asideEnabled)
        ? (_openBlock(), _createBlock(_component_KTAsideNew, {
            key: 1,
            lightLogo: _ctx.themeLightLogo,
            darkLogo: _ctx.themeDarkLogo
          }, null, 8, ["lightLogo", "darkLogo"]))
        : _createCommentVNode("", true)
  ], 64))
}