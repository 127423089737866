import LOCALSTORAGE_KEYS from "../constants/LOCALSTORAGE_KEYS";
import { Service, Inject } from 'typedi';
import CryptUtil from "@utilities/Crypt/Crypt.utility";
import ConfigsService from "@services/configs/Configs.service";

@Service()
export default class LocalStorageKeysModule {
    public constructor(
        @Inject() private _cryptUtility: CryptUtil,
        @Inject() private _configsService: ConfigsService,
    ) {}

    // ---> Variables
    private _keys: Map<LOCALSTORAGE_KEYS, string> = new Map();

    // ---> Getters
    public getByType(type: LOCALSTORAGE_KEYS): string {
        if (typeof type !== 'number') {
            return '';
        }

        if (Number.isNaN(type) || !Number.isFinite(type)) {
            return '';
        }

        let key: string | undefined = this._keys.get(type);

        if (key === undefined) {
            key = this.genKeyByType(type);
            this._keys.set(type, key);
        }
        
        return key;
    }

    // ---> Actions
    private genKeyByType(type: LOCALSTORAGE_KEYS): string {
        if (typeof type !== 'number') {
            return '';
        }

        if (this._configsService.get().isTesting()) {
            return String(type);
        }

        if (Number.isNaN(type) || !Number.isFinite(type)) {
            return '';
        }

        const sugar = 'k2UD2v+U3!JAg6hD*E5=*Q-FBEtJvKvqjDZnQzH_';
        const idRaw = type + sugar;
        const key = this._cryptUtility.hash(idRaw);

        return key;
    }
}
