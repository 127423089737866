import IBase64Util from './types/IBase64Util';
import { Service } from 'typedi';

@Service()
export default class Base64Util implements IBase64Util {
    // ---> Actions
    public encode(value: string): string {
        return btoa(value);
    }

    public decode(value: string): string {
        return atob(value);
    }
}
