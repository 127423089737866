import ConfigsService from "@services/configs/Configs.service";
import LocalStorageService from "@services/local-storage/LocalStorage.service";
import HttpClientUtility from "@utilities/http-client/HttpClient.utility";
import IHttpClientOptions from "@utilities/http-client/types/IHttpClientOptions";
import { Inject, Service } from "typedi";
import REQUEST_METHODS from "../constants/REQUEST_METHODS";

@Service()
export default class ApiTokenModule {
    public constructor(
        @Inject() private _httpClientUtility: HttpClientUtility,
        @Inject() private _localStorageService: LocalStorageService,
        @Inject() private _configsService: ConfigsService
    ) {}

    // ---> Actions
    public async send(
        method: REQUEST_METHODS,
        endpoint: string,
        needToken: boolean,
        data?: any
    ): Promise<any> {
        return await this.request(method, endpoint, needToken, data);
    }

    private async request(
        method: REQUEST_METHODS,
        endpoint: string,
        needToken: boolean,
        data?: any
    ): Promise<any> {
        try {
            const headers = this.getDefaultHeader(needToken);

            const options: IHttpClientOptions = {
                method,
                url: `${
                    this._configsService.getByEnv().apiExpressAddress
                }${endpoint}`,
                data,
                headers,
            };

            return await this._httpClientUtility.send(options);
        } catch (e) {
            console.error("API_SERVICE", e);

            return null;
        }
    }

    private applyAuthorizationHeader(header: any): boolean {
        const token: string | null = this._localStorageService.getUserToken();

        if (token === null) {
            return false;
        }

        header["Authorization"] = `Bearer ${token}`;

        return true;
    }

    private getDefaultHeader(needToken: boolean): any {
        const header = {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
        };

        if (!needToken) {
            return header;
        }

        const hasAccessKey = this.applyAuthorizationHeader(header);

        if (!hasAccessKey) {
            throw Error("Api Service: AccessKey required");
        }

        return header;
    }
}
