enum SITE_USER_ROLES {
    APP_ADMIN,
    ADMIN,
    OWNER,
    INVENTORY_MANAGER,
    ORDERS_MANAGER,
    MANAGER,
    ORDERS_INVENTORY_MANAGER,
    NO_ROLE,
}

export const SITE_USER_ROLE_LIST = [
    "app_admin",
    "admin",
    "owner",
    "inventory_manager",
    "orders_manager",
    "manager",
    "orders_inventory_manager",
    "no_role",
] as const;

export default SITE_USER_ROLES;
