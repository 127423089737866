import { Mutations } from "@frameworks/vue/store/enums/StoreEnums";
import VueFramework from "@frameworks/vue/Vue.framework";
import ApiService from "@services/api/Api.service";
import ConfigsService from "@services/configs/Configs.service";
import DeviceService from "@services/device/Device.service";
import IframeService from "@services/iframe/Iframe.service";
import LocalStorageService from "@services/local-storage/LocalStorage.service";
import UserService from "@services/user/User.service";
import axios, { AxiosResponse } from "axios";
import { Inject, Service } from "typedi";
import jwt_decode from "jwt-decode";
import LoginModule from "./Login.module";
import WindowService from "@services/window/Window.service";
import router from "@frameworks/vue/router/clean";

interface IV3LoginData {
    email: string;
    password: string;
    deviceId: string;
}

interface IV3LoginResponse {
    tokenV1: string;
    tokenV2: string;
    tokenV3: string;
}

interface IV3TokenData {
    deviceId: number;
    email: string;
    role: string;
    roleEnum: number;
    siteId: number;
    tokenV1: string;
    userId: number;
    appVersion: number;
}

@Service()
export default class LoginV3Module extends LoginModule {
    private static readonly LOGIN_ENDPOINT = "/authentication/login";

    constructor(
        @Inject() protected readonly _userService: UserService,
        @Inject() protected readonly _apiService: ApiService,
        @Inject(() => IframeService)
        protected readonly _iFrameService: IframeService,
        @Inject() protected readonly _deviceService: DeviceService,
        @Inject() protected readonly _localStorageService: LocalStorageService,
        @Inject() protected readonly _vueFramework: VueFramework,
        @Inject() protected readonly _windowService: WindowService,
        @Inject() protected readonly _configService: ConfigsService
    ) {
        super(
            _userService,
            _apiService,
            _iFrameService,
            _deviceService,
            _localStorageService,
            _vueFramework,
            _windowService
        );
    }
    public async loginV3(
        email: string,
        password: string,
        registration = false
    ) {
        try {
            const response = await axios.post<
                IV3LoginData,
                AxiosResponse<IV3LoginResponse>
            >(
                `${this._configService.getByEnv().v3API}${
                    LoginV3Module.LOGIN_ENDPOINT
                }`,
                {
                    email,
                    password,
                    deviceId: this._deviceService.getDeviceId(),
                }
            );
            const { tokenV1, tokenV2, tokenV3 } = response.data;
            const {
                userId,
                email: _email,
                appVersion,
                roleEnum,
                siteId,
            } = jwt_decode<IV3TokenData>(tokenV3);
            const wixData = this.getWixLoginDetails();
            const shopifyData = this.getShopifyLoginDetails();
            const bigCommerceData = this.getBigCommerceLoginDetails();
            let integrationType: "WIX" | "SHOPIFY" | "BIGCOMMERCE" | undefined;

            this._localStorageService.setV3Token(tokenV3);
            this._localStorageService.setSiteId(`${siteId}`);
            this.updateCredentials({
                accessKey: tokenV2,
                userId,
                token: tokenV1,
            });

            // ! Deprecated
            // await this.handleAppversion(tokenV2, tokenV1, userId, appVersion);

            this.updateUserModel({
                email: _email,
                userId,
                role: roleEnum,
                appVersion,
            });

            this._iFrameService.emitLogin({
                accessKey: tokenV2,
                token: tokenV1,
                userId,
                email: _email,
                appVersion,
                role: roleEnum,
                reg: registration,
                integration: !!wixData || !!shopifyData || !!bigCommerceData,
            });

            if (wixData) {
                integrationType = "WIX";
                this._iFrameService.emitWixData({
                    reg: registration,
                    wixData,
                });
            }

            if (shopifyData) {
                integrationType = "SHOPIFY";
                this._iFrameService.emitShopifyData({
                    ...shopifyData,
                    userId,
                    siteId,
                });
            }

            if (bigCommerceData) {
                integrationType = "BIGCOMMERCE";
                this._iFrameService.emitBigCommerceData({
                    reg: registration,
                    bcData: bigCommerceData,
                });
            }

            this._vueFramework.getStore().commit(Mutations.SET_ERROR, []);

            if (registration) {
                if (integrationType === "BIGCOMMERCE") {
                    router.push("/bigcommerce-registartion-success");
                } else {
                    router.push("/post-reg-orders");
                }
            }

            return {
                integration: integrationType,
            };
        } catch (error) {
            console.error(error);
            this._vueFramework
                .getStore()
                .commit(Mutations.SET_ERROR, ["Bad credentials"]);

            throw error;
        }
    }

    private getWixLoginDetails() {
        const params = new URLSearchParams(window.location.search);
        const wixIntegration = params.get("wixIntegration");
        const refreshToken = params.get("refreshToken");
        const instanceId = params.get("instanceId");

        if (!refreshToken || !instanceId || !wixIntegration) {
            return;
        }

        return {
            refreshToken,
            instanceId,
        };
    }

    private getShopifyLoginDetails() {
        const params = new URLSearchParams(window.location.search);
        const shop = params.get("shop");
        const email = params.get("email");
        const shopName = params.get("shopName");
        const accessToken = params.get("accessToken");

        if (!shop || !email || !shopName || !accessToken) {
            return;
        }

        return {
            shop,
            email,
            shopName,
            accessToken,
        };
    }

    private getBigCommerceLoginDetails() {
        const params = new URLSearchParams(window.location.search);
        const bigcommerceIntegration = params.get("bigcommerceIntegration");
        const accessToken = params.get("access_token");
        const storeHash = params.get("store_hash");

        if (!bigcommerceIntegration || !accessToken || !storeHash) {
            return;
        }

        return {
            accessToken,
            storeHash,
        };
    }
}
