
import { defineComponent } from "vue";
import Container from "typedi";
import UserService from "@services/user/User.service";
import gql from "graphql-tag";
import { provideApolloClient, useLazyQuery } from "@vue/apollo-composable";
import { validateUserSetup } from "@frameworks/vue/router/clean";
import { GraphqlInteractor } from "@interactors/graphql/Graphql.interactor";

type UserSetupQueryResponse = {
    me: {
        id: string;
        site: {
            id: string | null;
            whereSell: string | null;
            whichCarriers: string | null;
            featuresLooking: string | null;
            planningProducts: number | null;
            planningMonthlyOrders: number | null;
        };
    };
};

const userSetupQuery = gql`
    query UserSetupQuery {
        me {
            id
            site {
                id
                whereSell
                whichCarriers
                featuresLooking
                planningProducts
                planningMonthlyOrders
            }
        }
    }
`;

export default defineComponent({
    name: "SetupProvider",
    watch: {
        $route() {
            const isLoggedIn = Container.get(UserService).isAuthenticated();

            if (!isLoggedIn || this.setupFinished) {
                return;
            }

            // Vue is dumb
            // watchers fire before any context is initiated
            // so it does not have a reference to the client here still
            // and the method `beforeRouteUpdate` does not work on this component
            // because it's not within routing range
            provideApolloClient(GraphqlInteractor.getClient());
            this.checkIsUserSetup();
        },
    },
    data() {
        return {
            setupFinished: false,
        };
    },
    methods: {
        checkIsUserSetup() {
            const { onResult, load } = useLazyQuery<
                UserSetupQueryResponse,
                void
            >(userSetupQuery, undefined, {
                fetchPolicy: "network-only",
            });

            onResult((result) =>
                this.validateMissingSetupOptions(result.data.me.site)
            );

            load();
        },
        validateMissingSetupOptions(
            site: UserSetupQueryResponse["me"]["site"]
        ) {
            const route = validateUserSetup(site);

            if (!route) {
                this.setupFinished = true;
                return;
            }

            if (/features/.test(route)) {
                this.setupFinished = true;
            }

            this.$router.push(route);
        },
    },
});
