
import { defineComponent } from "vue";
import { useLazyQuery } from "@vue/apollo-composable";
import { SMARTLOOK_QUERY } from "./SmartlookQueries";
import SmartlookService from "@services/smartlook/smartlook.service";
import Container from "typedi";

export default defineComponent({
    name: "SmartlookProvider",
    watch: {
        $route(to, from) {
            if (this.isComponentMounted) {
                this.$nextTick(() => {
                    this.handleSmartlook();
                })
            }
        },
    },
    setup() {
        return {
            isComponentMounted: false,
        };
    },
    mounted() {
        this.handleSmartlook();
        this.isComponentMounted = true;
    },
    methods: {
        //this function checks if the site has <= 1000015 ordersCount, if he does we run smartlook, if not - we don't run smartlook
        handleSmartlook() {
            const smartlookQuery = useLazyQuery(SMARTLOOK_QUERY, {
                fetchPolicy: "network-only",
            });

            smartlookQuery.onResult((intercomQueryResult: any) => {
                const siteOrdersCount = intercomQueryResult.data.me.site.siteOrdersCount;

                if (siteOrdersCount === undefined || siteOrdersCount === null) {
                    return;
                }

                if (siteOrdersCount <= 1000015) {
                    const siteId = intercomQueryResult.data.me.site.id;
                    const email = intercomQueryResult.data.me.email;
                    Container.get(SmartlookService).bootSmartlook(siteId, email);
                }
            });

            smartlookQuery.onError((e) => {
                return;
            });

            smartlookQuery.load();
        },
    },
});
