import ApiService from "@frameworks/metronic/core/services/ApiService";
import JwtService from "@frameworks/metronic/core/services/JwtService";
import { Actions, Mutations } from "@/frameworks/vue/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface System {
    loaded: boolean,
}

@Module
export default class SysModule extends VuexModule {
    private _system = {
        loaded: false,
    };

    /**
     * Get system one time loaded state
     * @returns User
     */
    get isSysLoaded(): boolean {
        return this._system.loaded;
    }

    @Mutation
    [Mutations.SET_SYSTEM_LOADED] (state: boolean) {
        this._system.loaded = state;
    }
}
