
import { computed, defineComponent, onMounted, inject } from "vue";
import { useStore } from "vuex";
import KTLayout from "@components/layout/Layout.vue";
import KTLayoutWrapped from "@components/layout/LayoutWrapped.vue";
import { Mutations } from "@frameworks/vue/store/enums/StoreEnums";
import Modals from "./components/modals/Modals.vue";
import IFrameInteractor from "@interactors/iframe/Iframe.interactor";
import RoutingInteractor from "@interactors/routing/Routing.interactor";
import IntercomProvider from "@components/IntercomProvider.vue";
import SetupProvider from "./views/Setup/SetupProvider.vue";
import SmartlookProvider from "@components/SmartlookProvider.vue";

export default defineComponent({
    name: "app",
    components: {
        KTLayout,
        KTLayoutWrapped,
        Modals,
        IntercomProvider,
        SetupProvider,
        SmartlookProvider
    },
    watch: {
        $route(to) {
            if (to.path === "/auth/login") {
                window.sessionStorage.clear();
                window.localStorage.clear();
            }
            this.sectionsGroup = RoutingInteractor.getSectionSubsections();
        },
    },
    setup() {
        const store = useStore();
        store.getters.isSysLoaded;

        onMounted(() => {
            store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);
        });

        return {
            isSysLoaded: computed(() => store.getters.isSysLoaded),
            sectionsGroup: RoutingInteractor.getSectionSubsections(),
        };
    },
    data() {
        let $mitt;
        return {
            $mitt
        };
    },
    mounted() {
        this.$mitt = inject("$mitt");
    },
    methods: {
        isIframeEnabled: function (): boolean {
            return IFrameInteractor.isIframeEnabled(this.$route.path);
        },
    },
});
