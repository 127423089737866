import { Service } from "typedi";
import smartlookClient from "smartlook-client";

@Service()
export default class SmartlookService {
    private app_id = "4a772e05ba62272f6a7f9982b27ada1bb5dc23e7";
    private tmp_app_id = "89468d0430b12e443d216fb916cfa13a3d764f87";

    public bootSmartlook(siteId, email): void {
        smartlookClient.init(this.tmp_app_id, {
            region: "eu",
            cookies: true,
        });

        smartlookClient.identify(siteId, {
            email: email,
        });

        smartlookClient.record({
            forms: true,
            ips: true,
            emails: true,
            numbers: true,
        });
        return;
    }
}
