import Fuse from "fuse.js";

export default class SearchInterpreter {
    public fuse: Fuse<any>;

    constructor(data, options) {
        this.fuse = new Fuse(data, options);
    }

    public search(value) {
        const result = this.fuse.search(value);
        const combinedSearch = [];
        const reducedSearch = result.reduce((ac, i) => {
            if (!ac[i.item.category]) {
                ac[i.item.category] = [];
            }

            ac[i.item.category].push(i.item);

            return ac;
        }, {});

        Object.keys(reducedSearch).forEach(key => {
            (combinedSearch as any).push({
                category: key,
                items: reducedSearch[key],
            });
        });

        return combinedSearch;
    }
}
