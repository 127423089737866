import { createApp } from "@vue/runtime-dom";
import VueModel from "../models/Vue.model";
import AppView from "@/App.vue";
import { Service } from "typedi";
import { DefaultApolloClient } from "@vue/apollo-composable";
import { GraphqlInteractor } from "@interactors/graphql/Graphql.interactor";
import { h, provide } from "vue";

@Service()
export default class VueModelBuilder {
    public build(): VueModel {
        const vueModel = new VueModel();
        const app = createApp({
            setup() {
                provide(DefaultApolloClient, GraphqlInteractor.getClient());
            },
            render: () => h(AppView),
        });

        vueModel.setApp(app);

        return vueModel;
    }
}
