import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "./modules/AuthModule";
import BodyModule from "./modules/BodyModule";
import BreadcrumbsModule from "./modules/BreadcrumbsModule";
import ConfigModule from "./modules/ConfigModule";
import SearchModule from "@/frameworks/vue/store/modules/SearchModule";
import UserModule from "./modules/user/User.module";
import SysModule from "./modules/system/SysModule";

config.rawError = true;

const store = createStore({
  modules: {
    UserModule,
    SysModule,
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    SearchModule,
  },
});

export default store;
