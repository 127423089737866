import IConfigsMo from "@services/configs/types/IConfigsMo";

const CONFIGS_DEVELOP: IConfigsMo = {
    iframeViewAddress: "http://localhost:8080",
    apiExpressAddress: "http://localhost:9080/api/v2",
    v3API: "http://localhost:3000",
    v1API: "http://0.0.0.0:4567/api",
    featureFlagAddress: "https://stagingserverapiv3.multiorders.com",
};

export default CONFIGS_DEVELOP;
