import LocalStorageService from "@services/local-storage/LocalStorage.service";
import dayjs from "dayjs";
import axios from "axios";
import ConfigsService from "@services/configs/Configs.service";
import Container, { Service } from "typedi";

interface IGeneralStat {
    name: string;
    value: number;
    href: string;
}
interface IGeneralStatistics {
    generalStatistics: IGeneralStat[];
}

interface IStatusStat {
    name: string;
    value: number;
    href: string;
}
interface IStatusStatistics {
    data: IStatusStat[];
}

interface IAverageStat {
    name: string;
    data: number[];
}
interface IAverageStatistics {
    currency: string;
    averageStatistics: IAverageStat[];
}

interface IBarChartSeries {
    name: string;
    data: number[];
}
interface IBarChartStatistics {
    currency: string;
    categories: Array<string>;
    series: IBarChartSeries[];
    total: number;
}

interface IDonutChartStatistics {
    currency?: string;
    series: number[];
    labels: string[];
    headerLegendName: string;
    headerValueName: string;
}

interface IHeatmapSeriesData {
    x: string;
    y: number;
}
interface IHeatmapChartSeries {
    name: string;
    data: IHeatmapSeriesData[];
}
interface IHeatmapChartStatistics {
    min: number;
    max: number;
    series: IHeatmapChartSeries[];
}

//statistics will contain all fetchers to statistic endpoints
//replace promises with fetch()
@Service()
export default class StatisticsInteractor {
    public static getChannels(): Promise<string[] | Error> {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                const channels = ["Amazon", "Spotify", "Etsy", "WooCommerce"];

                resolve(channels);
            }, 1450);
        });
    }

    public static getGeneralStatistics(
        channels: Array<string>,
        dateRange: Array<dayjs.Dayjs>
    ): Promise<IGeneralStatistics | Error> {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                const generalStatistics = [
                    { name: "Automation Runs", value: 115, href: "/" },
                    {
                        name: "Automatic Products Updates",
                        value: 1345,
                        href: "/",
                    },
                    { name: "Automatic Bundle Updates", value: 326, href: "/" },
                    { name: ".csv Imported / Exported", value: 2, href: "/" },
                    { name: "Orders Merged", value: 3, href: "/" },
                    { name: "Items Tagged", value: 36, href: "/" },
                ];

                resolve({ generalStatistics });
            }, 1400);
        });
    }

    public static getStatusStatistics(
        channels: Array<string>,
        dateRange: Array<dayjs.Dayjs>
    ): Promise<IStatusStatistics | Error> {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                const title = "Today's Order Status";
                const data = [
                    { name: "New", value: 6, href: "/order-list/new/1" },
                    { name: "Prepared", value: 3, href: "/order-list/new/1" },
                    {
                        name: "In-Progress",
                        value: 15,
                        href: "/order-list/in-progress/1",
                    },
                    {
                        name: "Pending",
                        value: 3,
                        href: "/order-list/pending/1",
                    },
                    {
                        name: "Shipped",
                        value: 15,
                        href: "/order-list/dispatched/1",
                    },
                ];

                resolve({ data });
            }, 2500);
        });
    }

    public static getAverageStatistics(
        channels: Array<string>,
        dateRange: Array<dayjs.Dayjs>
    ): Promise<IAverageStatistics | Error> {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                //get currency from existing user data?
                const currency = "$";
                const averageStatistics = [
                    { name: "Avg Order Value", data: [10, 15, 17, 80, 30] },
                    { name: "Avg Product Value", data: [100, 15, 17, 80, 30] },
                    {
                        name: "Avg Product Qty Per Order",
                        data: [50, 15, 17, 80, 30],
                    },
                ];

                resolve({
                    currency,
                    averageStatistics,
                });
            }, 1000);
        });
    }

    public static getBarChartStatistics(
        channels: Array<string>,
        dateRange: Array<dayjs.Dayjs>
    ): Promise<IBarChartStatistics | Error> {
        return new Promise((resolve, reject) => {
            const _configService = Container.get(ConfigsService);
            const _localStorageService = Container.get(LocalStorageService);

            const apiV1Url = _configService.getByEnv().v1API;
            const accessToken = _localStorageService.getUserToken();
            const userId = _localStorageService.getUserId();
            let siteId = null;
            let currency = "";

            const dates = JSON.parse(
                sessionStorage.getItem("dashboard-date-period") || "[]"
            );
            let fromDate = dates[0];
            let toDate = dates[1];
            const categories: any = [];
            const diff = dayjs(dates[1]).diff(dates[0], "days");
            const series: IBarChartSeries[] = [];
            const defaultData: any = [];
            const oneDayChart = dayjs(dates[0]).diff(dates[1], "days") === 0;

            if (oneDayChart) {
                fromDate = dayjs(fromDate).startOf("day").toString();
                toDate = dayjs(toDate).endOf("day").toString();
                for (let i = 0; i < 24; i++) {
                    categories.push(`${i < 10 ? 0 : ""}${i}:00`);
                    defaultData.push(0);
                }
            } else {
                let offset = 0;
                while (
                    dayjs(dayjs(dates[0]).add(offset, "days")).diff(
                        dates[1],
                        "days"
                    ) <= 0
                ) {
                    categories.push(
                        dayjs(dates[0])
                            .add(offset, "days")
                            .format(diff > 7 ? "MM-DD" : "ddd")
                    );
                    defaultData.push(0);
                    offset++;
                }
            }

            // console.log("xxxxx metadata", diff, fromDate, toDate, categories)

            const headers = {
                accept: "application/json, text/plain, */*",
                "content-type": "application/json;charset=UTF-8",
                "accept-language": "en-US,en;q=0.9",
                authorization: accessToken,
            };

            axios
                .get(`${apiV1Url}/simpleUsers/${userId}/sites`, { headers })
                .then(async (resp) => {
                    siteId = resp.data[0].id;
                    currency = resp.data[0].siteCurrency.code;
                    const credentials = await axios
                        .get(
                            `${apiV1Url}/sites/${siteId}/marketplaceCredentials`,
                            {
                                headers,
                                params: {
                                    filter: {
                                        fields: ["id", "name"],
                                    },
                                },
                            }
                        )
                        .then((resp) => resp.data);
                    const orders = await axios
                        .get(`${apiV1Url}/sites/${siteId}/marketplaceOrders`, {
                            headers,
                            params: {
                                filter: {
                                    where: {
                                        purchased: {
                                            between: [fromDate, toDate],
                                        },
                                        and: [
                                            {
                                                or: [
                                                    { isMerged: false },
                                                    { isMergedParent: true },
                                                ],
                                            },
                                        ],
                                        systemStatus: {
                                            nin: ["cancelled", "system"],
                                        },
                                    },
                                    fields: [
                                        "id",
                                        "purchased",
                                        "price",
                                        "marketplaceCustomerId",
                                        "marketplaceId",
                                        "status",
                                        "systemStatus",
                                        "marketplaceCredentialId",
                                        "priceCurrency",
                                        "systemStatus",
                                    ],
                                },
                            },
                        })
                        .then((resp) => resp.data);

                    const credsHash = {};
                    credentials.forEach((creds) => {
                        credsHash[creds.id] = {
                            name: creds.name,
                            data: [...defaultData],
                        };
                    });

                    orders.forEach((order) => {
                        if (oneDayChart) {
                            const orderHour = parseInt(
                                dayjs(order.puchased).format("HH")
                            );
                            if (credsHash[order.marketplaceCredentialId]) {
                                credsHash[order.marketplaceCredentialId].data[
                                    orderHour
                                ] += order.price / 100;
                            }
                        } else {
                            let offset = 0;
                            let orderDay: any = null;
                            const orderDate = parseInt(
                                dayjs(order.purchased).format("YYYYMMDD")
                            );
                            let found = false;
                            while (
                                dayjs(dayjs(dates[0]).add(offset, "days")).diff(
                                    dates[1],
                                    "days"
                                ) <= 0 &&
                                !found
                            ) {
                                // console.log("xxxx orderDate", parseInt(dayjs(dates[0]).add(offset, "days").format("YYYYMMDD")), orderDay, offset)
                                if (
                                    parseInt(
                                        dayjs(dates[0])
                                            .add(offset, "days")
                                            .format("YYYYMMDD")
                                    ) === orderDate
                                ) {
                                    found = true;
                                    orderDay = offset;
                                }
                                offset++;
                            }
                            if (orderDay !== null) {
                                if (credsHash[order.marketplaceCredentialId]) {
                                    credsHash[
                                        order.marketplaceCredentialId
                                    ].data[orderDay] += order.price / 100;
                                }
                            }
                        }
                    });

                    for (const key in credsHash) {
                        series.push(credsHash[key]);
                    }

                    let total = 0;

                    for (const seriesIndex in series) {
                        for (const dataIndex in series[seriesIndex].data) {
                            total += series[seriesIndex].data[dataIndex];
                        }
                    }

                    resolve({
                        currency,
                        categories,
                        series,
                        total,
                    });
                });
        });
    }

    public static getDonutChartStatistics(
        channels: Array<string>,
        dateRange: Array<dayjs.Dayjs>
    ): Promise<IDonutChartStatistics | Error> {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                //get currency from existing user data?
                const currency = "$";
                const series = [15, 7, 2];
                const labels = ["1 Day", "2 Day", "3 Day"];
                const headerLegendName = "legend";
                const headerValueName = "value";

                resolve({
                    currency,
                    series,
                    labels,
                    headerLegendName,
                    headerValueName,
                });
            }, 1500);
        });
    }

    public static getHeatmapChartStatistics(
        channels: Array<string>,
        dateRange: Array<dayjs.Dayjs>
    ): Promise<IHeatmapChartStatistics | Error> {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                const series = [
                    {
                        name: "Sun",
                        data: [
                            {
                                x: "1",
                                y: 22,
                            },
                            {
                                x: "2",
                                y: 29,
                            },
                            {
                                x: "3",
                                y: 13,
                            },
                            {
                                x: "4",
                                y: 32,
                            },
                            {
                                x: "5",
                                y: 32,
                            },
                            {
                                x: "6",
                                y: 32,
                            },
                            {
                                x: "7",
                                y: 32,
                            },
                            {
                                x: "8",
                                y: 32,
                            },
                            {
                                x: "9",
                                y: 32,
                            },
                            {
                                x: "10",
                                y: 32,
                            },
                            {
                                x: "11",
                                y: 32,
                            },
                            {
                                x: "12",
                                y: 32,
                            },
                            {
                                x: "13",
                                y: 32,
                            },
                            {
                                x: "14",
                                y: 32,
                            },
                            {
                                x: "15",
                                y: 32,
                            },
                            {
                                x: "16",
                                y: 32,
                            },
                            {
                                x: "17",
                                y: 32,
                            },
                            {
                                x: "18",
                                y: 32,
                            },
                            {
                                x: "19",
                                y: 32,
                            },
                            {
                                x: "20",
                                y: 32,
                            },
                            {
                                x: "21",
                                y: 32,
                            },
                            {
                                x: "22",
                                y: 32,
                            },
                            {
                                x: "23",
                                y: 5,
                            },
                        ],
                    },
                    {
                        name: "Sat",
                        data: [
                            {
                                x: "1",
                                y: 22,
                            },
                            {
                                x: "2",
                                y: 29,
                            },
                            {
                                x: "3",
                                y: 13,
                            },
                            {
                                x: "4",
                                y: 32,
                            },
                            {
                                x: "5",
                                y: 32,
                            },
                            {
                                x: "6",
                                y: 32,
                            },
                            {
                                x: "7",
                                y: 32,
                            },
                            {
                                x: "8",
                                y: 32,
                            },
                            {
                                x: "9",
                                y: 32,
                            },
                            {
                                x: "10",
                                y: 32,
                            },
                            {
                                x: "11",
                                y: 32,
                            },
                            {
                                x: "12",
                                y: 32,
                            },
                            {
                                x: "13",
                                y: 32,
                            },
                            {
                                x: "14",
                                y: 32,
                            },
                            {
                                x: "15",
                                y: 32,
                            },
                            {
                                x: "16",
                                y: 32,
                            },
                            {
                                x: "17",
                                y: 32,
                            },
                            {
                                x: "18",
                                y: 32,
                            },
                            {
                                x: "19",
                                y: 32,
                            },
                            {
                                x: "20",
                                y: 32,
                            },
                            {
                                x: "21",
                                y: 32,
                            },
                            {
                                x: "22",
                                y: 32,
                            },
                            {
                                x: "23",
                                y: 32,
                            },
                        ],
                    },
                    {
                        name: "Fri",
                        data: [
                            {
                                x: "1",
                                y: 22,
                            },
                            {
                                x: "2",
                                y: 29,
                            },
                            {
                                x: "3",
                                y: 50,
                            },
                            {
                                x: "4",
                                y: 32,
                            },
                            {
                                x: "5",
                                y: 32,
                            },
                            {
                                x: "6",
                                y: 32,
                            },
                            {
                                x: "7",
                                y: 32,
                            },
                            {
                                x: "8",
                                y: 0,
                            },
                            {
                                x: "9",
                                y: 32,
                            },
                            {
                                x: "10",
                                y: 32,
                            },
                            {
                                x: "11",
                                y: 32,
                            },
                            {
                                x: "12",
                                y: 32,
                            },
                            {
                                x: "13",
                                y: 32,
                            },
                            {
                                x: "14",
                                y: 32,
                            },
                            {
                                x: "15",
                                y: 32,
                            },
                            {
                                x: "16",
                                y: 32,
                            },
                            {
                                x: "17",
                                y: 32,
                            },
                            {
                                x: "18",
                                y: 32,
                            },
                            {
                                x: "19",
                                y: 32,
                            },
                            {
                                x: "20",
                                y: 32,
                            },
                            {
                                x: "21",
                                y: 32,
                            },
                            {
                                x: "22",
                                y: 32,
                            },
                            {
                                x: "23",
                                y: 32,
                            },
                        ],
                    },
                    {
                        name: "Thu",
                        data: [
                            {
                                x: "1",
                                y: 22,
                            },
                            {
                                x: "2",
                                y: 29,
                            },
                            {
                                x: "3",
                                y: 13,
                            },
                            {
                                x: "4",
                                y: 32,
                            },
                            {
                                x: "5",
                                y: 32,
                            },
                            {
                                x: "6",
                                y: 32,
                            },
                            {
                                x: "7",
                                y: 32,
                            },
                            {
                                x: "8",
                                y: 32,
                            },
                            {
                                x: "9",
                                y: 32,
                            },
                            {
                                x: "10",
                                y: 32,
                            },
                            {
                                x: "11",
                                y: 32,
                            },
                            {
                                x: "12",
                                y: 32,
                            },
                            {
                                x: "13",
                                y: 32,
                            },
                            {
                                x: "14",
                                y: 32,
                            },
                            {
                                x: "15",
                                y: 32,
                            },
                            {
                                x: "16",
                                y: 32,
                            },
                            {
                                x: "17",
                                y: 32,
                            },
                            {
                                x: "18",
                                y: 32,
                            },
                            {
                                x: "19",
                                y: 32,
                            },
                            {
                                x: "20",
                                y: 32,
                            },
                            {
                                x: "21",
                                y: 32,
                            },
                            {
                                x: "22",
                                y: 32,
                            },
                            {
                                x: "23",
                                y: 32,
                            },
                        ],
                    },
                    {
                        name: "Wed",
                        data: [
                            {
                                x: "1",
                                y: 22,
                            },
                            {
                                x: "2",
                                y: 29,
                            },
                            {
                                x: "3",
                                y: 13,
                            },
                            {
                                x: "4",
                                y: 32,
                            },
                            {
                                x: "5",
                                y: 32,
                            },
                            {
                                x: "6",
                                y: 32,
                            },
                            {
                                x: "7",
                                y: 32,
                            },
                            {
                                x: "8",
                                y: 32,
                            },
                            {
                                x: "9",
                                y: 32,
                            },
                            {
                                x: "10",
                                y: 32,
                            },
                            {
                                x: "11",
                                y: 32,
                            },
                            {
                                x: "12",
                                y: 32,
                            },
                            {
                                x: "13",
                                y: 32,
                            },
                            {
                                x: "14",
                                y: 32,
                            },
                            {
                                x: "15",
                                y: 32,
                            },
                            {
                                x: "16",
                                y: 32,
                            },
                            {
                                x: "17",
                                y: 32,
                            },
                            {
                                x: "18",
                                y: 32,
                            },
                            {
                                x: "19",
                                y: 32,
                            },
                            {
                                x: "20",
                                y: 32,
                            },
                            {
                                x: "21",
                                y: 32,
                            },
                            {
                                x: "22",
                                y: 32,
                            },
                            {
                                x: "23",
                                y: 32,
                            },
                        ],
                    },
                    {
                        name: "Tue",
                        data: [
                            {
                                x: "1",
                                y: 22,
                            },
                            {
                                x: "2",
                                y: 48,
                            },
                            {
                                x: "3",
                                y: 13,
                            },
                            {
                                x: "4",
                                y: 32,
                            },
                            {
                                x: "5",
                                y: 32,
                            },
                            {
                                x: "6",
                                y: 32,
                            },
                            {
                                x: "7",
                                y: 32,
                            },
                            {
                                x: "8",
                                y: 32,
                            },
                            {
                                x: "9",
                                y: 32,
                            },
                            {
                                x: "10",
                                y: 32,
                            },
                            {
                                x: "11",
                                y: 32,
                            },
                            {
                                x: "12",
                                y: 32,
                            },
                            {
                                x: "13",
                                y: 32,
                            },
                            {
                                x: "14",
                                y: 32,
                            },
                            {
                                x: "15",
                                y: 32,
                            },
                            {
                                x: "16",
                                y: 32,
                            },
                            {
                                x: "17",
                                y: 32,
                            },
                            {
                                x: "18",
                                y: 32,
                            },
                            {
                                x: "19",
                                y: 32,
                            },
                            {
                                x: "20",
                                y: 32,
                            },
                            {
                                x: "21",
                                y: 32,
                            },
                            {
                                x: "22",
                                y: 32,
                            },
                            {
                                x: "23",
                                y: 32,
                            },
                        ],
                    },
                    {
                        name: "Mon",
                        data: [
                            {
                                x: "1",
                                y: 22,
                            },
                            {
                                x: "2",
                                y: 29,
                            },
                            {
                                x: "3",
                                y: 13,
                            },
                            {
                                x: "4",
                                y: 32,
                            },
                            {
                                x: "5",
                                y: 32,
                            },
                            {
                                x: "6",
                                y: 32,
                            },
                            {
                                x: "7",
                                y: 32,
                            },
                            {
                                x: "8",
                                y: 32,
                            },
                            {
                                x: "9",
                                y: 32,
                            },
                            {
                                x: "10",
                                y: 32,
                            },
                            {
                                x: "11",
                                y: 32,
                            },
                            {
                                x: "12",
                                y: 32,
                            },
                            {
                                x: "13",
                                y: 32,
                            },
                            {
                                x: "14",
                                y: 32,
                            },
                            {
                                x: "15",
                                y: 32,
                            },
                            {
                                x: "16",
                                y: 32,
                            },
                            {
                                x: "17",
                                y: 32,
                            },
                            {
                                x: "18",
                                y: 32,
                            },
                            {
                                x: "19",
                                y: 32,
                            },
                            {
                                x: "20",
                                y: 32,
                            },
                            {
                                x: "21",
                                y: 32,
                            },
                            {
                                x: "22",
                                y: 32,
                            },
                            {
                                x: "23",
                                y: 32,
                            },
                        ],
                    },
                ];
                const min = 0;
                const max = 50;

                resolve({
                    min,
                    max,
                    series,
                });
            }, 1400);
        });
    }
}
