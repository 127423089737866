import LayoutConfigTypes from "./LayoutConfigTypes";

const config: LayoutConfigTypes = {
    themeName: "Metronic",
    themeVersion: "8.0.23",
    demo: "demo1",
    main: {
        type: "default",
        primaryColor: "#009EF7",
        logo: {
            dark: "/media/logos/favicon-194x194.png",
            light: "/media/logos/favicon-194x194.png",
        },
    },
    loader: {
        logo: "/media/logos/favicon-194x194.png",
        display: true,
        type: "spinner-logo",
    },
    scrollTop: {
        display: false,
    },
    header: {
        display: true,
        menuIcon: "font",
        width: "fluid",
        fixed: {
            desktop: true,
            tabletAndMobile: true,
        },
    },
    toolbar: {
        display: true,
        width: "fluid",
        fixed: {
            desktop: true,
            tabletAndMobile: true,
        },
    },
    aside: {
        display: true,
        theme: "light",
        fixed: true,
        menuIcon: "svg",
        minimized: false,
        minimize: true,
        hoverable: false,
    },
    content: {
        width: "fixed",
    },
    footer: {
        width: "fixed",
    },
};

export default config;
