import RouterIframeSync from "@frameworks/vue/router/RouterIframeSync.service";
import LocalStorageService from "@services/local-storage/LocalStorage.service";
import Services from "@services/Services";
import UserService from "@services/user/User.service";
import { Inject, Service } from "typedi";
import IframeModel from "../models/Iframe.model";
import IframeEventsEmitterModule from "../modules/IframeEventsEmitter.module";
import IframePathCheckerModule from "../modules/iframePathChecker.module";

@Service()
export default class BuildDoneEvent {
    public constructor(
        @Inject() private readonly _userService: UserService,
        @Inject() private _localStorageService: LocalStorageService,
        @Inject() private _iframeEventsEmitterModule: IframeEventsEmitterModule,
        @Inject() private _iframePathCheckerModule: IframePathCheckerModule,
        @Inject() private iframeRouter: RouterIframeSync
    ) {}

    // --> Actions
    public async on(iframe: IframeModel, data: any): Promise<void> {
        Services.getDebug().log("EVENT", "BUILD_DONE");
        this.handleAuthorization(iframe);
    }

    private handleAuthorization(iframe: IframeModel): void {
        // 1. If no authorization then skip
        if (!this._userService.isAuthenticated()) {
            this._iframeEventsEmitterModule.emitStartMount(iframe);
            //store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
            iframe.setReady(true);
            return;
        }

        // 2. Get credentials
        const accessKey = this._localStorageService.getUserAccessKey();
        const token = this._localStorageService.getUserToken();
        const userId = this._localStorageService.getUserId();
        const redirect =
            this._userService.isAuthenticated() &&
            !this._iframePathCheckerModule.isPathAuthorizedByFullCheck(
                window.location.pathname
            );

        // 3. Emit credentials to iframe
        this._iframeEventsEmitterModule.emitLoadAuthentication(
            iframe,
            accessKey,
            token,
            userId,
            redirect
        );
    }
}
