enum LOCALSTORAGE_KEYS {
    USER_ID,
    USER_TOKEN,
    USER_ACCESS_KEY,
    DEVICE_ID,
    TIMEZONE_DETAILS,
    TOKEN_V3,
    SITE_ID,
}

export default LOCALSTORAGE_KEYS;
