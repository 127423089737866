<template>
    <div class="search-results">
        <div class="search-results-helpful" v-if="searchResults.length > 0">
            Press <strong>enter</strong> to select, <strong>↑ ↓</strong> to
            navigate, <strong>esc</strong> to close
        </div>
        <div class="search-result-subcontainer" v-if="searchResults.length > 0">
            <div
                v-for="(item, index) in searchResults"
                :key="index"
                class="search-result-group"
            >
                <div class="search-result-category">{{ item.category }}</div>
                <div
                    class="result-row"
                    v-bind:class="{ selected: item2.selected }"
                    @click.prevent="openLink(item2.link)"
                    v-for="(item2, index2) in item.items"
                    :key="index2"
                >
                    <a class="stupid-link" v-bind:href="item2.link">{{
                        item2.name
                    }}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import RouterIframeSync from "@frameworks/vue/router/RouterIframeSync.service";
import EXTERNAL_LINK_METHOD from "@frameworks/vue/router/EXTERNAL_LINK_METHOD";
import { Mutations } from "@frameworks/vue/store/enums/StoreEnums";
import store from "@frameworks/vue/store";

export default {
    props: ["searchResults"],
    name: "search-results",
    mounted() {
        window.addEventListener("keyup", this.handleSearchMovement);
    },
    beforeUnmount() {
        window.removeEventListener("keyup", this.handleSearchMovement);
    },
    methods: {
        openLink(link) {
            if (link[0] === "/") {
                RouterIframeSync.getInstance().navigate(link);
            } else {
                RouterIframeSync.getInstance().openExternalLink(
                    link,
                    EXTERNAL_LINK_METHOD.BLANK
                );
            }

            this.hideSearch();
        },

        hideSearch() {
            // also clear search input
            store.commit(Mutations.SET_SEARCH_VISIBILITY, false);
        },

        calculateNext(direction) {
            if (!(this.searchResults && this.searchResults[0])) {
                return;
            }

            let selectedItemIndex;
            let items = [];
            let sIndex = 0;
            this.searchResults.forEach((search, index) => {
                search.items.forEach((item) => {
                    items.push(item);
                    if (item.selected) {
                        selectedItemIndex = sIndex;
                    }
                    sIndex += 1;
                });
            });

            sIndex -= 1;

            if (direction == "up") {
                if (selectedItemIndex == 0) {
                    return;
                }
                items[selectedItemIndex].selected = false;
                items[selectedItemIndex - 1].selected = true;
            } else {
                if (selectedItemIndex == sIndex) {
                    return;
                }
                items[selectedItemIndex].selected = false;
                items[selectedItemIndex + 1].selected = true;
            }
        },

        handleSearchMovement(event) {
            switch (event.key) {
                case "ArrowUp":
                    this.calculateNext("up");
                    break;
                case "ArrowDown":
                    this.calculateNext("down");
                    break;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";

.search-results-helpful {
    display: none;
}
@include media-breakpoint-up(lg) {
    .search-results-helpful {
        padding: 8px 20px 0px;
        display: block;
    }
}

.stupid-link {
    padding: 0 !important;
    text-decoration: none;

    &:hover {
        text-decoration: none !important;
    }
}

.search-result-group {
    width: 100%;
    padding-bottom: 5px;
    padding-top: 5px;

    &:not(:last-child) {
        border-bottom: 1px solid #aaa;
    }
}

.search-result-category {
    text-align: left;
    font-size: 12px;
    font-weight: 600;
    color: #aaa;
}

.search-results {
    border-top: 1px solid #aaa;
    width: 100%;
}

.search-result-subcontainer {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    width: 100%;
    padding: 8px 20px;
    max-height: 370px;
    overflow: scroll;

    @include media-breakpoint-down(lg) {
        max-height: 100%;
    }
}

.result-row {
    padding: 2.1px 7px;
    width: 100%;
    text-align: left;
    font-size: 14px;
    color: #444446;
    border-radius: 5px;

    &.selected {
        background: #ccccf4;
    }
}
</style>
