<template>
    <div v-if="visible" class="search-box-container">
        <div class="search-box-background" @click="hideSearch"></div>

        <div class="search-box">
            <div class="search-wrapper">
                <input ref="searchRef" v-model="searchInput" class="search" type="text"
                    placeholder="Search Multiorders features" @keyup.esc="hideSearch" @keyup.enter="execSelection" />
            </div>

            <div v-if="!search" class="search-loading">loading...</div>

            <search-results :searchResults="searchFiltered"></search-results>
        </div>
    </div>
</template>

<script>
import SearchResults from "./SearchResults.vue";
import SearchInterpreter from "./search.interpreter";
import { ref, watchEffect, computed } from "vue";
import ApiService from "@frameworks/metronic/core/services/ApiService";
import Services from "@services/Services";
import RouterIframeSync from "@frameworks/vue/router/RouterIframeSync.service";
import EXTERNAL_LINK_METHOD from "@frameworks/vue/router/EXTERNAL_LINK_METHOD";
import { Mutations } from "@frameworks/vue/store/enums/StoreEnums";
import store from "@frameworks/vue/store";

export default {
    components: {
        SearchResults,
    },
    name: "search",
    setup() {
        const searchRef = ref(null);
        watchEffect(() => {
            if (searchRef.value) {
                searchRef.value.focus();
            }
        });

        return {
            searchRef,
        };
    },
    data() {
        let done;

        const loading = new Promise((resolve) => {
            done = resolve;
        });

        const visible = computed(() => {
            return store.getters.isSearchVisible;
        });

        const search = computed(() => {
            return store.getters.getSearchData;
        });

        return {
            searchInterpreter: null,
            searchFiltered: [],
            searchInput: "",
            visible,
            search,
            loading,
            done,
        };
    },
    watch: {
        search: {
            handler: async function () {
                this.done();
            },
        },
        searchInput: {
            handler: async function (value) {
                await this.loading;
                await this.logSearch(value);
                if (!this.searchInterpreter) {
                    this.searchInterpreter = new SearchInterpreter(
                        this.search,
                        {
                            threshold: 0.3,
                            keys: ["name", "tags", "category"],
                        }
                    );
                }

                if (this.searchInterpreter) {
                    this.searchFiltered = this.searchInterpreter.search(value);
                }

                if (this.searchFiltered && this.searchFiltered[0]) {
                    this.searchFiltered.forEach((search) => {
                        search.items.forEach((item) => {
                            item.selected = false;
                        });
                    });
                    this.searchFiltered[0].items[0].selected = true;
                }
            },
        },
    },
    methods: {
        hideSearch() {
            this.searchInput = "";
            store.commit(Mutations.SET_SEARCH_VISIBILITY, false);
        },

        showSearch() {
            this.searchInput = "";
            store.commit(Mutations.SET_SEARCH_VISIBILITY, true);
        },

        async logSearch(value) {
            const userId = Services.getLocalStorage().getUserId();

            await ApiService.post("log", {
                message: {
                    id: 1,
                    m: value,
                    u: userId,
                },
            });
        },

        execSelection() {
            this.searchInput = "";
            let link;

            if (!(this.searchFiltered && this.searchFiltered[0])) {
                return;
            }

            this.searchFiltered.forEach((search) => {
                search.items.forEach((item) => {
                    if (item.selected) {
                        link = item.link;
                    }
                });
            });

            if (!link) {
                return;
            }

            if (link[0] === "/") {
                RouterIframeSync.getInstance().navigate(link);
                //window.open(link, "_self");
            } else {
                RouterIframeSync.getInstance().openExternalLink(
                    link,
                    EXTERNAL_LINK_METHOD.BLANK
                );
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

.search-box-container * {
    color: #444446;
    box-sizing: border-box;
}

.search-loading {
    margin: 10px 0;
}

.search {
    border-radius: 10px;
    padding: 8px 22px;
    font-size: 25px;
    width: 100%;
    border: none;
    outline: none;
    background: transparent;

    &:focus,
    &:focus-visible {
        outline: none;
        border: none;
    }
}

.search-wrapper {
    width: 100%;
}

.search-box-background {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    content: " ";
    z-index: 999;
}

.search-box {
    box-shadow: 0px 10px 26px 2px rgb(0 0 0 / 31%);
    border: 1px solid #aaa;
    border-radius: 7px;
    width: 570px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f5f5f5;
    position: fixed;
    top: 90px;
    left: 50vw;
    transform: translate(-50%, 0);
    z-index: 1000;
    flex-direction: column;

    @include media-breakpoint-down(lg) {
        width: 100%;
        top: 0;
        border: none;
        border-bottom: 1px solid #aaa;
        border-radius: 0;
        box-shadow: none;
    }
}
</style>
