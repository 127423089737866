import TimeUtil from "@utilities/Time/Time.utility";
import { Inject, Service } from "typedi";

@Service()
export default class DebugService {
    public constructor(@Inject() private _timeUtility: TimeUtil) {}

    // ---> Variables
    private _enabled = true;

    // ---> Actions
    public toggle(state: boolean): void {
        this._enabled = state;
    }

    public log(title: string, ...args: any[]): void {
        if (!this._enabled) {
            return;
        }

        let messageDetails = "";
        const messageObject: any[] = [];

        for (let i = 0; i < args.length; ++i) {
            if (messageDetails) {
                messageDetails += " ";
            }

            const arg = args[i];

            if (typeof arg === "object") {
                messageObject.push(arg);
                continue;
            }

            messageDetails += arg;
        }

        if (messageObject.length > 0) {
            console.log(...messageObject);
        }
    }
}
