import SITE_USER_ROLES from "../constants/SITE_USER_ROLES";

export default class UserRoleModel {
    private _role: SITE_USER_ROLES = SITE_USER_ROLES.NO_ROLE;

    // ---> Getters
    public get(): SITE_USER_ROLES {
        return this._role;
    }

    // ---> Setters
    public set(role: SITE_USER_ROLES): void {
        this._role = role;
    }

    // ---> Booleans
    public isAppAdmin(): boolean {
        return this._role === SITE_USER_ROLES.APP_ADMIN;
    }
    public isAdmin(): boolean {
        return this._role === SITE_USER_ROLES.ADMIN;
    }
    public isOwner(): boolean {
        return this._role === SITE_USER_ROLES.OWNER;
    }
    public isManager(): boolean {
        return this._role === SITE_USER_ROLES.MANAGER;
    }
    public isIManager(): boolean {
        return this._role === SITE_USER_ROLES.INVENTORY_MANAGER;
    }
    public isOManager(): boolean {
        return this._role === SITE_USER_ROLES.ORDERS_MANAGER;
    }
    public isOIManager(): boolean {
        return this._role === SITE_USER_ROLES.ORDERS_INVENTORY_MANAGER;
    }

    public isHighRole(): boolean {
        return this.isAdmin() || this.isAppAdmin() || this.isOwner();
    }
}
