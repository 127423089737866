import { Service } from "typedi";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
dayjs.extend(weekday);

@Service()
export default class DatesUtility {
    // ---> Actions
    public getTodayDate(isRange: boolean): Array<dayjs.Dayjs> | dayjs.Dayjs {
        const today: dayjs.Dayjs = dayjs();

        if (isRange) {
            return [today, today];
        } else {
            return today;
        }
    }

    public getIsToday(dateValue: Array<dayjs.Dayjs>): boolean {
        const todayProxy: ProxyConstructor = new Proxy<any>(
            [this.getTodayDate(false)["$d"], this.getTodayDate(false)["$d"]],
            {}
        );

        return (
            dayjs(dateValue[0]).format("DD/MM/YYYY") ===
                dayjs(todayProxy[0]).format("DD/MM/YYYY") &&
            dayjs(dateValue[1]).format("DD/MM/YYYY") ===
                dayjs(todayProxy[1]).format("DD/MM/YYYY")
        );
    }

    public getYesterdayDate(
        isRange: boolean
    ): Array<dayjs.Dayjs> | dayjs.Dayjs {
        const yesterday: dayjs.Dayjs = dayjs().subtract(1, "day");

        if (isRange) {
            return [yesterday, yesterday];
        } else {
            return yesterday;
        }
    }

    public getIsYesterday(dateValue: Array<dayjs.Dayjs>): boolean {
        const yesterdayProxy: ProxyConstructor = new Proxy<any>(
            [
                this.getYesterdayDate(false)["$d"],
                this.getYesterdayDate(false)["$d"],
            ],
            {}
        );

        return (
            dayjs(dateValue[0]).format("DD/MM/YYYY") ===
                dayjs(yesterdayProxy[0]).format("DD/MM/YYYY") &&
            dayjs(dateValue[1]).format("DD/MM/YYYY") ===
                dayjs(yesterdayProxy[1]).format("DD/MM/YYYY")
        );
    }

    public getThisWeekDate(): Array<dayjs.Dayjs> {
        const today: dayjs.Dayjs = dayjs();
        const firstDayOfThisWeek: dayjs.Dayjs = dayjs()
            .startOf("w")
            .add(1, "day");

        return [firstDayOfThisWeek, today];
    }

    public getIsThisWeek(dateValue: Array<dayjs.Dayjs>): boolean {
        const thisWeekProxy: ProxyConstructor = new Proxy<any>(
            [this.getThisWeekDate()[0]["$d"], this.getThisWeekDate()[1]["$d"]],
            {}
        );

        return (
            dayjs(dateValue[0]).format("DD/MM/YYYY") ===
                dayjs(thisWeekProxy[0]).format("DD/MM/YYYY") &&
            dayjs(dateValue[1]).format("DD/MM/YYYY") ===
                dayjs(thisWeekProxy[1]).format("DD/MM/YYYY")
        );
    }

    public getLastSevenDaysDate(): Array<dayjs.Dayjs> {
        const today: dayjs.Dayjs = dayjs();
        const SevenDaysBefore: dayjs.Dayjs = dayjs().subtract(6, "days");

        return [SevenDaysBefore, today];
    }

    public getIsLastSevenDays(dateValue: Array<dayjs.Dayjs>): boolean {
        const lastSevenDaysProxy: ProxyConstructor = new Proxy<any>(
            [
                this.getLastSevenDaysDate()[0]["$d"],
                this.getLastSevenDaysDate()[1]["$d"],
            ],
            {}
        );

        return (
            dayjs(dateValue[0]).format("DD/MM/YYYY") ===
                dayjs(lastSevenDaysProxy[0]).format("DD/MM/YYYY") &&
            dayjs(dateValue[1]).format("DD/MM/YYYY") ===
                dayjs(lastSevenDaysProxy[1]).format("DD/MM/YYYY")
        );
    }

    public getLastWeekDate(): Array<dayjs.Dayjs> {
        const FirstDayOfLastWeek: dayjs.Dayjs = dayjs().weekday(-6);
        const LastDayOfLastWeek: dayjs.Dayjs = dayjs().weekday(-0);

        return [FirstDayOfLastWeek, LastDayOfLastWeek];
    }

    public getIsLastWeek(dateValue: Array<dayjs.Dayjs>): boolean {
        const lastWeekProxy: ProxyConstructor = new Proxy<any>(
            [this.getLastWeekDate()[0]["$d"], this.getLastWeekDate()[1]["$d"]],
            {}
        );

        return (
            dayjs(dateValue[0]).format("DD/MM/YYYY") ===
                dayjs(lastWeekProxy[0]).format("DD/MM/YYYY") &&
            dayjs(dateValue[1]).format("DD/MM/YYYY") ===
                dayjs(lastWeekProxy[1]).format("DD/MM/YYYY")
        );
    }

    public getThisMonthDate(): Array<dayjs.Dayjs> {
        const today: dayjs.Dayjs = dayjs();
        const firstDayOfThisMonth: dayjs.Dayjs = dayjs().startOf("M");

        return [firstDayOfThisMonth, today];
    }

    public getIsThisMonth(dateValue: Array<dayjs.Dayjs>): boolean {
        const thisMonthProxy: ProxyConstructor = new Proxy<any>(
            [
                this.getThisMonthDate()[0]["$d"],
                this.getThisMonthDate()[1]["$d"],
            ],
            {}
        );

        return (
            dayjs(dateValue[0]).format("DD/MM/YYYY") ===
                dayjs(thisMonthProxy[0]).format("DD/MM/YYYY") &&
            dayjs(dateValue[1]).format("DD/MM/YYYY") ===
                dayjs(thisMonthProxy[1]).format("DD/MM/YYYY")
        );
    }

    public getLastThirtyOneDaysDate(): Array<dayjs.Dayjs> {
        const today: dayjs.Dayjs = dayjs();
        const ThirtyOneDaysBefore: dayjs.Dayjs = dayjs().subtract(30, "days");

        return [ThirtyOneDaysBefore, today];
    }

    public getIsLastThirtyOneDays(dateValue: Array<dayjs.Dayjs>): boolean {
        const lastThirtyOneDaysProxy: ProxyConstructor = new Proxy<any>(
            [
                this.getLastThirtyOneDaysDate()[0]["$d"],
                this.getLastThirtyOneDaysDate()[1]["$d"],
            ],
            {}
        );

        return (
            dayjs(dateValue[0]).format("DD/MM/YYYY") ===
                dayjs(lastThirtyOneDaysProxy[0]).format("DD/MM/YYYY") &&
            dayjs(dateValue[1]).format("DD/MM/YYYY") ===
                dayjs(lastThirtyOneDaysProxy[1]).format("DD/MM/YYYY")
        );
    }

    public getLastMonthDate(): Array<dayjs.Dayjs> {
        const FirstDayOfLastMonth: dayjs.Dayjs = dayjs()
            .set("month", dayjs().get("month") - 1)
            .startOf("M");
        const LastDayOfLastMonth: dayjs.Dayjs = dayjs()
            .set("month", dayjs().get("month") - 1)
            .endOf("M");

        return [FirstDayOfLastMonth, LastDayOfLastMonth];
    }

    public getIsLastMonth(dateValue: Array<dayjs.Dayjs>): boolean {
        const lastThirtyDaysProxy: ProxyConstructor = new Proxy<any>(
            [
                this.getLastMonthDate()[0]["$d"],
                this.getLastMonthDate()[1]["$d"],
            ],
            {}
        );

        return (
            dayjs(dateValue[0]).format("DD/MM/YYYY") ===
                dayjs(lastThirtyDaysProxy[0]).format("DD/MM/YYYY") &&
            dayjs(dateValue[1]).format("DD/MM/YYYY") ===
                dayjs(lastThirtyDaysProxy[1]).format("DD/MM/YYYY")
        );
    }
}
