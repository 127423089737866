
import { defineComponent, inject, ref } from "vue";
import NavbarInteractor from "@interactors/navbar/Navbar.interactor";

export default defineComponent({
  name: "Toolbar2",
  props: {
    isIframe: Boolean,
  },
  components: {
  },
  watch: {
    '$route'(to, from) {
      this.navbarComponents = NavbarInteractor.getNavbarSecondaryToolbarComponents();
      console.log(this.navbarComponents);
      this.isAnyDropdownOpen = false;
      this.isLoading = true;
      this.dropdownStates = {};
      this.$forceUpdate();
    },
    toolbarOverflow() {
      this.$forceUpdate();
    },
  },
  data() {
    const $mitt: any = null;

    const filterRef = ref();
    filterRef.value = {};

    return {
      navbarComponents: NavbarInteractor.getNavbarSecondaryToolbarComponents(),
      toolbarOverflow: 'overflow-x-scroll',
      dropdownStates: {},
      isAnyDropdownOpen: false,
      isLoading: true,
      $mitt,
      filterRef: filterRef.value,
    }
  },
  mounted() {
    this.$mitt = inject('$mitt');

    this.$mitt.on('view-loading-start', (args) => { this.loadingStarted() });
    this.$mitt.on('view-loading-finish', (args) => { this.loadingFinished() });
  },
  beforeUnmount() {
    this.$mitt.all.delete('view-loading-start');
    this.$mitt.all.delete('view-loading-finish');

  },
  methods: {
    loadingStarted() {
      this.isLoading = true;
      return;
    },
    loadingFinished() {
      this.isLoading = false;
      return;
    },
    mutateFilterRef(filter: string, data) {
      this.filterRef[filter] = data;
      return
    },
    handleDropdownToggle(isDropdownShown: boolean, ID: string) {
      const toolbarEl: any = document.getElementById('kt_toolbar');
      this.dropdownStates[ID] = isDropdownShown;

      if (isDropdownShown) {
        const toolEl: any = document.getElementById(ID);
        toolEl.style.zIndex = '4'
      } else {
        const toolEl: any = document.getElementById(ID);
        toolEl.style.zIndex = '0'
      }

      for (let dropdownState in this.dropdownStates) {
        if (this.dropdownStates[dropdownState]) {
          this.toolbarOverflow = '';
          this.isAnyDropdownOpen = true;
          toolbarEl.style.marginLeft = -toolbarEl?.scrollLeft + 'px';
          return;
        }
      }

      this.toolbarOverflow = 'overflow-x-scroll';
      this.isAnyDropdownOpen = false;
      toolbarEl.style.marginLeft = '0px';
      toolbarEl.scrollTo(toolbarEl?.scrollLeft, 0)
      return;
    },
  }
});
