import ConfigsService from "@services/configs/Configs.service";
import DebugService from "@services/debug/Debug.service";
import UserModel from "@services/user/models/User.model";

/**
 * Search interactor
 *
 * Handles searching features logic
 */
export default class SearchInteractor {
    private static _instance: SearchInteractor;
    private readonly _debuger: DebugService;
    private readonly _configs: ConfigsService;

    private constructor(
        debuger: DebugService,
        configs: ConfigsService
    ) {
        this._debuger = debuger;
        this._configs = configs;
    }

    // ---> Singleton
    public static getInstance(
        debuger: DebugService,
        configs: ConfigsService
    ): SearchInteractor {
        if (!SearchInteractor._instance) {
            SearchInteractor._instance = new SearchInteractor(debuger, configs);
        }

        return SearchInteractor._instance;
    }

    // ---> Booleans
    /**
     * Returns boolean value if search feature is enabled
     * 
     * @param { UserModel } user 
     * @returns { boolean }
     */
    public isEnabledByUserRole(user: UserModel): boolean {
        // Enable in develop enviroment for all users
        if (this._configs.get().isDevelop()) {
            return true;
        }

        this._debuger.log('INTERACTOR', 'SEARCH', 'isEnabledByUserRole', 'IS_USER_HIGH_ROLE', user.getRole().isHighRole());

        return user.getRole().isHighRole();
    }
}
