import IConfigsMo from "@services/configs/types/IConfigsMo";

const CONFIGS_PBE: IConfigsMo = {
    iframeViewAddress: "https://legacy.multiorders.com",
    apiExpressAddress: "https://api.multiorders.com/api/v2",
    v3API: "https://productionserverapiv3.multiorders.com",
    v1API: "https://api.multiorders.com/api",
    featureFlagAddress: "https://productionserverapiv3.multiorders.com",
};

export default CONFIGS_PBE;
