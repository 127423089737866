import UserService from "@services/user/User.service";
import WindowService from "@services/window/Window.service";
import Container from "typedi";

const IsAppAdminMiddleware = ({ to, from, next }) => {
    const userService = Container.get(UserService);
    const role = userService.getUser().getRole();

    // Your custom if statement
    if (role.isAppAdmin()) {
        next();
        return;
    }

    if (userService.isAuthenticated()) {
        const defaultRoute = "/order-list/new/1";

        Container.get(WindowService).openByLocation(defaultRoute);
    } else {
        Container.get(WindowService).openByLocation("/auth/login");
    }
};

export default IsAppAdminMiddleware;
