import store from "@/frameworks/vue/store";
import { User } from "@frameworks/vue/store/modules/AuthModule";

const ID_TOKEN_KEY = "$LoopBack$accessTokenId" as string;

/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
    const user: User = store.getters.currentUser;

    //return window.localStorage.getItem(ID_TOKEN_KEY);
    return user.token;
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
    window.localStorage.setItem(ID_TOKEN_KEY, token);
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
    window.localStorage.removeItem(ID_TOKEN_KEY);
};

export default { getToken, saveToken, destroyToken };
