import NODE_ENV from "./constants/NODE_ENV";
import IConfigsMo from "./types/IConfigsMo";
import ConfigsServiceBuilder from "./ConfigsService.builder";
import ConfigsModel from "./models/Configs.model";
import DebugService from "@services/debug/Debug.service";
import CONFIGS_DEVELOP from "@services/configs/data/CONFIGS_DEVELOP";
import CONFIGS_PBE from "./data/CONFIGS_PBE";
import CONFIGS_STAGING from "./data/CONFIGS_STAGING";
import { Inject, Service } from "typedi";

@Service()
export default class ConfigsService {
    public constructor(@Inject() private _debuger: DebugService) {}

    // ---> Variables
    private _configs: ConfigsModel = new ConfigsModel();

    // ---> Getters
    public get(): ConfigsModel {
        return this._configs;
    }

    public getByEnv(): IConfigsMo {
        switch (this._configs.getEnv()) {
            case NODE_ENV.STAGING:
                return CONFIGS_STAGING;
            case NODE_ENV.PBE:
                return CONFIGS_PBE;
        }

        return CONFIGS_DEVELOP;
    }

    // ---> Actions
    public build(): void {
        ConfigsServiceBuilder.build(this._configs, this._debuger);
    }
}
