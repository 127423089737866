import LOCALSTORAGE_KEYS from "./constants/LOCALSTORAGE_KEYS";
import LocalStorage from "./modules/LocalStorage.module";
import LocalStorageKeys from "./modules/LocalStorageKeys.module";
import { Service, Inject } from "typedi";
import ITimezondeDetails from "@services/timezone/types/ITimezoneDetails";

@Service()
export default class LocalStorageService {
    public constructor(
        @Inject() private _localStorageKeysModule: LocalStorageKeys,
        @Inject() private _localStorageModule: LocalStorage
    ) {}

    // ---> Getters
    public getUserId(): string | null {
        return this._localStorageModule.get(
            this._localStorageKeysModule.getByType(LOCALSTORAGE_KEYS.USER_ID)
        );
    }

    public getUserToken(): string | null {
        return this._localStorageModule.get(
            this._localStorageKeysModule.getByType(LOCALSTORAGE_KEYS.USER_TOKEN)
        );
    }

    public getUserAccessKey(): string | null {
        return this._localStorageModule.get(
            this._localStorageKeysModule.getByType(
                LOCALSTORAGE_KEYS.USER_ACCESS_KEY
            )
        );
    }

    public getDeviceId(): string | null {
        return this._localStorageModule.get(
            this._localStorageKeysModule.getByType(LOCALSTORAGE_KEYS.DEVICE_ID)
        );
    }

    public getSiteId(): string | null {
        return this._localStorageModule.get(
            this._localStorageKeysModule.getByType(LOCALSTORAGE_KEYS.SITE_ID)
        );
    }

    public getTimezoneDetails(): ITimezondeDetails | null {
        return this._localStorageModule.get(
            this._localStorageKeysModule.getByType(
                LOCALSTORAGE_KEYS.TIMEZONE_DETAILS
            ),
            true
        );
    }

    public getV3Token(): string | null {
        return this._localStorageModule.get(
            this._localStorageKeysModule.getByType(LOCALSTORAGE_KEYS.TOKEN_V3)
        );
    }

    // ---> Setters
    public setUserId(value: string): boolean {
        return this._localStorageModule.set(
            this._localStorageKeysModule.getByType(LOCALSTORAGE_KEYS.USER_ID),
            value
        );
    }

    public setUserToken(value: string): boolean {
        return this._localStorageModule.set(
            this._localStorageKeysModule.getByType(
                LOCALSTORAGE_KEYS.USER_TOKEN
            ),
            value
        );
    }

    public setUserAccessKey(value: string): boolean {
        return this._localStorageModule.set(
            this._localStorageKeysModule.getByType(
                LOCALSTORAGE_KEYS.USER_ACCESS_KEY
            ),
            value
        );
    }

    public setDeviceId(value: string): boolean {
        return this._localStorageModule.set(
            this._localStorageKeysModule.getByType(LOCALSTORAGE_KEYS.DEVICE_ID),
            value
        );
    }

    public setSiteId(value: string): boolean {
        return this._localStorageModule.set(
            this._localStorageKeysModule.getByType(LOCALSTORAGE_KEYS.SITE_ID),
            value
        );
    }

    public setTimezoneDetails(value: ITimezondeDetails): boolean {
        return this._localStorageModule.set(
            this._localStorageKeysModule.getByType(
                LOCALSTORAGE_KEYS.TIMEZONE_DETAILS
            ),
            value
        );
    }

    public setV3Token(value: string): boolean {
        return this._localStorageModule.set(
            this._localStorageKeysModule.getByType(LOCALSTORAGE_KEYS.TOKEN_V3),
            value
        );
    }

    // ---> Actions
    public clear(): void {
        this._localStorageModule.clear();
    }

    public clearCredentials(): void {
        // 1. Remove access key value
        this._localStorageModule.delete(
            this._localStorageKeysModule.getByType(
                LOCALSTORAGE_KEYS.USER_ACCESS_KEY
            )
        );

        // 2. Remove token value
        this._localStorageModule.delete(
            this._localStorageKeysModule.getByType(LOCALSTORAGE_KEYS.USER_TOKEN)
        );

        // 3. Remove user id value
        this._localStorageModule.delete(
            this._localStorageKeysModule.getByType(LOCALSTORAGE_KEYS.USER_ID)
        );
    }
}
