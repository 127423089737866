import IframeService from "@services/iframe/Iframe.service";
import { Inject, Service } from "typedi";
import IframeSetuperModule from "./modules/IframeSetuper.module";
import Services from "@services/Services";
import RouterIframeSync from "@frameworks/vue/router/RouterIframeSync.service";
import Container from "typedi";

@Service()
export default class IFrameInteractor {
    public constructor(
        @Inject() private readonly _iframeSetuper: IframeSetuperModule,
        @Inject(() => IframeService)
        private readonly _iframeService: IframeService
    ) {}

    // ---> Actions
    public setupIframe(): void {
        try {
            // 1. Check if ifream is loaded
            if (this._iframeService.getIframeModel().isLoaded()) {
                throw new Error("Iframe is already loaded");
            }

            // 2. Create iframe element
            this._iframeSetuper.createIframe(
                this._iframeService.getIframeModel()
            );

            // 3. Append iframe element
            this._iframeSetuper.appendIframe(
                this._iframeService.getIframeModel()
            );

            // 4. Add iframe listeners
            this._iframeService.addListeners();

            // 5. Set iframe loaded
            this._iframeService.getIframeModel().setLoaded(true);
        } catch (error) {
            //console.error('IFRAME_SETUPING', error);
        }
    }

    public static isIframeEnabled(path: string): boolean {
        Container.get(RouterIframeSync).navigate(path);

        const isIframeEnabled: boolean =
            Services.getIframe().isMetronicRoute(path) === false;
        const iframe: HTMLElement | null =
            document.getElementById("iframeView");

        //console.error("CHECK IF IFRAME", isIframeEnabled, this.$route.path);

        if (iframe != null) {
            if (isIframeEnabled) {
                if (iframe.classList.contains("hidden")) {
                    iframe.classList.remove("hidden");
                }
            } else {
                if (!iframe.classList.contains("hidden")) {
                    iframe.classList.add("hidden");
                }
            }
        }

        return isIframeEnabled;
    }
}
