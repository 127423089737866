
import { defineComponent, inject } from "vue";
import RoutingInteractor from "@interactors/routing/Routing.interactor";

export default defineComponent({
    name: "RestoreSectionOptions",
    data() {
        let $mitt: any;
        return {
            $mitt
        }
    },
    mounted() {
        this.$mitt = inject("$mitt");
    },
    methods: {
        openRestoreOptionsModal() {
            let section = RoutingInteractor.getCurrentSection(false, false, true);
            if (section && section.split("/").length > 1) {
                section = section.split("/")[1];
            }
            this.$mitt.emit(section + "-options-restore-modal-open");
            return;
        }
    }
});
