import dayjs from 'dayjs';
import { Service } from 'typedi';
import ITimeUtil from './types/ITimeUtil';

@Service()
export default class TimeUtil implements ITimeUtil {
    // ---> Actions
    public getTimeFull(): string { return dayjs().format('HH:mm:ss:SSSS'); }
    public getCurrentTimestamp(): number { return dayjs().valueOf(); }

    // ---> Booleans
    public isExpired(timestamp: number): boolean {
        return this.getCurrentTimestamp() >= timestamp;
    }
}
