import { Service } from "typedi";
import COUNTRIES from "./data/COUNTRIES";
import COUNTRIES_PHONE_PREFIX from "./data/COUNTRIES_PHONE_PREFIX";
import TIME_ZONES from "./data/TIME_ZONES.json";
import SECTION_SUBSECTIONS from "./data/SECTION_SUBSECTIONS.json";
import NAVBAR_PRIMARY_TOOLBAR_COMPONENTS from "./data/NAVBAR_PRIMARY_TOOLBAR_COMPONENTS.json";
import NAVBAR_SECONDARY_TOOLBAR_COMPONENTS from "./data/NAVBAR_SECONDARY_TOOLBAR_COMPONENTS.json";
import DASHBOARD_DEFAULT_OPTIONS from "./data/DASHBOARD_DEFAULT_OPTIONS.json";
import DASHBOARD_CHART_CATEGORIES from "./data/DASHBOARD_CHART_CATEGORIES.json";
import HOME_DEFAULT_OPTIONS from "./data/HOME_DEFAULT_OPTIONS.json";
import ONBOARDING_STEPS from "./data/ONBOARDING_STEPS.json";
import ONBOARDING_ROLES_MAPPING from "./data/ONBOARDING_ROLES_MAPPING.json";
import HOME_DATA from "./data/HOME_DATA.json";

import ICountries from "./types/ICountries";
import ICountriesPhonePrefix from "./types/ICountriesPhonePrefix";
import ITimeZone from "./types/ITimeZone";
import ISectionSubsections from "./types/ISectionSubsections";
import INavbarPrimaryToolbarComponents from "./types/INavbarPrimaryToolbarComponents";
import INavbarSecondaryToolbarComponents from "./types/INavbarSecondaryToolbarComponents";
import IDashboardDefaultOptions from "./types/IDashboardDefaultOptions";
import IDashboardChartCategories from "./types/IDashboardChartCategories";
import IHomeDefaultOptions from "./types/IHomeDefaultOptions";
import IOnboardingSteps from "./types/IOnboardingSteps";
import IOnboardingRolesMapping from "./types/IOnboardingRolesMapping";
import IHomeData from "./types/IHomeData";

@Service()
export default class StaticDataService {
    // ---> Getters
    public getCountries(): ICountries {
        return COUNTRIES;
    }

    public getCountriesPhonePrefix(): ICountriesPhonePrefix {
        return COUNTRIES_PHONE_PREFIX;
    }

    public getTimeZones(): ITimeZone[] {
        return TIME_ZONES.list;
    }

    public getSectionSubsections(): ISectionSubsections {
        return SECTION_SUBSECTIONS;
    }

    public getNavbarPrimaryToolbarComponents(): INavbarPrimaryToolbarComponents {
        return NAVBAR_PRIMARY_TOOLBAR_COMPONENTS;
    }

    public getNavbarSecondaryToolbarComponents(): INavbarSecondaryToolbarComponents {
        return NAVBAR_SECONDARY_TOOLBAR_COMPONENTS;
    }

    public getDashboardDefaultOptions(): IDashboardDefaultOptions {
        return DASHBOARD_DEFAULT_OPTIONS;
    }

    public getDashboardChartCategories(): IDashboardChartCategories {
        return DASHBOARD_CHART_CATEGORIES;
    }

    public getHomeDefaultOptions(): IHomeDefaultOptions {
        return HOME_DEFAULT_OPTIONS;
    }

    public getOnboardingSteps(): IOnboardingSteps {
        return ONBOARDING_STEPS;
    }

    public getOnboardingRolesMapping(): IOnboardingRolesMapping {
        return ONBOARDING_ROLES_MAPPING;
    }

    public getHomeData(): IHomeData {
        return HOME_DATA;
    }
}
