<template>
    <div @click="invokeSearch" class="button-search">
        <div class="search-wrapper">
            <span class="search-icon">
                <span class="svg-icon svg-icon-2">
                    <inline-svg
                        :src="
                            originUrl +
                            '/media/icons/duotune/general/gen004.svg'
                        "
                    />
                </span>
            </span>

            <span class="search-title hide@on-aside-minimized">Search</span>
        </div>
    </div>
</template>

<style lang="scss">
.button-search {
    position: relative;
    transition: color 0.1s;
    z-index: 9999;

    &:hover {
        color: #646dec;
        transition: color 0.3s;
    }

    .search-wrapper {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 0;
        flex: 0 0 100%;
        padding: 0.65rem 1rem;
        padding-left: 25px;
        padding-right: 25px;
        transition: none;
        outline: none !important;
    }

    .search-icon {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2rem;
        margin-right: 0.5rem;
        justify-content: flex-start;
    }

    .search-title {
        align-items: center;
        display: flex;
        flex-grow: 1;
        font-weight: 500;
    }
}
</style>

<script>
import ApiService from "@frameworks/metronic/core/services/ApiService";
import { Mutations } from "@frameworks/vue/store/enums/StoreEnums";
import store from "@frameworks/vue/store";
import { computed } from "vue";

export default {
    name: "search-container",
    mounted() {
        window.addEventListener("keyup", this.handleSearchOpen);
    },
    beforeUnmount() {
        window.removeEventListener("keyup", this.handleSearchOpen);
    },
    data() {
        const originUrl = window.location.origin;

        const visible = computed(() => {
            return store.getters.isSearchVisible;
        });

        return {
            originUrl,
            searchResults: [],
            visible,
        };
    },
    methods: {
        handleSearchOpen(event) {
            switch (event.key) {
                case "/":
                    this.invokeSearch();
                    break;
            }
        },

        invokeSearch() {
            store.commit(Mutations.SET_SEARCH_VISIBILITY, true);
        },

        hideSearch() {
            store.commit(Mutations.SET_SEARCH_VISIBILITY, false);
        },

        async loadSearch() {
            const response = await ApiService.get("search");
            store.commit(Mutations.SET_SEARCH_DATA, response.data);
        },
    },
    async created() {
        await this.loadSearch();
    },
};
</script>
