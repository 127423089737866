import { Mutations } from "@/frameworks/vue/store/enums/StoreEnums";
import { Module, Mutation, VuexModule } from "vuex-module-decorators";

export interface ISearchModule {
    search: any;
    visible: boolean,
}

@Module
export default class SearchModule extends VuexModule implements ISearchModule {
    search = null;
    visible = false;

    /**
     * Get search object
     * @returns User
     */
    get getSearchData(): any {
        return this.search;
    }

    get isSearchVisible(): any {
        return this.visible;
    }
    @Mutation
    [Mutations.SET_SEARCH_DATA](data: any) {
        this.search = data;
    }

    @Mutation
    [Mutations.SET_SEARCH_VISIBILITY](visible: boolean) {
        this.visible = visible;
    }
}
