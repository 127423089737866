import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@frameworks/metronic/core/services/JwtService";
import { AxiosResponse, AxiosRequestConfig, ResponseType } from "axios";
import Services from "@services/Services";

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
    /**
     * @description property to share vue instance
     */
    public static vueInstance: App;

    /**
     * @description initialize vue axios
     */
    public static init(app: App<Element>) {
        ApiService.vueInstance = app;
        ApiService.vueInstance.use(VueAxios, axios);
        ApiService.vueInstance.axios.defaults.baseURL =
            Services.getConfigs().getByEnv().apiExpressAddress;
        this.setHeader();
    }

    /**
     * @description set the default HTTP request headers
     */
    public static setHeader(): void {
        const token = JwtService.getToken();

        if (token) {
            ApiService.vueInstance.axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${token}`;
        }

        ApiService.vueInstance.axios.defaults.headers.common[
            "Content-Type"
        ] = `application/json`;

        // ApiService.vueInstance.axios.defaults.headers.common[
        //     "Access-Control-Allow-Origin"
        // ] = Services.getConfigs().getByEnv().apiExpressAddress;
    }

    /**
     * @description send the GET HTTP request
     * @param resource: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    public static query(
        resource: string,
        params: AxiosRequestConfig
    ): Promise<AxiosResponse> {
        return ApiService.vueInstance.axios
            .get(resource, params)
            .catch((error) => {
                throw new Error(`[KT] ApiService ${error}`);
            });
    }

    /**
     * @description send the GET HTTP request
     * @param resource: string
     * @param slug: string
     * @returns Promise<AxiosResponse>
     */
    public static get(
        resource: string,
        slug = "" as string
    ): Promise<AxiosResponse> {
        Services.getDebug().log(
            "GET",
            ApiService.vueInstance,
            ApiService.vueInstance.axios.defaults.baseURL,
            `/${resource}/${slug}`
        );

        return ApiService.vueInstance.axios
            .get(`${resource}/${slug}`)
            .catch((error) => {
                Services.getDebug().log("ERROR", error);
                throw new Error(`[KT] ApiService ${error}`);
            });
    }

    /**
     * @description set the POST HTTP request
     * @param resource: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    public static post(
        resource: string,
        params: AxiosRequestConfig
    ): Promise<AxiosResponse> {
        return ApiService.vueInstance.axios.post(`${resource}`, params);
    }

    /**
     * @description send the UPDATE HTTP request
     * @param resource: string
     * @param slug: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    public static update(
        resource: string,
        slug: string,
        params: AxiosRequestConfig
    ): Promise<AxiosResponse> {
        return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
    }

    /**
     * @description Send the PUT HTTP request
     * @param resource: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    public static put(
        resource: string,
        params: AxiosRequestConfig
    ): Promise<AxiosResponse> {
        return ApiService.vueInstance.axios.put(`${resource}`, params);
    }

    /**
     * @description Send the DELETE HTTP request
     * @param resource: string
     * @returns Promise<AxiosResponse>
     */
    public static delete(resource: string): Promise<AxiosResponse> {
        return ApiService.vueInstance.axios.delete(resource).catch((error) => {
            throw new Error(`[RWV] ApiService ${error}`);
        });
    }
}

export default ApiService;
