import UserModel from "@services/user/models/User.model";
import ApiService from "../../frameworks/metronic/core/services/ApiService";
import Services from "@services/Services";
import { Service } from "typedi";
@Service()
export default class UserService {
    // ---> Variables
    private _user: UserModel = new UserModel();

    // ---> Getters
    public getUser(): UserModel {
        return this._user;
    }

    public getAppVersion(): number {
        return this._user.getAppVersion();
    }

    // ---> Booleans
    public isAuthenticated(): boolean {
        return this._user.isAuthenticated();
    }

    public isAuthenticationChecked(): boolean {
        return this._user.isAuthenticationChecked();
    }

    // ---> Actions
    public async build(): Promise<void> {
        Services.getDebug().log("USER_SERVICE", "BUILDING");
    }

    public async updateByAuth(
        email: string,
        userId: string,
        role: number,
        appVersion: number,
        siteId?: string
    ): Promise<void> {
        this._user.setEmail(email);
        this._user.setId(userId);
        this._user.setAppVersion(appVersion);
        this._user.getRole().set(role);
        siteId && this._user.setSiteId(siteId);
        this._user.setAuthenticated(true);
        this._user.triggerUserReactivity();
    }

    public async update(): Promise<void> {
        const user = this._user;

        if (!user.getId()) {
            Services.getDebug().log("USER_SERVICE", "UPDATE", "NO_ID", user);
            return;
        }

        let userResults: any;

        try {
            userResults = await ApiService.get(`user/${user.getId()}`);
        } catch (e) {
            Services.getDebug().log(
                "USER_SERVICE",
                "UPDATE",
                "ERROR",
                "BAD_REQUEST",
                e
            );
            return;
        }

        const userResultData = userResults.data;

        if (!userResultData) {
            Services.getDebug().log(
                "USER_SERVICE",
                "UPDATE",
                "ERROR",
                "BAD_RESPONSE",
                user
            );
            return;
        }

        if (userResultData.siteId != undefined) {
            user.setSiteId(userResultData.siteId);
        }

        if (userResultData.role != undefined) {
            user.getRole().set(userResultData.role);
        }

        Services.getDebug().log("USER_SERVICE", "UPDATE", user, userResults);
    }
}
