import Services from "@services/Services";
import ChangePathnameEvent from "@services/iframe/events/ChangePathname.event";
import LoadedEvent from "@services/iframe/events/Loaded.event";
import BuildDoneEvent from "@services/iframe/events/BuildDone.event";
import DebugService from "@services/debug/Debug.service";
import Container, { Inject, Service } from "typedi";
import LoadAuthenticationCompleteEvent from "../events/LoadAuthenticationComplete.event";
import IframeModel from "../models/Iframe.model";
import WindowService from "@services/window/Window.service";
import { RequestParentLoginEvent } from "../events/RequestParentLogin.event";

@Service()
export default class IframeEventsModule {
    public constructor(
        @Inject() private _debugService: DebugService,
        @Inject() private _windowService: WindowService
    ) {}

    // ---> Events
    public onEvent(iframe: IframeModel, event: any): void {
        this._debugService.log("MESSAGE_FROM_IFRAME", event.data);

        if (!event.data) {
            return;
        }

        if (typeof event.data !== "string") {
            return;
        }

        let dataParsed: any;

        try {
            dataParsed = JSON.parse(event.data);
        } catch (e) {
            Services.getDebug().log("BAD JSON MESSAGE", e);
        }

        if (typeof dataParsed !== "object") {
            return;
        }

        if (!dataParsed.event) {
            return;
        }

        switch (dataParsed.event) {
            case "CHANGE_PATHNAME":
                Container.get(ChangePathnameEvent).on(iframe, dataParsed);
                break;
            case "STATE":
                Container.get(ChangePathnameEvent).on(iframe, dataParsed);
                break;
            case "LOADED":
                Container.get(LoadedEvent).on();
                break;
            case "EXTERNAL_LINK":
                this._windowService.openByMethod(
                    dataParsed.url,
                    dataParsed.method
                );
                break;
            case "LOAD_AUTHENTICATION_COMPLETE":
                Container.get(LoadAuthenticationCompleteEvent).on(
                    iframe,
                    dataParsed
                );
                break;
            case "BUILD_DONE":
                Container.get(BuildDoneEvent).on(iframe, dataParsed);
                break;
            case "REQUEST_PARENT_LOGIN":
                Container.get(RequestParentLoginEvent).on(dataParsed);
                break;
        }
    }

    // ---> Actions
    public addListeners(iframe: IframeModel): void {
        const iframeElement = iframe.getElement();

        if (iframeElement === null) {
            throw new Error("Iframe element not found");
        }

        window.addEventListener(
            "message",
            (event: any) => {
                this.onEvent(iframe, event);
            },
            false
        );
    }
}
