export default class IframeModel {
    private _urlInit: string;
    private _urlQueryInit: string;
    private _element: HTMLIFrameElement | null = null;
    private _loaded = false;
    private _ready = false;

    public constructor(urlInit: string, urlQueryInit: string) {
        this._urlInit = urlInit;
        this._urlQueryInit = urlQueryInit;
    }

    // ---> Getters
    public getUrlInit(): string {
        if (this._urlInit.charAt(this._urlInit.length - 1) === "/") {
            return this._urlInit.slice(0, -1);
        }

        return this._urlInit + this._urlQueryInit;
    }

    public getUrlQueryInit(): string {
        return this._urlQueryInit;
    }

    public getElement(): HTMLIFrameElement | null {
        return this._element;
    }

    // ---> Setters
    public setUrlInit(url: string): void {
        this._urlInit = url;
    }

    public setElement(element: HTMLIFrameElement | null): void {
        this._element = element;
    }

    public setLoaded(loaded: boolean): void {
        this._loaded = loaded;
    }

    public setReady(ready: boolean): void {
        this._ready = ready;
    }

    // ---> Booleans
    public isLoaded(): boolean {
        return this._loaded;
    }

    public isReady(): boolean {
        return this._ready;
    }
}
