
import { defineComponent } from "vue";
import router from "@frameworks/vue/router/clean";
import RoutingInteractor from "@interactors/routing/Routing.interactor";

export default defineComponent({
    name: "CancelSectionOptions",
    methods: {
        cancelOptions() {
            let section = RoutingInteractor.getCurrentSection(false, false, true);

            if (section && section.split("/").length > 1) {
                section = section.split("/")[1];
            }

            const subsection = RoutingInteractor.getCurrentSubsection(false, false, true);
            router.push("/" + section + "/" + subsection);
            return;
        }
    }
});
