import IConfigsMo from "@services/configs/types/IConfigsMo";

const CONFIGS_STAGING: IConfigsMo = {
    iframeViewAddress: "https://staging-legacy.multiorders.com",
    apiExpressAddress: "https://api-staging.multiorders.com/api/v2",
    v3API: "https://stagingserverapiv3.multiorders.com",
    v1API: "https://api-staging.multiorders.com/api",
    featureFlagAddress: "https://stagingserverapiv3.multiorders.com",
};

export default CONFIGS_STAGING;
