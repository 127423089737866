import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "section-buttons-container"
}
const _hoisted_2 = {
  key: 0,
  class: "mo-btn mx-2 disabled-subsection"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.subsections)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subsections, (subsection) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: String(subsection)
          }, [
            (_ctx.isDisabled[subsection.route])
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(subsection.title), 1))
              : (_openBlock(), _createBlock(_component_router_link, {
                  key: 1,
                  class: _normalizeClass(["mo-btn mx-2", _ctx.currentSubsection === subsection.route ? 'active-subsection' : 'subsection']),
                  to: '/' + _ctx.currentSection + '/' + subsection.route
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(subsection.title), 1)
                  ]),
                  _: 2
                }, 1032, ["class", "to"]))
          ], 64))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}