import { Service } from "typedi";
import Services from "@services/Services";
import RoutingInteractor from "@interactors/routing/Routing.interactor";

interface IComponent {
    component: string;
}
interface ISubsection {
    [subPath: string]: IComponent[];
}

@Service()
export default class NavbarInteractor {
    // navbar

    public static getNavbarPrimaryToolbarComponents():
        | IComponent[]
        | undefined {
        const section = RoutingInteractor.getCurrentSection(false, false, true);
        const subsection = RoutingInteractor.getCurrentSubsection(
            false,
            false,
            true
        );

        if (!section || !subsection) {
            return undefined;
        }

        const currentNavbarPrimaryToolbarComponents: ISubsection | undefined =
            Services.getStaticData().getNavbarPrimaryToolbarComponents()[
                section
            ];

        if (!currentNavbarPrimaryToolbarComponents) {
            return undefined;
        }

        return currentNavbarPrimaryToolbarComponents[subsection];
    }

    public static getNavbarSecondaryToolbarComponents():
        | IComponent[]
        | undefined {
        const section = RoutingInteractor.getCurrentSection(false, false, true);
        const subsection = RoutingInteractor.getCurrentSubsection(
            false,
            false,
            true
        );

        if (!section || !subsection) {
            return undefined;
        }

        const currentNavbarPrimaryToolbarComponents: ISubsection | undefined =
            Services.getStaticData().getNavbarSecondaryToolbarComponents()[
                section
            ];

        if (!currentNavbarPrimaryToolbarComponents) {
            return undefined;
        }

        return currentNavbarPrimaryToolbarComponents[subsection];
    }

    // navbar
}
