import ConfigsService from "@services/configs/Configs.service";
import { Inject, Service } from "typedi";
import IframeModel from "./models/Iframe.model";
import IframeEventsModule from "./modules/IframeEvents.module";
import IframeEventsEmitterModule from "./modules/IframeEventsEmitter.module";
import IframePageAuthorizerModule from "./modules/IframePageAuthorizor.module";
import IframePathCheckerModule from "./modules/iframePathChecker.module";

@Service()
export default class IframeService {
    public constructor(
        @Inject(() => IframeEventsModule)
        private _iframeEventsModule: IframeEventsModule,
        @Inject() private _iframeEventsEmitterModule: IframeEventsEmitterModule,
        @Inject() private _iframePathCheckerModule: IframePathCheckerModule,
        @Inject() private readonly _configsService: ConfigsService,
        @Inject()
        private readonly _iframePageAuthorizerModule: IframePageAuthorizerModule
    ) {}

    // ---> Variables
    private readonly _iframe = new IframeModel(
        this._configsService.getByEnv().iframeViewAddress +
            window.location.pathname,
        window.location.search
    );

    // ---> Getters
    public getIframeModel(): IframeModel {
        return this._iframe;
    }

    // ---> Setters
    public setInitPathname(pathname: string): void {
        this.getIframeModel().setUrlInit(
            this._configsService.getByEnv().iframeViewAddress + pathname
        );
    }
    // ---> Booleans
    public isAuthorizedRoute(pathname: string): boolean {
        return this._iframePathCheckerModule.isPathAuthorizedByFullCheck(
            pathname
        );
    }

    public isComponentsVisible(pathname: string): boolean {
        return this._iframePathCheckerModule.isComponentsVisible(pathname);
    }

    public isMetronicRoute(pathname: string): boolean {
        return this._iframePathCheckerModule.isPathMetronic(pathname);
    }

    // ---> Actions
    public addListeners(): void {
        this._iframeEventsModule.addListeners(this._iframe);
    }

    public emitStartMount(): void {
        this._iframeEventsEmitterModule.emitStartMount(this._iframe);
    }

    public emitLoginUpdate(data: any): void {
        const event: any = {
            event: "LOGIN_UPDATE",
            data: data,
        };

        this._iframeEventsEmitterModule.emit(this._iframe, event);
    }

    public emitWixData(data: {
        reg: boolean;
        wixData: { refreshToken: string; instanceId: string };
    }) {
        this._iframeEventsEmitterModule.emit(this._iframe, {
            event: "WIX_LOGIN",
            data,
        });
    }

    public emitShopifyData(data: {
        shop: string;
        shopName: string;
        email: string;
        accessToken: string;
        siteId: number;
        userId: number;
    }) {
        this._iframeEventsEmitterModule.emit(this._iframe, {
            event: "ADD_SHOPIFY_INTEGRATION",
            data,
        });
    }

    public emitBigCommerceData(data: {
        reg: boolean;
        bcData: { accessToken: string; storeHash: string };
    }) {
        this._iframeEventsEmitterModule.emit(this._iframe, {
            event: "ADD_BIGCOMMERCE_INTEGRATION",
            data,
        });
    }

    public async emitUpdateCredentialsStorage(
        accessKey: string | null,
        token: string | null,
        userId: string | null
    ): Promise<void> {
        return await this._iframeEventsEmitterModule.emitUpdateCredentialsStorage(
            this._iframe,
            accessKey,
            token,
            userId
        );
    }

    public emitLogin(data: any): void {
        const event: any = {
            event: "LOGIN",
            data: data,
        };

        this._iframeEventsEmitterModule.emit(this._iframe, event);
    }

    public emitLogout(): void {
        const event: any = {
            event: "LOGOUT",
        };

        this._iframeEventsEmitterModule.emit(this._iframe, event);
    }

    public authorize(pathname: string): void {
        this._iframePageAuthorizerModule.authorize(pathname);
    }

    public toggleComponents(state: boolean): void {
        this._iframePageAuthorizerModule.toggle(state);
    }
}
