import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-a8d9fc8a")
const _hoisted_1 = {
  class: "modal fade",
  id: "dataModal",
  tabindex: "-1",
  "aria-labelledby": "exampleModalLabel",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog modal-xl modal-dialog-centered w-max-con w-min-unset" }
const _hoisted_3 = { class: "modal-content overflow-hidden" }
const _hoisted_4 = { class: "modal-body p-0 m-0" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SupportToolTable = _resolveComponent("SupportToolTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_SupportToolTable, { tableData: _ctx.modalDataBody }, null, 8, ["tableData"])
        ])
      ])
    ])
  ]))
}