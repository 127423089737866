import store from "@frameworks/vue/store";
import { Mutations } from "@frameworks/vue/store/enums/StoreEnums";
import UserRoleModel from "./UserRole.model";

export default class UserModel {
    private _id = '';
    private _email = '';
    private _siteId = '';
    private _role = new UserRoleModel();
    private _appVersion = 1;
    private _authenticated = false;
    private _authenticationChecked = false;

    // ---> Getters
    public getId(): string {
        return this._id;
    }

    public getEmail(): string {
        return this._email;
    }

    public getSiteId(): string {
        return this._siteId;
    }

    public getAppVersion(): number {
        return this._appVersion;
    }

    public getRole(): UserRoleModel {
        return this._role;
    }
    
    // ---> Setters
    public setId(id: string): void {
        this._id = id;
    }

    public setEmail(email: string): void {
        this._email = email;
    }
    
    public setSiteId(id: string): void {
        this._siteId = id;
    }

    public setAppVersion(version: number): void {
        this._appVersion = version;
    }

    public setAuthenticated(state: boolean): void {
        this._authenticated = state;
    }

    public setAuthenticationChecked(state: boolean): void {
        this._authenticationChecked = state;
    }


    // ---> Booleans
    public isAuthenticated(): boolean {
        return this._authenticated;
    }

    public isAuthenticationChecked(): boolean {
        return this._authenticationChecked;
    }

    // ---> Actions
    public triggerUserReactivity(): void {
        store.commit(Mutations.USER_UPDATED);
    }
}
