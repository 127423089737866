
import { defineComponent, onMounted, onUpdated } from "vue";
import { useI18n } from "vue-i18n";
import { ToggleComponent } from "@/assets/ts/components/_ToggleComponent";
import KTMenu from "@components/layout/aside/Menu.vue";
import { asideTheme } from "@frameworks/metronic/core/helpers/config";
import { DrawerComponent } from "@/assets/ts/components/_DrawerComponent";
import { ScrollComponent } from "@/assets/ts/components/_ScrollComponent";
import RouterIframeSync from "@frameworks/vue/router/RouterIframeSync.service";
import EXTERNAL_LINK_METHOD from "@frameworks/vue/router/EXTERNAL_LINK_METHOD";
import Services from "@services/Services";
import UserService from "@services/user/User.service";
import Container from "typedi";

export default defineComponent({
    name: "KTAside",
    components: {
        KTMenu,
    },
    props: {
        lightLogo: String,
        darkLogo: String,
    },
    setup() {
        const { t } = useI18n();

        onMounted(() => {
            ToggleComponent.reinitialization();
            DrawerComponent.bootstrap();
            ScrollComponent.bootstrap();
            DrawerComponent.updateAll();
            ScrollComponent.updateAll();
        });

        onUpdated(() => {
            ToggleComponent.bootstrap();
        });



        const originUrl = window.location.origin;

        return {
            originUrl,
            asideTheme,
            t,
        };
    },
    data() {
        const defaultRoute = "/order-list/new/1";

        return {
            defaultRoute,
            appVersion: true,
        }
    },
    methods: {
        changeAppVersion: async function () {
            const version: number = this.appVersion ? 1 : 0;
            const user = Container.get(UserService).getUser();
            const switchResponse = await Services.getApi().setBetaVersionByUserId(
                Number(user.getId()),
                version,
            );

            console.log(this.appVersion, version, user);

            if (switchResponse === null) {
                this.appVersion = true;
            }

            if (this.appVersion) {
                return false;
            }

            const url = Services.getConfigs().getByEnv().iframeViewAddress + window.location.pathname + window.location.search;
            RouterIframeSync.getInstance().openExternalLink(url, EXTERNAL_LINK_METHOD.LOCATION);
        }
    }
});
