import IdGeneratorUtility from "@utilities/id-generator/IdGenerator.utility";
import { Inject, Service } from "typedi";

@Service()
export default class DeviceIdGeneratorModule {
    public constructor(
        @Inject() private _idGeneratorUtility: IdGeneratorUtility,
    ) {}

    public generate(): string {
        return this._idGeneratorUtility.generateV4();
    }
}
