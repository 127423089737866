
import { defineComponent, onMounted, ref, computed, inject } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { ScrollComponent } from "@/assets/ts/components/_ScrollComponent";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import { version } from "@frameworks/metronic/core/helpers/documentation";
import { asideMenuIcons } from "@frameworks/metronic/core/helpers/config";
import MainMenuConfig from "@frameworks/metronic/core/config/MainMenuConfig";
import RouterIframeSync from "@frameworks/vue/router/RouterIframeSync.service";
import MOSearchContainer from "@components/layout/search/SearchContainer.vue";
import SITE_USER_ROLES from "@services/user/constants/SITE_USER_ROLES";
import store from "@frameworks/vue/store";
import { Actions } from "@frameworks/vue/store/enums/StoreEnums";
import { useStore } from "vuex";
import UserService from "@services/user/User.service";
import ConfigsService from "@services/configs/Configs.service";
import LocalStorageService from "@services/local-storage/LocalStorage.service";
import Container from "typedi";
import { FeatureFlagInteractor } from "@interactors/feature-flags/FeatureFlags.interactor";
import HomeInteractor from "@interactors/home/Home.interactor";
import Interactors from "@interactors/Interactors";
import RoutingInteractor from "@interactors/routing/Routing.interactor";
import OnboardingInteractor from "@interactors/onboarding/Onboarding.interactor";
export default defineComponent({
    name: "kt-menu",
    components: {
        MOSearchContainer,
    },
    setup() {
        const { t, te } = useI18n();
        const route = useRoute();
        const store = useStore();
        const scrollElRef = ref<null | HTMLElement>(null);
        const configService = Container.get(ConfigsService);
        const localStorageService = Container.get(LocalStorageService);
        onMounted(() => {
            ScrollComponent.reinitialization();
            MenuComponent.reinitialization();
            if (scrollElRef.value) {
                scrollElRef.value.scrollTop = 0;
            }
        });
        const translate = (text) => {
            if (te(text)) {
                return t(text);
            } else {
                return text;
            }
        };
        const originUrl = window.location.origin;
        const angularNavigation = (pathname) => {
            Container.get(RouterIframeSync).navigate(pathname);
        };
        return {
            userUpdated: computed(() => store.getters.getUserUpdated),
            angularNavigation,
            originUrl,
            asideMenuIcons,
            version,
            translate,
        };
    },
    watch: {
        userUpdated: function (newValue) {
            this.$forceUpdate();
        },
    },
    data() {
        let $mitt: any;
        return {
            MainMenuConfig,
            $mitt
        }
    },
    mounted() {
        this.$mitt = inject('$mitt');
        this.$mitt.on('onboarding-step-update', (currentSectionProgress) => {
            this.$nextTick(() => {
                this.handleIsOnboardingHidden(currentSectionProgress);
            });
        });
        this.handleIsOnboardingHidden();
    },
    beforeUnmount() {
        this.$mitt.all.delete('onboarding-step-update', (currentSectionProgress) => {
            this.$nextTick(() => {
                this.handleIsOnboardingHidden(currentSectionProgress);
            });
        })
    },
    methods: {
        isActivePath(subsections: Array<string>) {
            return subsections.includes(this.$route.path);
        },
        handleOnboardingClick(section: string) {
            if (section !== "Onboarding") {
                return;
            };
            this.$mitt.emit("aside-menu-onboarding-click");
        },
        async handleIsOnboardingHidden(currentSectionProgress = undefined): Promise<void> {
            let firstTimeSetupProgress, advancedGuideProgress;
            const subsection = RoutingInteractor.getCurrentSubsection(false, false, true);

            //this way of getting progress solves gql giving old progress
            if (subsection === "first-time-setup") {
                firstTimeSetupProgress = currentSectionProgress ? currentSectionProgress : await OnboardingInteractor.getFirstTimeSetupProgress();
                advancedGuideProgress = await OnboardingInteractor.getAdvancedGuideProgress();
            } else if (subsection === "advanced-guide") {
                advancedGuideProgress = currentSectionProgress ? currentSectionProgress : await OnboardingInteractor.getAdvancedGuideProgress();
                firstTimeSetupProgress = await OnboardingInteractor.getFirstTimeSetupProgress();
            } else {
                firstTimeSetupProgress = await OnboardingInteractor.getFirstTimeSetupProgress();
                advancedGuideProgress = await OnboardingInteractor.getAdvancedGuideProgress();
            }


            const firstTimeSetupProgressKeys = Object.keys(firstTimeSetupProgress);
            const advancedGuideProgressKeys = Object.keys(advancedGuideProgress);

            let isFirstTimeSetupComplete = true, isAdvancedGuideComplete = true;

            for (const keyIndex in firstTimeSetupProgressKeys) {
                const key = firstTimeSetupProgressKeys[keyIndex];
                const step = firstTimeSetupProgress[key];
                if (!step) {
                    isFirstTimeSetupComplete = false;
                    break;
                }
            }

            for (const keyIndex in advancedGuideProgressKeys) {
                const key = advancedGuideProgressKeys[keyIndex];
                const step = advancedGuideProgress[key];
                if (!step) {
                    isAdvancedGuideComplete = false;
                    break;
                }
            }

            const menuConfig = this.MainMenuConfig;
            for (const sectionGroupIndex in menuConfig) {
                const sectionGroupHeading = menuConfig[sectionGroupIndex].heading;
                if (sectionGroupHeading === "General") {
                    const generalPages = menuConfig[sectionGroupIndex].pages;
                    for (const sectionIndex in generalPages) {
                        const section = generalPages[sectionIndex];
                        if (section.heading === "Onboarding") {
                            if (isFirstTimeSetupComplete && isAdvancedGuideComplete) {
                                this.MainMenuConfig[sectionGroupIndex].pages[sectionIndex]['isHidden'] = true;
                            } else {
                                this.MainMenuConfig[sectionGroupIndex].pages[sectionIndex]['isHidden'] = false;
                            }
                            return;
                        }
                    }
                }
            }
            return;
        },
        isAnyPageEnabled(item) {
            for (const page in item.pages) {
                if (this.isFeatureEnabled(item.pages[page].feature)) {
                    return true;
                }
            }
            return false;
        },
        isFeatureEnabled(feature: string | undefined) {
            if (feature === undefined) {
                return true;
            }
            return FeatureFlagInteractor.hasflag(feature);
        },
        unminimizeAside: () => {
            store.dispatch(Actions.REMOVE_BODY_ATTRIBUTE, {
                qulifiedName: "data-kt-aside-minimize",
            });
        },
        isUserInheritRoles: function (roles: SITE_USER_ROLES[]): boolean {
            const user = Container.get(UserService).getUser();
            const role = user.getRole();
            // Always allow add-admin
            if (role.isAppAdmin()) { return true; }
            // Check if there is any role requirements
            if (!roles || !Array.isArray(roles)) { return true; }
            // Owner & Admin have all privileges except app-admin
            if (roles.includes(SITE_USER_ROLES.APP_ADMIN) === false && (role.isOwner() || role.isAdmin())) { return true; }
            // Check if user has any of the required roles
            const isRoleInherited: boolean = roles.includes(role.get());
            return isRoleInherited;
        },
        isSearchEnabled: function (): boolean {
            const user = Container.get(UserService).getUser();
            return Interactors.getSearch().isEnabledByUserRole(user);
        },
    },
});
