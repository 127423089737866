
import { defineComponent, inject } from "vue";
import SupportToolTable from "@/views/support/SupportToolTable.vue";

export default defineComponent({
    name: "SupportToolModal",
    components: { SupportToolTable },
    data() {
        let $mitt;

        //data modal
        let modalDataHeader = '';
        let modalDataBody;

        return {
            $mitt,

            //data modal
            modalDataHeader,
            modalDataBody,
        }
    },
    mounted() {
        this.$mitt = inject("$mitt");
        this.$mitt.on("update-data-modal", ({ header, body }) => { this.updateDataModal({ header, body }) });
    },
    beforeUnmount() {
        this.$mitt.all.delete('update-data-modal', ({ header, body }) => { this.updateDataModal({ header, body }) });
    },
    methods: {
        updateDataModal({ header, body }) {
            this.modalDataBody = body;
            this.modalDataHeader = header;
        },
    }
});
