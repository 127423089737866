import router from "@frameworks/vue/router/clean";
import Services from "@services/Services";
import IframePageAuthorizerModule from "@services/iframe/modules/IframePageAuthorizor.module";
import IframeModel from "../models/Iframe.model";
import { Inject, Service } from "typedi";

@Service()
export default class ChangePathnameEvent {
    public constructor(
        @Inject()
        private readonly _iframePageAuthorizerModule: IframePageAuthorizerModule
    ) {}

    public on(iframe: IframeModel, data: any): void {
        Services.getDebug().log("EVENT", "CHANGE_PATHNAME");
        Services.getDebug().log("NEW_HISTORY_STATE", data);
        Services.getDebug().log("SEARCH STRING", window.location.search);

        if (!iframe.isReady()) {
            Services.getDebug().log("EVENT", "CANCELED_CHANGE_PATHNAME");
            return;
        }

        if (
            data.pathname == undefined ||
            data.state == undefined ||
            data.hash == undefined
        ) {
            return;
        }

        this._iframePageAuthorizerModule.authorize(data.pathname);

        const searchParams = new URLSearchParams(window.location.search);
        const query = Array.from(searchParams.entries()).reduce(
            (accumulator, entry) => {
                const [key, value] = entry;
                accumulator[key] = value;

                return accumulator;
            },
            {}
        );

        router.replace({
            path: data.pathname,
            query,
            hash: data.hash,
        });
    }
}
