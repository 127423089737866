import { ObjectDirective } from "vue";

const createElementOnClick = (): HTMLElement => {
    const newEl: HTMLElement = document.createElement("div");
    newEl.className =
        "TOOLTIP position-absolute top-100 start-50 card px-4 py-2 h1 shadow-sm fw-bold fs-2 m-0 mt-2 cursor-text";

    newEl.style.transform = "translate(-50%, 0)";
    newEl.style.width = "max-content";
    newEl.style.zIndex = "51";
    return newEl;
};

const createElementOnHover = (): HTMLElement => {
    const newEl: HTMLElement = document.createElement("div");
    newEl.className =
        "TOOLTIP position-absolute top-100 start-50 card px-4 py-2 h1 shadow-sm fw-bold fs-2 m-0 cursor-text";

    newEl.style.transform = "translate(-50%, 0)";
    newEl.style.width = "max-content";
    newEl.style.maxWidth = "95%";
    newEl.style.zIndex = "52";
    return newEl;
};

const Tooltip = {
    mounted(el, binding) {
        if (binding.arg === "click") {
            el.handleClick = (event: Event) => {
                const target: HTMLElement | null =
                    event.target as HTMLElement | null;
                if (target !== null) {
                    if (target.classList.contains("TOOLTIP")) {
                        return;
                    }
                }

                el.classList.toggle("directive-tooltip-click");

                if (el.classList.contains("directive-tooltip-click")) {
                    const newEl: HTMLElement = createElementOnClick();
                    if (binding.value.prefix) {
                        newEl.innerHTML =
                            binding.value.prefix + " " + binding.value.data;
                    } else {
                        newEl.innerHTML = binding.value.data;
                    }

                    el.tempEl = el.appendChild(newEl);
                    return;
                } else {
                    el.tempEl.remove();
                    return;
                }
            };
            el.addEventListener("click", el.handleClick);
        } else {
            el.handleMouseEnter = (event: Event) => {
                if (el.offsetWidth < el.scrollWidth) {
                    const newEl: HTMLElement = createElementOnHover();
                    newEl.innerHTML = binding.value.data;

                    el.tempEl = el.parentNode.appendChild(newEl);
                    return;
                }
                return;
            };
            el.addEventListener("mouseenter", el.handleMouseEnter);

            el.handleMouseLeave = (event: Event) => {
                el.tempEl.remove();
                return;
            };
            el.addEventListener("mouseleave", el.handleMouseLeave);
        }
        return;
    },
    unmounted(el, binding) {
        if (binding.arg === "click") {
            document.removeEventListener("click", el.handleClick);
            return;
        }
        document.removeEventListener("mouseenter", el.handleMouseEnter);
        document.removeEventListener("mouseleave", el.handleMouseLeave);
        return;
    },
};

export default Tooltip;
