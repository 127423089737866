import Services from "@services/Services";
import IBuildable from "./types/core/IBuildable";
import Frameworks from "./frameworks/Frameworks";

export default class AppBuilder implements IBuildable {
    public async build(): Promise<void> {
        Services.getDebug().log('APP', 'BUILDING', 'START');

        Services.getConfigs().build();
        Services.getDevice().build();
        await Services.getTimezone().build();
        Frameworks.getVue().build();

        Services.getDebug().log('APP', 'BUILDING', 'DONE');
    }
}
