import { Service } from "typedi";

@Service()
export default class ColorsUtility {
    // ---> Actions
    public getColorsByIndex(size: number, index: number): string {
        const colorsArray = this.getColorsArrayBySize(size);

        return colorsArray[index];
    }

    public getColorsArrayBySize(size: number): Array<string> {
        const colors: Array<string> = [
            "#008FFB",
            "#00E396",
            "#FEB019",
            "#FF4560",
            "#775DD0",
        ];

        const colorsArraySize = size - colors.length;

        function createColor(i: number): string {
            const red: string = sin_to_hex(i, (0 * Math.PI * 2) / 3);
            const blue: string = sin_to_hex(i, (1 * Math.PI * 2) / 3);
            const green: string = sin_to_hex(i, (2 * Math.PI * 2) / 3);

            return "#" + red + blue + green;
        }

        function sin_to_hex(i: number, phase: number): string {
            const sin: string | number = Math.sin(
                (Math.PI / size) * 2 * i + phase
            );
            const int: string | number = Math.floor(sin * 127) + 128;
            const hex: string | number = int.toString(16);

            return hex.length === 1 ? "0" + hex : hex;
        }

        for (let i = 0; i < colorsArraySize; i++) {
            colors.push(createColor(i + (size * 5) / 3.25));
        }

        return colors;
    }
}
