import { FeatureFlagService } from "@services/feature-flags/FeatureFlags.service";
import Container, { Service } from "typedi";

@Service()
export class FeatureFlagInteractor {
    public static hasflag(name: string) {
        const service = Container.get(FeatureFlagService);

        return service.hasFlag(name);
    }
}
