import { v4 as uuidV4 } from 'uuid';
import { Service } from 'typedi';

/**
 * Generate Id Utility
 * 
 * Generates Id
 */
@Service()
export default class IdGeneratorUtility {
    // ---> Actions
    public generateV4(): string {
        return uuidV4();
    }
}
