//imports for app initialization
import "@frameworks/metronic/core/plugins/keenthemes";
import "@frameworks/metronic/core/plugins/prismjs";
import "bootstrap";

import IFramework from "../types/IFramework";
import VueModel from "./models/Vue.model";

import ApiService from "@frameworks/metronic/core/services/ApiService";
import { initApexCharts } from "@frameworks/metronic/core/plugins/apexcharts";
import { initInlineSvg } from "@frameworks/metronic/core/plugins/inline-svg";
import { initVeeValidate } from "@frameworks/metronic/core/plugins/vee-validate";

import router from "@frameworks/vue/router/clean";
import store from "@/frameworks/vue/store";
import ElementPlus from "element-plus";
import middleware from "@grafikri/vue-middleware";
import i18n from "@frameworks/metronic/core/plugins/i18n";
import { Store } from "vuex";
import Container, { Inject, Service } from "typedi";
import VueModelBuilder from "./builders/VueModel.builder";
import AuthenticationInteractor from "@interactors/authentication/Authentication.interactor";

import mitt from "mitt";

import Tooltip from "@/directives/tooltip";
import IntercomService from "@services/intercom/Intercom.service";
import SmartlookService from "@services/smartlook/smartlook.service";

@Service()
export default class VueFramework implements IFramework {
    public constructor(@Inject() private _vueModelBuilder: VueModelBuilder) {}

    // ---> Variables
    private _vueModel: VueModel | null = null;

    // ---> Getters
    public getVue(): VueModel | null {
        return this._vueModel;
    }

    public getStore(): Store<any> {
        return store;
    }

    // ---> Actions
    public build(): void {
        this._vueModel = this._vueModelBuilder.build();
        ApiService.init(this._vueModel.getApp());
        initApexCharts(this._vueModel.getApp());
        initInlineSvg(this._vueModel.getApp());
        initVeeValidate();
    }

    public mount(): void {
        if (this._vueModel === null) {
            throw Error("FRAMEWORK_VUE: App is not builded");
        }

        // Setup authentication router middleware
        router.beforeEach(async () => {
            await Container.get(AuthenticationInteractor).authenticate();
        });

        // Setup router middleware plugin
        router.beforeEach(middleware());

        // Setup other plugins;
        this._vueModel.getApp().use(store);
        this._vueModel.getApp().use(router);
        this._vueModel.getApp().use(ElementPlus);
        this._vueModel.getApp().use(i18n);
        this._vueModel.getApp().directive();

        const $mitt = mitt();
        this._vueModel.getApp().provide("$mitt", $mitt);

        this._vueModel.getApp().directive("tooltip", Tooltip);

        this._vueModel.getApp().mount("#app");

        Container.get(IntercomService).bootIntercom();
    }
}
