
import { defineComponent } from 'vue';
import DashboardOptionsRestoreModal from './dashboard/DashboardOptionsRestoreModal.vue';
import RoutingInteractor from '@interactors/routing/Routing.interactor';
import HomeOptionsRestoreModal from './home/HomeOptionsRestoreModal.vue';
import SupportToolModal from './supportTool/SupportToolModal.vue';

export default defineComponent({
    components: { DashboardOptionsRestoreModal, HomeOptionsRestoreModal, SupportToolModal },
    name: "Modals",
    watch: {
        '$route'(to, from) {
            const section = RoutingInteractor.getCurrentSection(false, false, true);
            if (section === "dashboard" || section === "settings/dashboard") {
                this.isDashboardOptionsModal = true;
                this.isHomeOptionsModal = false;
            } else {
                this.isDashboardOptionsModal = false;
            }

            if (section === "home" || section === "settings/home") {
                this.isDashboardOptionsModal = false;
                this.isHomeOptionsModal = true;
            } else {
                this.isHomeOptionsModal = false;
            }
        },
    },
    data() {
        const isDashboardOptionsModal = false, isHomeOptionsModal = false;
        return {
            isDashboardOptionsModal,
            isHomeOptionsModal,
        }
    },
});
