import { Mutations } from "@frameworks/vue/store/enums/StoreEnums";
import VueFramework from "@frameworks/vue/Vue.framework";
import ApiService from "@services/api/Api.service";
import DeviceService from "@services/device/Device.service";
import IframeService from "@services/iframe/Iframe.service";
import LocalStorageService from "@services/local-storage/LocalStorage.service";
import UserService from "@services/user/User.service";
import WindowService from "@services/window/Window.service";
import { Inject, Service } from "typedi";

@Service()
export default class LoginModule {
    public constructor(
        @Inject() protected readonly _userService: UserService,
        @Inject() protected readonly _apiService: ApiService,
        @Inject(() => IframeService)
        protected readonly _iFrameService: IframeService,
        @Inject() protected readonly _deviceService: DeviceService,
        @Inject() protected readonly _localStorageService: LocalStorageService,
        @Inject() protected readonly _vueFramework: VueFramework,
        @Inject() protected readonly _windowService: WindowService
    ) {}

    public async login(email: string, password: string, reg = false) {
        try {
            // 1. Get login response
            const res = await this._apiService.login(
                email,
                password,
                this._deviceService.getDeviceId()
            );

            // 2. Update credentials
            this.updateCredentials(res.data);

            // 3. Handle app version
            // ! Deprecated
            /*await this.handleAppversion(
                res.data.accessKey,
                res.data.token,
                res.data.userId,
                res.data.appVersion
            );*/

            // 4. Update user model
            this.updateUserModel(res.data);

            // 5. Update iframe with data
            (res as any).data.reg = reg;
            this._iFrameService.emitLogin(res.data);

            // 6. Clear error messages
            this._vueFramework.getStore().commit(Mutations.SET_ERROR, []);
        } catch (e) {
            this._vueFramework
                .getStore()
                .commit(Mutations.SET_ERROR, ["Bad credentials"]);

            throw e;
        }
    }

    /**
     * ! Deprecated
     * @param accessKey
     * @param token
     * @param userId
     * @param appVersion
     * @returns
     */
    protected async handleAppversion(
        accessKey: string,
        token: string,
        userId: number,
        appVersion: number
    ): Promise<void> {
        // 1. Validate app version
        if (appVersion === undefined) {
            throw new Error("App version is not defined");
        }

        // 2. If app version is equal to current app version do nothing
        if (appVersion > 0) {
            return;
        }

        // 3. Update iframe credentials storage
        await this._iFrameService.emitUpdateCredentialsStorage(
            accessKey,
            token,
            String(userId)
        );

        // 4. Update iframe init url
        this._iFrameService.setInitPathname("/order-list/new/1");

        // 5. Get redirect path
        const url = this._iFrameService.getIframeModel().getUrlInit();

        // 6. Redirect to old app version
        this._windowService.openByLocation(url);
    }

    protected updateCredentials(data: any): void {
        // 1. Get and validate access key
        const accessKey = data.accessKey;

        if (!accessKey) {
            throw new Error("Access key not defined");
        }

        // 2. Get and validate user id
        const userId = data.userId;

        if (userId === undefined) {
            throw new Error("User id is not defined");
        }

        // 3. Get and validate token
        const token = data.token;

        if (!token) {
            throw new Error("Token is not defined");
        }

        // 4. Update localstorage with credentials
        this._localStorageService.setUserAccessKey(accessKey);
        this._localStorageService.setUserId(String(userId));
        this._localStorageService.setUserToken(token);
    }

    protected updateUserModel(data: any): void {
        // 1. Get and validate email
        const email = data.email;

        if (!email) {
            throw new Error("Email is not defined");
        }

        // 2. Get and validate user id
        const userId = data.userId;

        if (userId === undefined) {
            throw new Error("User id is not defined");
        }

        // 3. Get and validate role
        const role = data.role;

        if (role === undefined) {
            throw new Error("Role is not defined");
        }

        // 4. Get and validate app version
        const appVersion = data.appVersion;

        if (appVersion === undefined) {
            throw new Error("App version is not defined");
        }

        // 5. Update user model
        this._userService.updateByAuth(email, userId, role, appVersion);
    }
}
