import LayoutConfigTypes from "@frameworks/metronic/core/config/LayoutConfigTypes";
import store from "@frameworks/vue/store";
import { Actions, Mutations } from "@frameworks/vue/store/enums/StoreEnums";
import Services from "@services/Services";
import { Inject, Service } from "typedi";
import IframePathCheckerModule from "./iframePathChecker.module";

@Service()
export default class IframePageAuthorizerModule {
    private _isLastPageAuthorized = true;

    public constructor(
        @Inject() private _iframePathCheckerModule: IframePathCheckerModule,
    ) {}

    public authorize(pathname): void {
        Services.getDebug().log("AUTHORIZING_PAGE", this.isComponentsVisible(pathname));

        /*if (this._inited === false) {
            this._inited = true;
            this._isLastPageAuthorized = IframePageAuthorizer.isPageAuthorized(window.location.pathname)
        }*/

        if (this.isComponentsVisible(pathname) && !this._isLastPageAuthorized) {
            this._isLastPageAuthorized = true;
            this.toggleAside(true);
            this.toggleHeader(true);
        } else if (!this.isComponentsVisible(pathname) && this._isLastPageAuthorized) {
            this._isLastPageAuthorized = false;
            this.toggleAside(false);
            this.toggleHeader(false);
        }
    }

    public toggle(state: boolean) {
        this._isLastPageAuthorized = state;
        this.toggleAside(state);
        this.toggleHeader(state);
    }

    private toggleAside(state: boolean) {
        Services.getDebug().log("ASIDE_TOGGLE", state);
        const configs: LayoutConfigTypes = store.getters.layoutConfig();

        this._isLastPageAuthorized = state;
        configs.aside.display = state;

        store.commit(Mutations.SET_LAYOUT_CONFIG, configs);

        if (state) {
            store.dispatch(Actions.ADD_BODY_CLASSNAME, "aside-enabled");
            store.dispatch(Actions.ADD_BODY_CLASSNAME, "aside-fixed");
        } else {
            store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "aside-enabled");
            store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "aside-fixed");
        }
    }

    private toggleHeader(state: boolean) {
        Services.getDebug().log("HEADER_TOGGLE", state);
        const configs: LayoutConfigTypes = store.getters.layoutConfig();

        this._isLastPageAuthorized = state;
        configs.header.display = state;
        configs.header.fixed.tabletAndMobile = state;

        store.commit(Mutations.SET_LAYOUT_CONFIG, configs);

        if (state) {
            store.dispatch(
                Actions.ADD_BODY_CLASSNAME,
                "header-tablet-and-mobile-fixed"
            );
        } else {
            store.dispatch(
                Actions.REMOVE_BODY_CLASSNAME,
                "header-tablet-and-mobile-fixed"
            );
        }
    }

    public isComponentsVisible(pathname: string): boolean {
        return this._iframePathCheckerModule.isComponentsVisible(pathname);
    }
}
