
import { defineComponent } from "vue";
import RoutingInteractor from "@interactors/routing/Routing.interactor";

export default defineComponent({
  name: "SectionOptions",
  components: {},
  watch: {
    '$route'(to, from) {
      this.sectionClean = RoutingInteractor.getCurrentSection(true, true);
      this.sectionRaw = RoutingInteractor.getCurrentSection(false, false, true);
      this.subsection = RoutingInteractor.getCurrentSubsection(false, false, true);
    },
  },
  data() {
    return {
      sectionClean: RoutingInteractor.getCurrentSection(true, true),
      sectionRaw: RoutingInteractor.getCurrentSection(false, false, true),
      subsection: RoutingInteractor.getCurrentSubsection(false, false, true),
    }
  }
});
