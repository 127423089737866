import REQUEST_METHODS from "./constants/REQUEST_METHODS";
import ApiAccessKeyModule from "./modules/ApiAccessKey.module";
import API_ENDPOINTS from "./constants/API_ENDPOINTS";
import IApiLoginResponse from "./types/IApiLoginResponse";
import { Service, Inject } from "typedi";
import ApiTokenModule from "./modules/ApiToken.module";
import IApiBaseResponse from "./types/IApiBaseResponse";
import ITimeZone from "@services/static-data/types/ITimeZone";

@Service()
export default class ApiService {
    public constructor(
        @Inject() private _apiAccessKey: ApiAccessKeyModule,
        @Inject() private _apiToken: ApiTokenModule
    ) {}

    // ---> Getters
    public async getBetaVersionByUserId(userId: number): Promise<any> {
        return await this._apiToken.send(
            REQUEST_METHODS.GET,
            `${API_ENDPOINTS.BETA}/${userId}`,
            true
        );
    }

    // ---> Setters
    public async setBetaVersionByUserId(
        userId: number,
        version: number
    ): Promise<void> {
        return await this._apiToken.send(
            REQUEST_METHODS.POST,
            `${API_ENDPOINTS.BETA}/${version}/${userId}`,
            true
        );
    }

    // ---> Actions
    public async authenticate(): Promise<IApiLoginResponse> {
        return await this._apiAccessKey.send(
            REQUEST_METHODS.POST,
            API_ENDPOINTS.AUTHENTICATE,
            true
        );
    }

    public async createAccount(
        email: string,
        password: string,
        nameFirst: string,
        nameLast: string,
        phoneNumber: string,
        country: string,
        timezone: ITimeZone
    ): Promise<IApiBaseResponse> {
        return await this._apiAccessKey.send(
            REQUEST_METHODS.POST,
            API_ENDPOINTS.CREATE_ACCOUNT,
            false,
            {
                email,
                password,
                nameFirst,
                nameLast,
                phoneNumber,
                country,
                timezone,
            }
        );
    }

    public async loginBySiteId(siteId: number): Promise<IApiBaseResponse> {
        return await this._apiAccessKey.send(
            REQUEST_METHODS.POST,
            API_ENDPOINTS.ADMIN_LOGIN_BY_SITE_ID,
            true,
            {
                siteId,
            }
        );
    }

    public async authorizeSession(): Promise<IApiBaseResponse> {
        return await this._apiAccessKey.send(
            REQUEST_METHODS.POST,
            API_ENDPOINTS.AUTHORIZE_SESSION,
            true
        );
    }

    public async login(
        email: string,
        password: string,
        deviceId: string
    ): Promise<IApiLoginResponse> {
        return await this._apiAccessKey.send(
            REQUEST_METHODS.POST,
            API_ENDPOINTS.LOGIN,
            false,
            {
                email,
                password,
                deviceId,
            }
        );
    }
}
