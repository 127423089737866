import EXTERNAL_LINK_METHOD from "@frameworks/vue/router/EXTERNAL_LINK_METHOD";
import Services from "@services/Services";
import { Service } from "typedi";

@Service()
export default class WindowService {
    public openByMethod(url: string, method: EXTERNAL_LINK_METHOD): void {
        if (!url) {
            Services.getDebug().log(
                "ERROR",
                "Failed to open external link, undefined url"
            );
            return;
        }

        if (!method) {
            Services.getDebug().log(
                "ERROR",
                "Failed to open external link, undefined method"
            );
            return;
        }

        Services.getDebug().log("REDIRECTING", url, method);

        switch (method) {
            case EXTERNAL_LINK_METHOD.LOCATION:
                this.openByLocation(url);
                break;
            case EXTERNAL_LINK_METHOD.LOCATION_HREF:
                this.openByLocationHref(url);
                break;
            case EXTERNAL_LINK_METHOD.BLANK:
                this.openTab(url);
                break;
        }
    }

    public openTab(url: string): void {
        window.open(url, "_blank");
    }

    public openByLocationHref(url: string): void {
        window.location.href = url;
    }

    public openByLocation(url: string): void {
        (window.location as any) = url;
    }
}
