import { gql } from "graphql-tag";

const SMARTLOOK_QUERY = gql`
    query {
        me {
            email
            site {
                id
                siteOrdersCount
            }
        }
    }
`;

export { SMARTLOOK_QUERY };
