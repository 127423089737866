import Base64Util from "./Base64/Base64.utility";
import IBase64Util from "./Base64/types/IBase64Util";
import CryptUtil from "./Crypt/Crypt.utility";
import ICryptUtil from "./Crypt/types/ICryptUtil";
import HttpClientUtility from "./http-client/HttpClient.utility";
import IHttpClient from "./http-client/types/IHttpClient";
import TimeUtil from "./Time/Time.utility";
import ITimeUtil from "./Time/types/ITimeUtil";
import { Container } from "typedi";
import IdGeneratorUtility from "./id-generator/IdGenerator.utility";
import ColorsUtility from "./Colors/Colors.utility";
import FormatsUtility from "./Number/Formats.utility";
import DatesUtility from "./Dates/Dates.utility";

export default class Utilities {
    public static getTime(): ITimeUtil {
        return Container.get(TimeUtil);
    }

    public static getIdGenerator(): IdGeneratorUtility {
        return Container.get(IdGeneratorUtility);
    }

    public static getCrypt(): ICryptUtil {
        return Container.get(CryptUtil);
    }

    public static getBase64(): IBase64Util {
        return Container.get(Base64Util);
    }

    public static getHttpClient(): IHttpClient {
        return Container.get(HttpClientUtility);
    }

    public static getColors(): ColorsUtility {
        return Container.get(ColorsUtility);
    }

    public static getFormatted(): FormatsUtility {
        return Container.get(FormatsUtility);
    }

    public static getDates(): DatesUtility {
        return Container.get(DatesUtility);
    }
}
