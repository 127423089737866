
import { defineComponent, computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import KTAsideNew from "@components/layout/aside/AsideNew.vue";
import KTAsideOld from "@components/layout/aside/AsideOld.vue";
import Search from "@components/layout/search/Search.vue";
import KTLoader from "@components/Loader.vue";
import HtmlClass from "@frameworks/metronic/core//services/LayoutService";
import { removeModalBackdrop } from "@frameworks/metronic/core/helpers/dom";
import {
    MenuComponent,
    DrawerComponent,
    ScrollComponent,
} from "@/assets/ts/components/index";
import {
    toolbarDisplay,
    loaderEnabled,
    contentWidthFluid,
    loaderLogo,
    asideEnabled,
    headerEnabled,
    subheaderDisplay,
    themeLightLogo,
    themeDarkLogo,
} from "@frameworks/metronic/core/helpers/config";
import { Actions } from "@frameworks/vue/store/enums/StoreEnums";
import { FeatureFlagInteractor } from "@interactors/feature-flags/FeatureFlags.interactor";

export default defineComponent({
    name: "Layout",
    components: {
        KTAsideNew,
        KTAsideOld,
        KTLoader,
        Search
    },
    data() {
        const isUiToggleEnabled = FeatureFlagInteractor.hasflag("ui-toggle");

        return {
            isUiToggleEnabled
        }
    },
    setup() {
        const store = useStore();
        const route = useRoute();

        // show page loading
        store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");



        // initialize html element classes
        HtmlClass.init();

        const pageTitle = computed(() => {
            return store.getters.pageTitle;
        });

        const breadcrumbs = computed(() => {
            return store.getters.pageBreadcrumbPath;
        });

        onMounted(async () => {
            DrawerComponent.bootstrap();
            ScrollComponent.bootstrap();
            DrawerComponent.updateAll();
            ScrollComponent.updateAll();
        });

        watch(
            () => route.path,
            () => {
                MenuComponent.hideDropdowns(undefined);

                DrawerComponent.hideAll();

                // check if current user is authenticated
                if (!store.getters.isUserAuthenticated) {
                    //router.push({ name: "sign-in" });
                }

                removeModalBackdrop();
            }
        );

        return {
            toolbarDisplay,
            loaderEnabled,
            contentWidthFluid,
            loaderLogo,
            headerEnabled,
            asideEnabled,
            subheaderDisplay,
            pageTitle,
            breadcrumbs,
            themeLightLogo,
            themeDarkLogo,
        };
    },
});
