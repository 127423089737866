import LoginV3Module from "@interactors/login/modules/LoginV3.module";
import { Inject, Service } from "typedi";

type RequestParantLoginPayload = {
    event: "REQUEST_PARENT_LOGIN";
    data: {
        email: string;
        password: string;
        registration: boolean;
    };
};

@Service()
export class RequestParentLoginEvent {
    @Inject(() => LoginV3Module)
    private readonly loginModule!: LoginV3Module;

    public on(payload: RequestParantLoginPayload) {
        this.handleLogin(payload.data);
    }

    private handleLogin(data: RequestParantLoginPayload["data"]) {
        const { email, password, registration } = data;

        this.loginModule.loginV3(email, password, registration);
    }
}
