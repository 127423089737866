import { Service } from "typedi";
import Services from "@services/Services";
import { Container } from "typedi";
import UserService from "@services/user/User.service";
import IOnboardingSteps from "@services/static-data/types/IOnboardingSteps";
import HomeInteractor from "@interactors/home/Home.interactor";
import {
    useQuery,
    useMutation,
    provideApolloClient,
} from "@vue/apollo-composable";
import { GET_GUIDES_PROGRESS, COMPLETE_GUIDE_STEP } from "./OnboardingQueries";
import { GraphqlInteractor } from "@interactors/graphql/Graphql.interactor";

@Service()
export default class OnboardingInteractor {
    public static getUserFirstTimeSetupMapping(): string[] | undefined {
        const SITE_USER_ROLES = [
            "app_admin",
            "admin",
            "owner",
            "inventory_manager",
            "orders_manager",
            "manager",
            "orders_inventory_manager",
            "no_role",
        ];
        const user = Container.get(UserService).getUser();
        const role = SITE_USER_ROLES[user.getRole().get()];

        const rolesMapping =
            Services.getStaticData().getOnboardingRolesMapping();

        const roleSteps = rolesMapping[role]["first_time_setup"];

        return roleSteps;
    }

    public static getUserAdvancedGuideMapping(): string[] | undefined {
        const SITE_USER_ROLES = [
            "app_admin",
            "admin",
            "owner",
            "inventory_manager",
            "orders_manager",
            "manager",
            "orders_inventory_manager",
            "no_role",
        ];
        const user = Container.get(UserService).getUser();
        const role = SITE_USER_ROLES[user.getRole().get()];

        const rolesMapping =
            Services.getStaticData().getOnboardingRolesMapping();
        const roleSteps = rolesMapping[role]["advanced_guide"];

        return roleSteps;
    }

    public static getAllSteps(): IOnboardingSteps {
        const allSteps = Services.getStaticData().getOnboardingSteps();
        return allSteps;
    }

    public static async updateFirstTimeSetupProgress(
        step: string
    ): Promise<void> {
        const client = GraphqlInteractor.getClient();
        provideApolloClient(client);

        const progress = await this.getFirstTimeSetupProgress();
        if (!progress) {
            return;
        }

        progress[step] = 1;

        //PUT backend
        const mutateGuides: any = useMutation(COMPLETE_GUIDE_STEP);
        mutateGuides.mutate({ guideId: "g1", guideStepId: step });

        // localStorage.setItem(
        //     "onboarding-first-time-setup-progress",
        //     JSON.stringify(progress)
        // );

        //disable onboarding progress card in home if options exist
        const onboardingProgress: any =
            await HomeInteractor.getOnboardingProgress();
        if (onboardingProgress.disableProgressBar) {
            let homeOptions: any = sessionStorage.getItem(
                "Home-personal-homepage-options"
            );
            if (homeOptions !== null) {
                homeOptions = JSON.parse(homeOptions);

                for (const key in homeOptions) {
                    if (homeOptions[key].component === "HomeProgress") {
                        homeOptions[key].options.isEnabled = false;

                        homeOptions = JSON.stringify(homeOptions);
                        sessionStorage.setItem(
                            "Home-personal-homepage-options",
                            homeOptions
                        );
                    }
                }
            }
        }

        return new Promise((resolve, reject) => {
            mutateGuides.onDone(() => {
                resolve();
            });
        });
    }

    public static async updateAdvancedGuideProgress(
        step: string
    ): Promise<void> {
        const client = GraphqlInteractor.getClient();
        provideApolloClient(client);

        const progress = await this.getAdvancedGuideProgress();
        if (!progress) {
            return;
        }

        progress[step] = 1;
        //PUT backend
        const mutateGuides: any = useMutation(COMPLETE_GUIDE_STEP);
        mutateGuides.mutate({ guideId: "g2", guideStepId: step });
        // localStorage.setItem(
        //     "onboarding-advanced-guide-progress",
        //     JSON.stringify(progress)
        // );

        const onboardingProgress: any =
            await HomeInteractor.getOnboardingProgress();
        if (onboardingProgress.disableProgressBar) {
            let homeOptions: any = sessionStorage.getItem(
                "Home-personal-homepage-options"
            );
            if (homeOptions !== null) {
                homeOptions = JSON.parse(homeOptions);

                for (const key in homeOptions) {
                    if (homeOptions[key].component === "HomeProgress") {
                        homeOptions[key].options.isEnabled = false;

                        homeOptions = JSON.stringify(homeOptions);
                        sessionStorage.setItem(
                            "Home-personal-homepage-options",
                            homeOptions
                        );
                        return;
                    }
                }
            }
        }

        return;
    }

    public static async getFirstTimeSetupProgress(): Promise<any> {
        return new Promise((resolve, reject) => {
            const client = GraphqlInteractor.getClient();
            provideApolloClient(client);

            const roleSteps = this.getUserFirstTimeSetupMapping();

            if (!roleSteps) {
                resolve(false);
                return;
            }

            const defaultProgress: any = {};
            for (let idx = 0; idx < roleSteps.length; idx++) {
                defaultProgress[roleSteps[idx]] = 0;
            }

            const meGuidesQuery = useQuery(GET_GUIDES_PROGRESS, {
                fetchPolicy: "cache-and-network",
            });

            meGuidesQuery.onResult((meGuidesQueryResult) => {
                meGuidesQueryResult = meGuidesQueryResult.data.me.guides;

                let userProgress: any = null;
                for (const guide in meGuidesQueryResult) {
                    if (meGuidesQueryResult[guide].id === "g1") {
                        userProgress = {};
                        const steps = meGuidesQueryResult[guide].steps;
                        for (const step in steps) {
                            userProgress[steps[step].id] =
                                steps[step].isCompleted;
                        }
                    }
                }
                // let userProgress: any = localStorage.getItem(
                //     "onboarding-first-time-setup-progress"
                // );

                if (userProgress === null) {
                    //fetch backend else use defaultProgress
                    userProgress = defaultProgress;

                    const userProgressKeys = Object.keys(userProgress);
                    for (const idx in defaultProgress) {
                        //add new steps
                        if (!userProgressKeys.includes(idx)) {
                            userProgress[idx] = 0;
                        }
                    }
                    const defaultProgressKeys = Object.keys(defaultProgress);
                    for (const idx in userProgress) {
                        //remove irrelevant steps
                        if (!defaultProgressKeys.includes(idx)) {
                            delete userProgress[idx];
                        }
                    }

                    resolve(userProgress);
                    return;
                }

                const userProgressKeys = Object.keys(userProgress);
                for (const idx in defaultProgress) {
                    //add new steps
                    if (!userProgressKeys.includes(idx)) {
                        userProgress[idx] = 0;
                    }
                }
                const defaultProgressKeys = Object.keys(defaultProgress);
                for (const idx in userProgress) {
                    //remove irrelevant steps
                    if (!defaultProgressKeys.includes(idx)) {
                        delete userProgress[idx];
                    }
                }

                resolve(userProgress);
                return;
            });
        });
    }

    public static async getAdvancedGuideProgress(): Promise<any> {
        return new Promise((resolve) => {
            const SITE_USER_ROLES = [
                "app_admin",
                "admin",
                "owner",
                "inventory_manager",
                "orders_manager",
                "manager",
                "orders_inventory_manager",
                "no_role",
            ];
            const user = Container.get(UserService).getUser();
            const role = SITE_USER_ROLES[user.getRole().get()];

            const rolesMapping =
                Services.getStaticData().getOnboardingRolesMapping();
            const roleSteps = rolesMapping[role]["advanced_guide"];

            if (!roleSteps) {
                resolve(false);
                return;
            }

            const defaultProgress: any = {};

            for (let idx = 0; idx < roleSteps.length; idx++) {
                defaultProgress[roleSteps[idx]] = 0;
            }

            const meGuidesQuery: any = useQuery(GET_GUIDES_PROGRESS, {
                fetchPolicy: "cache-and-network",
            });

            meGuidesQuery.onResult((meGuidesQueryResult) => {
                meGuidesQueryResult = meGuidesQueryResult.data.me.guides;

                let userProgress: any = null;

                for (const guide in meGuidesQueryResult) {
                    if (meGuidesQueryResult[guide].id === "g2") {
                        userProgress = {};
                        const steps = meGuidesQueryResult[guide].steps;
                        for (const step in steps) {
                            userProgress[steps[step].id] =
                                steps[step].isCompleted;
                        }
                    }
                }
                // let userProgress: any = localStorage.getItem(
                //     "onboarding-first-time-setup-progress"
                // );

                if (userProgress === null) {
                    //fetch backend else use defaultProgress
                    userProgress = defaultProgress;

                    const userProgressKeys = Object.keys(userProgress);
                    for (const idx in defaultProgress) {
                        //add new steps
                        if (!userProgressKeys.includes(idx)) {
                            userProgress[idx] = 0;
                        }
                    }
                    const defaultProgressKeys = Object.keys(defaultProgress);
                    for (const idx in userProgress) {
                        //remove irrelevant steps
                        if (!defaultProgressKeys.includes(idx)) {
                            delete userProgress[idx];
                        }
                    }

                    resolve(userProgress);
                    return;
                }

                const userProgressKeys = Object.keys(userProgress);
                for (const idx in defaultProgress) {
                    //add new steps
                    if (!userProgressKeys.includes(idx)) {
                        userProgress[idx] = 0;
                    }
                }
                const defaultProgressKeys = Object.keys(defaultProgress);
                for (const idx in userProgress) {
                    //remove irrelevant steps
                    if (!defaultProgressKeys.includes(idx)) {
                        delete userProgress[idx];
                    }
                }

                resolve(userProgress);
                return;
            });
        });
    }
}
