import ConfigsService from "@services/configs/Configs.service";
import Base64Util from "@utilities/Base64/Base64.utility";
import { Service, Inject } from "typedi";

@Service()
export default class LocalStorageModule {
    public constructor(
        @Inject() private _base64Utility: Base64Util,
        @Inject() private _configsService: ConfigsService
    ) {}

    // ---> Getters
    public get(key: string, isJson = false): null | any {
        if (key === "") {
            return null;
        }

        const storage = window.localStorage;
        const value: string | null = storage.getItem(key);

        if (value === null) {
            return null;
        }

        // let result = value;

        // if (value != null && !this._configsService.get().isTesting()) {
        //     try {
        //         result = this._base64Utility.decode(value);
        //     } catch (error) {
        //         console.error(error);
        //     }
        // }

        if (isJson) {
            try {
                return JSON.parse(value);
            } catch (e) {
                return null;
            }
        }

        return value;
    }

    // ---> Setters
    public set(key: string, value: any): boolean {
        if (key === "") {
            return false;
        }

        if (value === null) {
            return false;
        }

        if (typeof value === "object") {
            value = JSON.stringify(value);
        }

        const storage = window.localStorage;
        // let valueFinal = value;

        //
        // if (!this._configsService.get().isTesting()) {
        //     valueFinal = this._base64Utility.encode(value);
        // }

        // storage.setItem(key, valueFinal);
        storage.setItem(key, value);

        return true;
    }

    // ---> Actions
    public delete(key: string): boolean {
        if (key === "") {
            return false;
        }

        const storage = window.localStorage;

        storage.removeItem(key);

        return true;
    }

    public clear(): void {
        window.localStorage.clear();
    }
}
