import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  id: "kt_toolbar_container",
  class: "\n                remove-last-border-toolbar remove-last-first-padding\n                d-flex\n                align-items-center\n                toolbar-items\n                position-relative\n                flex-noshrink\n                w-max-con\n                py-0\n            "
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isIframe && _ctx.navbarComponents)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["toolbar position-relative pt-0 px-12 top-0 start-0", _ctx.toolbarOverflow]),
        id: "kt_toolbar"
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["position-absolute w-100 h-100 bg-white z-index-3 opacity-50", { 'd-none': !_ctx.isAnyDropdownOpen && !_ctx.isLoading }])
        }, null, 2),
        _createElementVNode("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navbarComponents, (item) => {
            return (_openBlock(), _createBlock(_resolveDynamicComponent(item.component), {
              key: item,
              class: "w-max-con",
              id: item.component,
              dropdownHandler: _ctx.handleDropdownToggle,
              mutateFilterRef: _ctx.mutateFilterRef,
              filterRef: _ctx.filterRef
            }, null, 8, ["id", "dropdownHandler", "mutateFilterRef", "filterRef"]))
          }), 128))
        ])
      ], 2))
    : _createCommentVNode("", true)
}