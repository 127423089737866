import Services from "@services/Services";
import { Service } from "typedi";

@Service()
export default class IframePathCheckerModule {
    public isPathAuthorizedByFullCheck(pathname: string): boolean {
        const isAuthorized = this.isPathAuthorized(pathname);

        if (!isAuthorized) {
            return false;
        }

        const isAuthorizedDirect = this.isPathAuthorizedDirectly(pathname);

        return isAuthorizedDirect;
    }

    public isPathAuthorizedDirectly(pathname: string): boolean {
        if (pathname === "/") {
            return false;
        }

        const notAuthorizedRoutes = [
            "/account/create",
            "/account/create/big-commerce",
        ];

        const isAuthorized = !notAuthorizedRoutes.includes(pathname);

        return isAuthorized;
    }

    public isPathAuthorized(pathname: string): boolean {
        if (pathname === "/") {
            return false;
        }

        const notAuthorizedPathname = [
            "/choose-app",
            "/auth",
            "/post-reg-your-industry",
            "/post-reg-details",
            "/post-reg-people",
            "/post-reg-about-you",
            "/post-reg-orders",
            "/post-reg-products",
            "/post-reg-integrations",
            "/post-reg-features",
            "/post-reg-describe",
            "/bigcommerce-registartion-success",
        ];

        const pathnameTrimmed = "/" + pathname.split("/")[1];
        const isAuthorized = !notAuthorizedPathname.includes(pathnameTrimmed);

        return isAuthorized;
    }

    public isComponentsVisible(pathname: string): boolean {
        if (pathname === "/") {
            return false;
        }

        // Abstract list of routes that shouldn't be visible
        const notAuthorizedPathname = [
            "/choose-app",
            "/auth",
            "/doc",
            "/print",
            "/post-reg-your-industry",
            "/post-reg-details",
            "/post-reg-people",
            "/post-reg-about-you",
            "/post-reg-orders",
            "/post-reg-products",
            "/post-reg-integrations",
            "/post-reg-features",
            "/post-reg-describe",
            "/bigcommerce-registartion-success",
        ];

        const pathnameTrimmed = "/" + pathname.split("/")[1];

        if (notAuthorizedPathname.includes(pathnameTrimmed)) {
            return false;
        }

        // Exact list of routes that should't be visible
        const notAuthorizedRoutes = [
            "/account/create",
            "/account/create/big-commerce",
        ];

        return !notAuthorizedRoutes.includes(pathname);
    }

    public isPathMetronic(pathname: string): boolean {
        if (pathname === "/") {
            return false;
        }

        const metronicRoutes = [
            "/home/personal-homepage",
            "/settings/home/personal-homepage",

            "/onboarding/first-time-setup",
            "/onboarding/advanced-guide",

            "/picking-and-packing/packing-jobs",
            "/picking-and-packing/picking-jobs",
            "/picking-and-packing/picking-jobs/:123",
            "/picking-and-packing/stock-take",

            "/dashboard/overview",
            "/dashboard/orders-and-sales",
            "/settings/dashboard/overview",
            "/settings/dashboard/orders-and-sales",

            "/auth/login",
            "/account/create",
            "/account/create/big-commerce",
            "/cpanel",
            "/bigcommerce-registartion-success",
            "/admin/support-tool",
            "/choose-app",
        ];

        const metronicRoutesWithParam = ["/picking-and-packing/picking-jobs"];

        let isIncluded = metronicRoutes.includes(pathname);
        if (!isIncluded) {
            if (
                metronicRoutesWithParam.includes(
                    pathname.replace(/([/]+[\d+]+$)/g, "")
                )
            ) {
                isIncluded = true;
            }
        }

        Services.getDebug().log("IS_METRONIC_ROUTE", pathname, isIncluded);

        return isIncluded;
    }
}
