
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import KTHeader from "@components/navbar/Header.vue";
import {
    headerEnabled,
} from "@frameworks/metronic/core/helpers/config";
import KTToolbar from '@components/navbar/Toolbar.vue'
import Toolbar2 from "@components/navbar/Toolbar2.vue";

export default defineComponent({
    name: "Layout",
    props: {
        isIframe: Boolean,
    },
    components: {
        KTHeader,
        KTToolbar,
        Toolbar2
    },
    setup() {
        const store = useStore();

        const pageTitle = computed(() => {
            return store.getters.pageTitle;
        });

        return {
            headerEnabled,
            pageTitle,
        };
    },
});
