import IHttpClientOptions from "@utilities/http-client/types/IHttpClientOptions";
import API_ENDPOINTS from "../constants/API_ENDPOINTS";
import REQUEST_METHODS from "../constants/REQUEST_METHODS";
import { Service, Inject } from "typedi";
import HttpClientUtility from "@utilities/http-client/HttpClient.utility";
import LocalStorageService from "@services/local-storage/LocalStorage.service";
import ConfigsService from "@services/configs/Configs.service";
import ResponseHeadersProcessorModule from "./ResponseHeadersProcessor.module";

@Service()
export default class ApiAccessKeyModule {
    public constructor(
        @Inject() private _httpClientUtility: HttpClientUtility,
        @Inject() private _localStorageService: LocalStorageService,
        @Inject() private _configsService: ConfigsService,
        @Inject()
        private _responseHeadersProcessorModule: ResponseHeadersProcessorModule
    ) {}

    // ---> Actions
    public async send(
        method: REQUEST_METHODS,
        endpoint: API_ENDPOINTS,
        needAccessKey: boolean,
        data?: any
    ): Promise<any> {
        return await this.request(method, endpoint, needAccessKey, data);
    }

    private async request(
        method: REQUEST_METHODS,
        endpoint: API_ENDPOINTS,
        needAccessKey: boolean,
        data?: any
    ): Promise<any> {
        try {
            // 1. Get default header
            const headers = this.getDefaultHeader(needAccessKey);

            // 2. Setup request configs
            const options: IHttpClientOptions = {
                method,
                url: `${
                    this._configsService.getByEnv().apiExpressAddress
                }${endpoint}`,
                data,
                headers,
            };

            // 3. Send request
            const response = await this._httpClientUtility.send(options);

            // 4. Process response headers
            this._responseHeadersProcessorModule.processHeaders(response);

            return response;
        } catch (e: any) {
            console.error("API_SERVICE", e);
            console.error("REQUEST_RESPONSE", e.response);

            this._responseHeadersProcessorModule.processHeaders(e.response);

            return e.response;
        }
    }

    private applyAccesKeyHeader(header: any): boolean {
        const accessKey: string | null =
            this._localStorageService.getUserAccessKey();

        if (accessKey === null) {
            return false;
        }

        header["access-key"] = `${accessKey}`;

        return true;
    }

    private getDefaultHeader(needAccessKey: boolean): any {
        const header = {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
        };

        if (!needAccessKey) {
            return header;
        }

        const hasAccessKey = this.applyAccesKeyHeader(header);

        if (!hasAccessKey) {
            throw Error("Api Service: AccessKey required");
        }

        return header;
    }
}
