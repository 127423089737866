import Container, { Inject, Service } from "typedi";
import LocalStorageService from "@services/local-storage/LocalStorage.service";
import DeviceIdGeneratorModule from "./modules/DeviceIdGenerator.module";

@Service()
export default class DeviceService {
    public constructor(
        @Inject() private _localStorageService: LocalStorageService,
        @Inject() private _deviceIdGeneratorModule: DeviceIdGeneratorModule,
    ) {}

    // ---> Getters
    public getDeviceId(): string {
        const id = this._localStorageService.getDeviceId();

        if (id === null) {
            return this.setupDeviceId();
        }

        return id;
    }

    // ---> Actions
    public build(): void {
        // Retrieves device id. If it doesn't exist, it will be created
        this.getDeviceId();
    }

    public setupDeviceId(): string {
        const id = this._deviceIdGeneratorModule.generate();

        this._localStorageService.setDeviceId(id);

        return id;
    }
}
