import { Service } from "typedi";
import Services from "@services/Services";

@Service()
export default class RoutingInteractor {
    // routing

    public static getSectionSubsections():
        | Array<{ title: string; route: string }>
        | undefined {
        const path: string[] = window.location.pathname.split("/");

        let currentSection: string;

        if (path.length === 4) {
            currentSection = path[1] + "/" + path[2];
            console.log(currentSection);
        } else {
            currentSection = window.location.pathname.split("/")[1];
        }

        if (!currentSection) {
            return undefined;
        }

        const subsections: Array<{ title: string; route: string }> | undefined =
            Services.getStaticData().getSectionSubsections()[currentSection];

        return subsections;
    }

    public static getCurrentSection(
        capitalize = false,
        spaced = false,
        raw = false
    ): string | undefined {
        const path: string[] = window.location.pathname.split("/");

        let currentSection: string;

        if (path.length === 4) {
            currentSection = path[1] + "/" + path[2];
        } else {
            currentSection = window.location.pathname.split("/")[1];
        }

        if (raw) {
            return currentSection;
        }

        if (capitalize) {
            const currentSubsectionWords = currentSection.split("-");
            const currentSubsectionCapitalizedWords: string[] = [];
            for (const word in currentSubsectionWords) {
                const capitalizedWord =
                    currentSubsectionWords[word][0].toUpperCase() +
                    currentSubsectionWords[word].substring(1);
                currentSubsectionCapitalizedWords.push(capitalizedWord);
            }

            currentSection = currentSubsectionCapitalizedWords.join(" ");
        } else {
            currentSection = currentSection.replaceAll("-", " ");
        }

        if (!spaced) {
            return currentSection.replaceAll(" ", "");
        }

        return currentSection;
    }

    public static getCurrentSubsection(
        capitalize = false,
        spaced = false,
        raw = false
    ): string | undefined {
        const path: string[] = window.location.pathname.split("/");
        let currentSubsection: string;

        if (path.length === 4) {
            currentSubsection = path[3];
        } else {
            currentSubsection = window.location.pathname.split("/")[2];
        }

        if (!currentSubsection) {
            return undefined;
        }

        if (raw) {
            return currentSubsection;
        }

        if (capitalize) {
            const currentSubsectionWords = currentSubsection.split("-");
            const currentSubsectionCapitalizedWords: string[] = [];

            for (const word in currentSubsectionWords) {
                const capitalizedWord =
                    currentSubsectionWords[word][0].toUpperCase() +
                    currentSubsectionWords[word].substring(1);
                currentSubsectionCapitalizedWords.push(capitalizedWord);
            }

            currentSubsection = currentSubsectionCapitalizedWords.join(" ");
        } else {
            currentSubsection = currentSubsection.replaceAll("-", " ");
        }

        if (!spaced) {
            return currentSubsection.replaceAll(" ", "");
        }

        return currentSubsection;
    }

    // routing
}
