import { Inject, Service } from "typedi";
import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client/core";
import { onError } from "@apollo/client/link/error";
import { logErrorMessages } from "@vue/apollo-util";
import ConfigsService from "@services/configs/Configs.service";
import LocalStorageService from "@services/local-storage/LocalStorage.service";
import RouterIframeSync from "@frameworks/vue/router/RouterIframeSync.service";

@Service()
export default class GraphQLService {
    public client: ApolloClient<unknown>;

    constructor(
        @Inject() private readonly configService: ConfigsService,
        @Inject() private readonly localstorageService: LocalStorageService,
        @Inject() private readonly iframeRoutingService: RouterIframeSync
    ) {
        const httpLink = new HttpLink({
            uri: `${this.configService.getByEnv().v3API}/graphql`,
            fetch: (uri: RequestInfo, options: RequestInit) => {
                options.headers = this.mergeAuthHeaders(options.headers);
                return fetch(uri, options);
            },
        });
        const errorLink = onError((error) => {
            if (this.configService.get().isDevelop()) {
                logErrorMessages(error);
            }

            const isJWTExpired = error.graphQLErrors?.find(
                (entry) => entry.message === "jwt expired"
            );

            if (isJWTExpired) {
                this.iframeRoutingService.navigate("/logout");
            }
        });
        this.client = new ApolloClient<unknown>({
            cache: new InMemoryCache(),
            link: errorLink.concat(httpLink),
        });
    }

    private mergeAuthHeaders(headers: RequestInit["headers"] = {}) {
        const v2Token = this.localstorageService.getUserAccessKey();
        const v3Token = this.localstorageService.getV3Token();

        if (v3Token) {
            headers["Authorization-V3"] = `${v3Token}`;
        }

        if (v2Token) {
            headers["Authorization-V2"] = `${v2Token}`;
        }

        return headers;
    }
}
