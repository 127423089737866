/**
 * RouterIframeSync class
 *
 * Synchronizes address pathname accordingly to iframe
 *
 * 2021-11-08: Moved most of logic to Iframe.service.ts
 */

import IframeService from "@services/iframe/Iframe.service";
import Services from "@services/Services";
import Container, { Service } from "typedi";
import EXTERNAL_LINK_METHOD from "./EXTERNAL_LINK_METHOD";

@Service()
export default class RouterIframeSync {
    // ---> Variables
    private static instance: RouterIframeSync;

    private isNavigatedByVue = false;
    private queuedRoute: string | undefined;

    // ---> Singleton
    public static getInstance(): RouterIframeSync {
        if (!RouterIframeSync.instance) {
            RouterIframeSync.instance = new RouterIframeSync();
        }

        return RouterIframeSync.instance;
    }

    public openExternalLink(url: string, method: EXTERNAL_LINK_METHOD) {
        if (!url) {
            Services.getDebug().log(
                "ERROR",
                "Failed to open external link, undefined url"
            );
            return;
        }

        if (!method) {
            Services.getDebug().log(
                "ERROR",
                "Failed to open external link, undefined method"
            );
            return;
        }

        Services.getDebug().log("REDIRECTING", url, method);

        switch (method) {
            case EXTERNAL_LINK_METHOD.LOCATION:
                (window.location as any) = url;
                break;
            case EXTERNAL_LINK_METHOD.LOCATION_HREF:
                window.location.href = url;
                break;
            case EXTERNAL_LINK_METHOD.BLANK:
                window.open(url);
                break;
        }
    }

    public onHashChange(): void {
        if (this.isNavigatedByVue) {
            this.isNavigatedByVue = false;
            return;
        }

        const iframeView: any = document.getElementById("iframeView");

        Services.getDebug().log(
            "HISTORY",
            window.location.pathname,
            history.state,
            history
        );

        if (!iframeView) {
            return;
        }

        this.go(window.location.pathname);
    }

    // ---> Actions
    private go(pathname: string): void {
        if (pathname === undefined) {
            return;
        }

        if (typeof pathname != "string") {
            return;
        }

        const iframeView: any = document.getElementById("iframeView");

        if (!iframeView) {
            return;
        }

        if (!Container.get(IframeService).getIframeModel().isReady()) {
            console.log("IFRAME_NOT_READY", pathname);
            this.queuedRoute = pathname;
            return;
        }

        const data = {
            event: "STATE_GO",
            pathname,
        };

        const dataStringified = JSON.stringify(data);

        iframeView.contentWindow.postMessage(dataStringified, "*");
        Container.get(IframeService).authorize(pathname);
    }

    public navigate(pathname): void {
        Services.getDebug().log("ANGULAR_NAVIGATE", pathname);

        this.isNavigatedByVue = true;

        this.go(pathname);
    }

    public update(): void {
        const iframeView: any = document.getElementById("iframeView");

        if (!iframeView) {
            return;
        }

        const data = {
            event: "STATE_GET",
        };

        const dataStringified = JSON.stringify(data);

        iframeView.contentWindow.postMessage(dataStringified, "*");
        Services.getDebug().log(iframeView.contentWindow.localStorage);
    }

    public checkForQueuedRoute() {
        if (!this.queuedRoute) {
            return;
        }

        this.navigate(this.queuedRoute);
    }
}
