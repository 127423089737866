import RouterIframeSync from "@frameworks/vue/router/RouterIframeSync.service";
import Services from "@services/Services";
import { Inject, Service } from "typedi";
import IframeModel from "../models/Iframe.model";

@Service()
export default class LoadAuthenticationCompleteEvent {
    constructor(@Inject() private readonly iframeRouter: RouterIframeSync) {}
    // --> Actions
    public async on(iframe: IframeModel, data: any): Promise<void> {
        Services.getDebug().log("EVENT", "LOAD_AUTHENTICATION_COMPLETE");
        iframe.setReady(true);

        this.iframeRouter.checkForQueuedRoute();
    }
}
