import AppBuilder from "./App.builder";
import AppMounter from "./App.mounter";
import Services from "@services/Services";
import AppRunner from "./App.runner";

export default class App {
    private _builder = new AppBuilder();
    private _runner = new AppRunner();
    private _mounter = new AppMounter();

    // ---> Actions
    public async start(): Promise<void> {
        Services.getDebug().log("APP", "START");
        await Services.getFeatureFlags().getFlags();
        await this._builder.build();
        await Services.getFeatureFlags().getFlags();
        this._run();
        this._mount();
    }

    private _run(): void {
        Services.getDebug().log("APP", "RUNNING", "START");
        this._runner.run();
        Services.getDebug().log("APP", "RUNNING", "DONE");
    }

    private _mount(): void {
        Services.getDebug().log("APP", "MOUNTING", "START");
        this._mounter.mount();
        Services.getDebug().log("APP", "MOUNTING", "DONE");
    }
}
