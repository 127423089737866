
import { defineComponent, inject } from "vue";
import RoutingInteractor from "@interactors/routing/Routing.interactor";

export default defineComponent({
    props: ['subsections'],
    name: "SectionButtons",
    watch: {
        '$route'(to, from) {
            this.currentSubsection = RoutingInteractor.getCurrentSubsection(false, false, true);
            this.currentSection = RoutingInteractor.getCurrentSection(false, false, true);

            if (to !== from) {
                document.getElementById('kt_content')?.scrollTo(0, 0);
            }
        },
    },
    data() {
        let $mitt: any;
        let isDisabled: any = {};
        return {
            isDisabled,
            currentSubsection: RoutingInteractor.getCurrentSubsection(false, false, true),
            currentSection: RoutingInteractor.getCurrentSection(false, false, true),
            $mitt,
        }
    },
    async mounted() {
        this.$mitt = inject("$mitt");
    },
});

