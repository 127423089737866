import Services from "@services/Services";
import { Service } from "typedi";
import IframeModel from "../models/Iframe.model";

@Service()
export default class IframeEventsEmitterModule {
    // ---> Emitters
    public emitLoadAuthentication(
        iframe: IframeModel,
        accessKey: string | null,
        token: string | null,
        userId: string | null,
        redirect: boolean
    ): void {
        this.emit(iframe, {
            event: "LOAD_AUTHENTICATION",
            data: {
                accessKey,
                token,
                userId,
                redirect,
            },
        });
    }

    public emitStartMount(iframe: IframeModel): void {
        this.emit(iframe, {
            event: "START_MOUNT",
        });
    }

    public async emitUpdateCredentialsStorage(
        iframe: IframeModel,
        accessKey: string | null,
        token: string | null,
        userId: string | null
    ): Promise<void> {
        return new Promise((resolve, reject) => {
            const iframeElement = iframe.getElement();

            if (iframeElement === null) {
                reject(new Error("Iframe element not found"));
                return;
            }

            if (iframeElement.contentWindow === null) {
                reject(new Error("Iframe content window not found"));
                return;
            }

            const dataStringified = JSON.stringify({
                event: "UPDATE_CREDENTIALS_STORAGE",
                data: {
                    accessKey,
                    token,
                    userId,
                },
            });

            iframeElement.contentWindow.postMessage(dataStringified, "*");

            const onMessage = (event: any) => {
                let dataParsed;

                try {
                    dataParsed = JSON.parse(event.data);
                } catch (e) {
                    Services.getDebug().log("BAD JSON MESSAGE", e);
                }

                if (dataParsed.event !== "UPDATE_CREDENTIALS_STORAGE_DONE") {
                    return;
                }

                Services.getDebug().log("Event", dataParsed.event);

                try {
                    window.removeEventListener("message", onMessage);
                } catch (e) {
                    console.error(e);
                }

                resolve();
            };

            window.addEventListener("message", onMessage, false);
        });
    }

    // ---> Ations
    public emit(iframe: IframeModel, data: any): void {
        const iframeElement = iframe.getElement();

        if (iframeElement === null) {
            throw new Error("Iframe element not found");
        }

        if (iframeElement.contentWindow === null) {
            throw new Error("Iframe content window not found");
        }

        const dataStringified = JSON.stringify(data);

        iframeElement.contentWindow.postMessage(dataStringified, "*");
    }
}
