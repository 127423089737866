
import { defineComponent, inject } from "vue";

export default defineComponent({
    name: "SaveSectionOptions",
    data() {
        let $mitt: any;
        return {
            $mitt
        }
    },
    mounted() {
        this.$mitt = inject("$mitt");
    },
    methods: {
        saveOptions() {
            this.$mitt.emit("view-options-save-options");
            return;
        }
    }
});
