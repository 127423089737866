import IframeService from "@services/iframe/Iframe.service";
import LocalStorageService from "@services/local-storage/LocalStorage.service";
import ApiService from "@services/api/Api.service";
import { Inject, Service } from "typedi";
import IApiBaseResponse from "@services/api/types/IApiBaseResponse";
import UserService from "@services/user/User.service";
import RESPONSE_STATUS from "@services/api/constants/RESPONSE_STATUS";
import router from "@frameworks/vue/router/clean";
import IFrameInteractor from "@interactors/iframe/Iframe.interactor";
import { Mutations } from "@frameworks/vue/store/enums/StoreEnums";
import store from "@frameworks/vue/store";
import { gql } from "@apollo/client/core";
import GraphQLService from "@services/graphql/Graphql.service";

@Service()
export default class AuthenticationInteractor {
    public constructor(
        @Inject() private readonly _userService: UserService,
        @Inject() private readonly _localStorageService: LocalStorageService,
        @Inject(() => IframeService)
        private readonly _iFrameService: IframeService,
        @Inject() private readonly _apiService: ApiService,
        @Inject(() => IFrameInteractor)
        private readonly _iFrameInteractor: IFrameInteractor,
        private readonly graphQLService: GraphQLService
    ) {}

    public async authenticate(): Promise<void> {
        try {
            // 1. Check if already authenticated
            if (this._userService.isAuthenticationChecked()) {
                throw new Error();
            }

            // 2. Mark authentication as checked
            this._userService.getUser().setAuthenticationChecked(true);

            // 3. Get access key from local storage
            const accessKey: string | null =
                this._localStorageService.getUserAccessKey();

            // 4. Handle access key when it is null
            if (accessKey === null) {
                this.handleUnauthorization();
                throw new Error("Access key is not defined");
            }

            // 5. Authorize session
            const res = await this._apiService.authorizeSession();

            // 6. Check if authorized response
            if (res.status === RESPONSE_STATUS.OK) {
                // 4.1. Update user model & reactivity
                this.updateUserModel(res.data);
            }

            // 7. Handle authorization response
            this.handleAuthorizationResponse(res);
        } catch (e) {
            if ((<Error>e).message.length > 0) {
                this._iFrameService.toggleComponents(false);
            }
        }

        this._iFrameInteractor.setupIframe();
        store.commit(Mutations.SET_ERROR, []);
    }

    private updateUserModel(data: any): void {
        // 1. Get and validate email
        const email = data.email;

        if (!email) {
            throw new Error("Email is not defined");
        }

        // 2. Get and validate user id
        const userId = data.userId;

        if (userId === undefined) {
            throw new Error("User id is not defined");
        }

        // 3. Get and validate role
        const role = data.role;

        if (role === undefined) {
            throw new Error("Role is not defined");
        }

        // 4. Get and validate app version
        const appVersion = data.appVersion;

        if (appVersion === undefined) {
            throw new Error("App version is not defined");
        }

        // 5. Update user model
        this._userService.updateByAuth(email, userId, role, appVersion);
    }

    private async handleAuthorizationResponse(res: IApiBaseResponse) {
        // 1. Get current pathname
        const pathname = window.location.pathname;

        // 2. Check if current pathname is authorized route
        if (this._iFrameService.isAuthorizedRoute(pathname)) {
            // # Auhtorized routes

            // 3. Check if response status is unauthorized
            if (res.status === RESPONSE_STATUS.UNAUTHORIZED) {
                // 3.1 Toggle off authorized components
                this._iFrameService.toggleComponents(
                    this._iFrameService.isComponentsVisible(pathname)
                );

                // 3.2. Prepare iframe to open at login page
                this._iFrameService.setInitPathname("/auth/login");

                // 3.3. Redirect to login page
                router.push("/auth/login");
                localStorage.clear();
                sessionStorage.clear();

                // 3.4. Clear credentials from localstorage
                this._localStorageService.clearCredentials();

                throw new Error("Unauthorized");
            }

            this._iFrameService.toggleComponents(
                this._iFrameService.isComponentsVisible(pathname)
            );
        } else {
            // # Unauhtorized routes

            // 4. Check if response status is unauthorized
            if (res.status === RESPONSE_STATUS.UNAUTHORIZED) {
                // 4.2 Toggle off authorized components
                this._iFrameService.toggleComponents(
                    this._iFrameService.isComponentsVisible(pathname)
                );

                // 4.1. Clear credentials from localstorage
                this._localStorageService.clearCredentials();

                throw new Error("Unauthorized");
            }

            // 5 Toggle on authorized components
            this._iFrameService.toggleComponents(
                this._iFrameService.isComponentsVisible("/")
            );
            // 6. Prepare iframe to open on authorized route
            this._iFrameService.setInitPathname("/");
            // 7. Redirect to current page
            router.push("/");
        }
    }

    private handleUnauthorization(): void {
        // 1. Get current pathname
        const pathname = window.location.pathname;

        // 2. Toggle off authorized components=
        this._iFrameService.toggleComponents(
            this._iFrameService.isComponentsVisible(pathname)
        );

        // 3. Check if current pathname is authorized route
        if (this._iFrameService.isAuthorizedRoute(pathname)) {
            // 4. Prepare iframe to open at login page
            this._iFrameService.setInitPathname("/auth/login");
            localStorage.clear();
            sessionStorage.clear();

            // 5. Redirect to login page
            router.push("/auth/login");
        }
    }
}
