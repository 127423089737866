import { gql } from "graphql-tag";

const GET_GUIDES_PROGRESS = gql`
    query {
        me {
            id
            guides {
                id
                steps {
                    id
                    isCompleted
                }
            }
        }
    }
`;

const COMPLETE_GUIDE_STEP = gql`
    mutation ($guideId: String!, $guideStepId: String!) {
        completeGuideStep(guideId: $guideId, guideStepId: $guideStepId) {
            id
            guides {
                id
                steps {
                    id
                    isCompleted
                }
            }
        }
    }
`;

export { GET_GUIDES_PROGRESS, COMPLETE_GUIDE_STEP };
