import GraphQLService from "@services/graphql/Graphql.service";
import Container, { Service } from "typedi";

@Service()
export class GraphqlInteractor {
    public static getClient() {
        const gqlService = Container.get(GraphQLService);

        return gqlService.client;
    }
}
