import axios, { AxiosResponse } from "axios";
import { Inject, Service } from "typedi";
import ConfigsService from "@services/configs/Configs.service";
import NODE_ENV from "@services/configs/constants/NODE_ENV";

interface IGetFlagsResponse {
    [key: string]: boolean;
}

@Service()
export class FeatureFlagService {
    private static readonly FLAG_ENDPOINT = "/feature-flags";

    private flags: Map<string, boolean> = new Map();
    private fetching = true;
    private successCallback?: () => void;
    private errorCallback?: (error: Error) => void;

    constructor(@Inject() private readonly configService: ConfigsService) {}

    public hasFlag(name: string) {
        return this.flags.get(name) ?? false;
    }

    public isFetching() {
        return this.fetching;
    }

    public onSuccess(cb: () => void) {
        this.successCallback = cb;
    }

    public onError(cb: (error: Error) => void) {
        this.errorCallback = cb;
    }

    public getFlags() {
        if (this.configService.get().getEnv() === NODE_ENV.DEVELOP) {
            return this.getDevFlags();
        }

        return this.getEnvFlags();
    }

    private async getEnvFlags() {
        try {
            const response = await axios.get<
                void,
                AxiosResponse<IGetFlagsResponse>
            >(
                `${this.configService.getByEnv().featureFlagAddress}${
                    FeatureFlagService.FLAG_ENDPOINT
                }`
            );

            Object.keys(response.data).forEach((key) =>
                this.flags.set(key, response.data[key])
            );

            this.fetching = false;
            this.successCallback && this.successCallback();
        } catch (error) {
            console.error(error);

            if (error instanceof Error) {
                this.errorCallback && this.errorCallback(error);
            }
        }
    }

    private async getDevFlags() {
        const flags = await import("../../../feature-flags.local.json");

        Object.keys(flags.default).forEach((key) =>
            this.flags.set(key, flags.default[key])
        );

        return;
    }
}
