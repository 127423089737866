import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "kt_wrapper",
  class: "d-flex flex-column flex-row-fluid wrapper pt-0"
}
const _hoisted_2 = { class: "shadow-sm z-100" }
const _hoisted_3 = { class: "content-wrapper" }
const _hoisted_4 = {
  id: "kt_content",
  class: "d-flex flex-column flex-column-fluid"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Modals = _resolveComponent("Modals")!
  const _component_KTLayout = _resolveComponent("KTLayout")!
  const _component_KTLayoutWrapped = _resolveComponent("KTLayoutWrapped")!
  const _component_IntercomProvider = _resolveComponent("IntercomProvider")!
  const _component_SetupProvider = _resolveComponent("SetupProvider")!
  const _component_SmartlookProvider = _resolveComponent("SmartlookProvider")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Modals),
    _createVNode(_component_KTLayout),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_KTLayoutWrapped, {
          isIframe: _ctx.isIframeEnabled()
        }, null, 8, ["isIframe"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_IntercomProvider),
          _createVNode(_component_SetupProvider),
          _createVNode(_component_SmartlookProvider),
          (_ctx.isSysLoaded)
            ? (_openBlock(), _createBlock(_component_router_view, {
                key: 0,
                class: _normalizeClass(["view", [{ hidden: _ctx.isIframeEnabled() }]])
              }, null, 8, ["class"]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ], 64))
}