
import { defineComponent, inject } from "vue";
import RoutingInteractor from "@interactors/routing/Routing.interactor";

export default defineComponent({
    name: "DashboardOptionsRestoreModals",
    watch: {
        '$route'(to, from) {
            this.subsection = RoutingInteractor.getCurrentSubsection(true, true);
        },
    },
    data() {
        const subsection = RoutingInteractor.getCurrentSubsection(true, true);
        let $mitt: any;

        return {
            subsection,
            $mitt,
        }
    },
    mounted() {
        this.$mitt = inject("$mitt");
        this.$mitt.on("dashboard-options-restore-modal-open", () => { this.showModal() })
    },
    beforeUnmount() {
        this.$mitt.all.delete('dashboard-options-restore-modal-open', () => { this.showModal() });
    },
    methods: {
        showModal(): void {
            document.getElementById("button-dashboard-options-restore-modal")?.click();
            return;
        },
        restoreOptions(allSections = false): void {
            if (allSections) {
                this.$mitt.emit("dashboard-options-restore-options-all");
                return;
            }

            this.$mitt.emit("dashboard-options-restore-options-current");
            return;
        }
    }
});
