import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withScopeId as _withScopeId } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTHeader = _resolveComponent("KTHeader")!
  const _component_KTToolbar = _resolveComponent("KTToolbar")!
  const _component_Toolbar2 = _resolveComponent("Toolbar2")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.headerEnabled)
      ? (_openBlock(), _createBlock(_component_KTHeader, {
          key: 0,
          class: _normalizeClass(["position-relative", { 'd-lg-none': _ctx.isIframe }]),
          isIframe: _ctx.isIframe
        }, null, 8, ["isIframe", "class"]))
      : _createCommentVNode("", true),
    (_ctx.headerEnabled)
      ? (_openBlock(), _createBlock(_component_KTToolbar, {
          key: 1,
          class: "position-relative",
          isIframe: !_ctx.isIframe
        }, null, 8, ["isIframe"]))
      : _createCommentVNode("", true),
    (_ctx.headerEnabled)
      ? (_openBlock(), _createBlock(_component_Toolbar2, {
          key: 2,
          class: "position-relative",
          isIframe: !_ctx.isIframe
        }, null, 8, ["isIframe"]))
      : _createCommentVNode("", true)
  ], 64))
}