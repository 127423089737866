import ApiService from "@frameworks/metronic/core/services/ApiService";
import JwtService from "@frameworks/metronic/core/services/JwtService";
import { Actions, Mutations } from "@/frameworks/vue/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import Services from "@services/Services";
import Container from "typedi";
import UserService from "@services/user/User.service";

export interface User {
    id: string;
    name: string;
    surname: string;
    userId: string;
    siteId: string;
    email: string;
    password: string;
    token: string;
    appVersion: number;
}

export interface UserAuthInfo {
    errors: Array<string>;
    user: User;
    isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
    errors = [];
    user = {} as User;
    isAuthenticated = false; //!!JwtService.getToken();

    /**
     * Get current user object
     * @returns User
     */
    get currentUser(): User {
        return this.user;
    }

    /**
     * Verify user authentication
     * @returns boolean
     */
    get isUserAuthenticated(): boolean {
        return this.isAuthenticated;
    }

    /**
     * Get authentification errors
     * @returns array
     */
    get getErrors(): Array<string> {
        return this.errors;
    }

    /**
     * Get application version
     * @returns number
     */
    get getAppVersion(): number {
        return this.user.appVersion;
    }

    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = error;
    }

    @Mutation
    [Mutations.SET_AUTH](user) {
        this.isAuthenticated = true;
        this.user = user;
        this.errors = [];
    }

    @Mutation
    [Mutations.SET_USER](user) {
        this.user = user;
    }

    @Mutation
    [Mutations.SET_PASSWORD](password) {
        this.user.password = password;
    }

    @Mutation
    [Mutations.SET_APP_VERSION](version) {
        this.user.appVersion = version;
    }

    @Mutation
    [Mutations.PURGE_AUTH]() {
        localStorage.clear();
        sessionStorage.clear();
        this.isAuthenticated = false;
        this.user = {} as User;
        this.errors = [];
        JwtService.destroyToken();
    }

    /**
     * !!! DEPRICATED
     * No longer in use
     */
    @Action
    [Actions.LOGIN](credentials) {
        return new Promise<void>((resolve, reject) => {
            console.log("ACTION LOGIN");

            /*Services.getApi().login(
                credentials.email,
                credentials.password,
            ).then((response) => {
                console.log("Login", response);

                if (response === undefined) {
                    throw new Error("Bad request");
                }

                //Services.getUser().updateByLogin(response);
                Services.getIframe().emitLogin(response.data);

                resolve();
            })
            .catch((e) => {
                console.error("Login error", e);
                this.context.commit(Mutations.SET_ERROR, ["Bad credentials"]);
                reject();
            });*/
        });
    }

    @Action
    [Actions.LOGOUT]() {
        localStorage.clear();
        sessionStorage.clear();
        console.log("UNAUTHORIZIATION HERE");
        this.context.commit(Mutations.PURGE_AUTH);
    }

    @Action
    [Actions.REGISTER](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("registration", credentials)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_AUTH, data);
                    resolve();
                })
                .catch(({ response }) => {
                    this.context.commit(
                        Mutations.SET_ERROR,
                        response.data.errors
                    );
                    reject();
                });
        });
    }

    @Action
    [Actions.FORGOT_PASSWORD](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("forgot_password", payload)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_AUTH, data);
                    resolve();
                })
                .catch(({ response }) => {
                    console.log(response.data.errors);
                    this.context.commit(
                        Mutations.SET_ERROR,
                        response.data.errors
                    );
                    reject();
                });
        });
    }

    @Action
    [Actions.VERIFY_AUTH]() {
        if (JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.get("verify")
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_AUTH, data);
                })
                .catch(({ response }) => {
                    this.context.commit(
                        Mutations.SET_ERROR,
                        response.data.errors
                    );
                });
        } else {
            this.context.commit(Mutations.PURGE_AUTH);
        }
    }

    @Action
    [Actions.UPDATE_USER](payload) {
        ApiService.setHeader();
        return new Promise<void>((resolve, reject) => {
            ApiService.post("update_user", payload)
                .then(({ data }) => {
                    this.context.commit(Mutations.SET_USER, data);
                    resolve();
                })
                .catch(({ response }) => {
                    this.context.commit(
                        Mutations.SET_ERROR,
                        response.data.errors
                    );
                    reject();
                });
        });
    }
}
