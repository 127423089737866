import store from "@/frameworks/vue/store";
import { Actions, Mutations } from "@/frameworks/vue/store/enums/StoreEnums";
import Services from "@services/Services";
import { Service } from "typedi";

@Service()
export default class LoadedEvent {
    // --> Actions
    public async on(): Promise<void> {
        Services.getDebug().log("EVENT", "LOADED");

        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");

        const isSysLoaded = store.getters.isSysLoaded;

        if (!isSysLoaded) {
            //this.handleAuthorization(iframe);
        }

        store.commit(Mutations.SET_SYSTEM_LOADED, true);
    }
}
