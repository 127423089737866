import SITE_USER_ROLES from "@services/user/constants/SITE_USER_ROLES";

const DocMenuConfig = [
    {
        heading: "General",
        feature: "general-pages",
        route: "/dashboard",
        roles: [SITE_USER_ROLES.MANAGER],
        pages: [
            {
                heading: "Home",
                feature: "new-home",
                route: "/home/personal-homepage",
                subsections: ["/home/personal-homepage"],
                svgIcon: "/assets/svg/icons/general/home.svg",
                roles: [SITE_USER_ROLES.MANAGER],
            },
            {
                heading: "Onboarding",
                feature: "new-home",
                isHidden: false,
                route: "/onboarding/",
                subsections: [
                    "/onboarding/first-time-setup",
                    "/onboarding/advanced-guide",
                ],
                svgIcon: "/assets/svg/icons/general/onboarding.svg",
                roles: [SITE_USER_ROLES.MANAGER],
            },
            {
                sectionTitle: "Picking and Packing",
                feature: "picking-and-packing",
                svgIcon: "/assets/svg/icons/general/pap.svg",
                fontIcon: "bi-app-indicator",
                subsections: [
                    "/picking-and-packing/picking-jobs",
                    "/picking-and-packing/packing-jobs",
                    "/picking-and-packing/stock-take",
                ],
                roles: [SITE_USER_ROLES.MANAGER],
                sub: [
                    {
                        heading: "Picking Jobs",
                        route: "/picking-and-packing/picking-jobs",
                        roles: [SITE_USER_ROLES.MANAGER],
                    },
                    {
                        heading: "Packing Jobs",
                        route: "/picking-and-packing/packing-jobs",
                        roles: [SITE_USER_ROLES.MANAGER],
                    },
                    {
                        heading: "Stock Take",
                        route: "/picking-and-packing/stock-take",
                        roles: [SITE_USER_ROLES.MANAGER],
                    },
                ],
            },
            {
                heading: "Dashboard",
                feature: "old-dashboard",
                route: "/dashboard",
                subsections: ["/dashboard"],
                svgIcon: "/media/icons/duotune/general/gen032.svg",
                roles: [SITE_USER_ROLES.MANAGER],
            },
            {
                heading: "Dashboard",
                feature: "new-dashboard",
                route: "/dashboard/overview",
                subsections: ["/dashboard/overview"],
                svgIcon: "/media/icons/duotune/general/gen032.svg",
                roles: [SITE_USER_ROLES.MANAGER],
            },
        ],
    },
    {
        heading: "Admin",
        route: "/admin",
        subsections: ["/admin/users", "/cpanel", "/admin/support-tool"],
        roles: [SITE_USER_ROLES.APP_ADMIN],
        pages: [
            {
                heading: "Admin",
                route: "/admin/users",
                subsections: ["/admin/users"],
                svgIcon: "/media/icons/duotune/food/wine.svg",
                roles: [SITE_USER_ROLES.APP_ADMIN],
            },
            {
                heading: "cPanel",
                route: "/cpanel",
                subsections: ["/cpanel"],
                svgIcon: "/media/icons/duotune/food/martini.svg",
                roles: [SITE_USER_ROLES.APP_ADMIN],
            },
            {
                heading: "Support Tool",
                route: "/admin/support-tool",
                subsections: ["/admin/support-tool"],
                svgIcon: "/assets/svg/icons/general/smile.svg",
                roles: [SITE_USER_ROLES.APP_ADMIN],
            },
        ],
    },
    {
        heading: "orders & shipping",
        route: "/crafted",
        subsections: [
            "/order-list/new/1",
            "/shipping-documents/labels/1",
            "/customers",
        ],
        roles: [
            SITE_USER_ROLES.MANAGER,
            SITE_USER_ROLES.ORDERS_INVENTORY_MANAGER,
            SITE_USER_ROLES.ORDERS_MANAGER,
        ],
        pages: [
            {
                heading: "Orders",
                route: "/order-list/new/1",
                subsections: ["/order-lisct/new/1"],
                svgIcon: "/media/menu/orders.svg",
                fontIcon: "bi-person",
                notExact: true,
                roles: [
                    SITE_USER_ROLES.MANAGER,
                    SITE_USER_ROLES.ORDERS_INVENTORY_MANAGER,
                    SITE_USER_ROLES.ORDERS_MANAGER,
                ],
            },
            {
                heading: "Labels & Manifests",
                route: "/shipping-documents/labels/1",
                subsections: ["/shipping-documents/labels/1"],
                svgIcon: "/media/menu/labels_and_manifests.svg",
                fontIcon: "bi-person",
                notExact: true,
                roles: [
                    SITE_USER_ROLES.MANAGER,
                    SITE_USER_ROLES.ORDERS_INVENTORY_MANAGER,
                    SITE_USER_ROLES.ORDERS_MANAGER,
                ],
            },
            {
                heading: "Customers",
                route: "/customers",
                subsections: ["/customers"],
                svgIcon: "/media/menu/customers.svg",
                fontIcon: "bi-person",
                notExact: true,
                roles: [
                    SITE_USER_ROLES.MANAGER,
                    SITE_USER_ROLES.ORDERS_INVENTORY_MANAGER,
                    SITE_USER_ROLES.ORDERS_MANAGER,
                ],
            },
        ],
    },
    {
        heading: "products & inventory",
        route: "/settings/merge",
        subsections: ["/product-list/all/1", "/settings/merge"],
        roles: [
            SITE_USER_ROLES.MANAGER,
            SITE_USER_ROLES.ORDERS_INVENTORY_MANAGER,
            SITE_USER_ROLES.INVENTORY_MANAGER,
        ],
        pages: [
            {
                heading: "Products",
                route: "/product-list/all/1",
                subsections: ["/product-list/all/1"],
                svgIcon: "/media/menu/products.svg",
                fontIcon: "bi-person",
                notExact: true,
                roles: [
                    SITE_USER_ROLES.MANAGER,
                    SITE_USER_ROLES.ORDERS_INVENTORY_MANAGER,
                    SITE_USER_ROLES.INVENTORY_MANAGER,
                ],
            },
            {
                heading: "Product merge",
                route: "/settings/merge",
                subsections: ["/settings/merge"],
                svgIcon: "/media/menu/product_merge.svg",
                fontIcon: "bi-person",
                roles: [
                    SITE_USER_ROLES.MANAGER,
                    SITE_USER_ROLES.ORDERS_INVENTORY_MANAGER,
                    SITE_USER_ROLES.INVENTORY_MANAGER,
                ],
            },
            // it is removed on purpose, we no longer support lister
            // {
            //     heading: "Product lister",
            //     route: "/settings/lister",
            //     svgIcon: "/media/menu/product_lister.svg",
            //     fontIcon: "bi-person",
            //     roles: [
            //         SITE_USER_ROLES.MANAGER,
            //         SITE_USER_ROLES.INVENTORY_MANAGER,
            //         SITE_USER_ROLES.ORDERS_INVENTORY_MANAGER,
            //     ],
            // },
        ],
    },
    {
        heading: "tools",
        route: "/crafted",
        subsections: [
            "/settings/automation",
            "/reports",
            "/purchase-orders/all",
            "/suppliers",
            "/settings/integrations",
        ],
        roles: [
            SITE_USER_ROLES.MANAGER,
            SITE_USER_ROLES.ORDERS_MANAGER,
            SITE_USER_ROLES.ORDERS_INVENTORY_MANAGER,
            SITE_USER_ROLES.INVENTORY_MANAGER,
        ],
        pages: [
            {
                heading: "Automation",
                route: "/settings/automation",
                subsections: ["/settings/automation"],
                svgIcon: "/media/menu/automation.svg",
                fontIcon: "bi-person",
                roles: [
                    SITE_USER_ROLES.MANAGER,
                    SITE_USER_ROLES.ORDERS_INVENTORY_MANAGER,
                    SITE_USER_ROLES.ORDERS_MANAGER,
                ],
            },
            {
                heading: "Reports",
                route: "/reports",
                subsections: ["/reports"],
                svgIcon: "/media/menu/reports.svg",
                fontIcon: "bi-person",
                roles: [SITE_USER_ROLES.MANAGER],
            },
            {
                heading: "Purchases",
                route: "/purchase-orders/all",
                subsections: ["/purchase-orders/all"],
                svgIcon: "/media/menu/purchases.svg",
                fontIcon: "bi-person",
                notExact: true,
                roles: [
                    SITE_USER_ROLES.MANAGER,
                    SITE_USER_ROLES.INVENTORY_MANAGER,
                    SITE_USER_ROLES.ORDERS_INVENTORY_MANAGER,
                ],
            },
            {
                heading: "Suppliers",
                route: "/suppliers",
                subsections: ["/suppliers"],
                svgIcon: "/media/menu/suppliers.svg",
                fontIcon: "bi-person",
                notExact: true,
                roles: [
                    SITE_USER_ROLES.MANAGER,
                    SITE_USER_ROLES.INVENTORY_MANAGER,
                    SITE_USER_ROLES.ORDERS_INVENTORY_MANAGER,
                ],
            },
            {
                heading: "Integrations",
                route: "/settings/integrations",
                subsections: ["/settings/integrations"],
                svgIcon: "/media/menu/integrations.svg",
                fontIcon: "bi-person",
            },
            {
                sectionTitle: "Settings",
                route: "/settings/",
                subsections: [
                    "/settings/account",
                    "/settings/subscriptions",
                    "/settings/inventory",
                    "/settings/shipping",
                    "/settings/invoicing",
                    "/settings/supporting-documents",
                    "/settings/auto-mcf",
                    "/settings/error-log",
                    "/settings/uspsv",
                    "/logout",
                    "/settings/dashboard/overview",
                    "/settings/home/personal-homepage",
                ],
                svgIcon: "/media/menu/settings.svg",
                fontIcon: "bi-person",
                roles: [
                    SITE_USER_ROLES.MANAGER,
                    SITE_USER_ROLES.ORDERS_MANAGER,
                    SITE_USER_ROLES.ORDERS_INVENTORY_MANAGER,
                    SITE_USER_ROLES.INVENTORY_MANAGER,
                ],
                sub: [
                    {
                        heading: "Account",
                        route: "/settings/account",
                        subsections: ["/settings/account"],
                        roles: [
                            SITE_USER_ROLES.MANAGER,
                            SITE_USER_ROLES.ORDERS_MANAGER,
                            SITE_USER_ROLES.ORDERS_INVENTORY_MANAGER,
                            SITE_USER_ROLES.INVENTORY_MANAGER,
                        ],
                    },
                    {
                        heading: "Subscription",
                        route: "/settings/subscriptions",
                        subsections: ["/settings/subscriptions"],
                    },
                    {
                        heading: "Inventory",
                        route: "/settings/inventory",
                        subsections: ["/settings/inventory"],
                    },
                    {
                        heading: "Shipping",
                        route: "/settings/shipping",
                        subsections: ["/settings/shipping"],
                    },
                    {
                        heading: "Invoicing",
                        route: "/settings/invoicing",
                        subsections: ["/settings/invoicing"],
                    },
                    {
                        heading: "Supporting Documents",
                        route: "/settings/supporting-documents",
                        subsections: ["/settings/supporting-documents"],
                    },
                    {
                        heading: "Auto MCF",
                        route: "/settings/auto-mcf",
                        subsections: ["/settings/auto-mcf"],
                        roles: [
                            SITE_USER_ROLES.MANAGER,
                            SITE_USER_ROLES.ORDERS_INVENTORY_MANAGER,
                            SITE_USER_ROLES.ORDERS_MANAGER,
                        ],
                    },
                    {
                        heading: "Error logs",
                        route: "/settings/error-log",
                        subsections: ["/settings/error-log"],
                        roles: [
                            SITE_USER_ROLES.MANAGER,
                            SITE_USER_ROLES.ORDERS_INVENTORY_MANAGER,
                            SITE_USER_ROLES.ORDERS_MANAGER,
                            SITE_USER_ROLES.INVENTORY_MANAGER,
                        ],
                    },
                    {
                        heading: "USPS",
                        route: "/settings/uspsv",
                        subsections: ["/settings/uspsv"],
                        roles: [
                            SITE_USER_ROLES.MANAGER,
                            SITE_USER_ROLES.ORDERS_INVENTORY_MANAGER,
                            SITE_USER_ROLES.ORDERS_MANAGER,
                        ],
                    },
                    {
                        heading: "Logout",
                        route: "/logout",
                        subsections: ["/logout"],
                        roles: [
                            SITE_USER_ROLES.MANAGER,
                            SITE_USER_ROLES.ORDERS_MANAGER,
                            SITE_USER_ROLES.ORDERS_INVENTORY_MANAGER,
                            SITE_USER_ROLES.INVENTORY_MANAGER,
                        ],
                    },
                ],
            },
        ],
    },
];

export default DocMenuConfig;
