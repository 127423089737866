import LocalStorageService from "@services/local-storage/LocalStorage.service";
import { Inject, Service } from "typedi";
import IApiBaseResponse from "../types/IApiBaseResponse";

@Service()
export default class ResponseHeadersProcessorModule {
    public constructor(
        @Inject() private _localStorageService: LocalStorageService,
    ) { }
    
    // ---> Actions
    public processHeaders(response: IApiBaseResponse): void {
        const headers = response.headers;

        console.log('HEADERS', headers);
        this.processAccessKey(headers);
        this.processAccessKeyToken(headers);
    }

    private processAccessKey(headers: any): void {
        const accessKey: string | undefined = headers["access-key"];

        if (accessKey === undefined) {
            return;
        }

        this._localStorageService.setUserAccessKey(accessKey);
    }

    private processAccessKeyToken(headers: any): void {
        const accessKeyToken: string | undefined = headers["access-key-token"];

        if (accessKeyToken === undefined) {
            return;
        }

        this._localStorageService.setUserToken(accessKeyToken);
    }
}
