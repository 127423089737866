import { gql } from "graphql-tag";

const INTERCOM_QUERY = gql`
    query {
        me {
            id
            email
            username
            image
            name
            firstName
            lastName
            timezone {
                utc
                abbr
                text
                isdst
                value
                offset
                townField
            }
            isOnline
            lastOnline
            registrationDate
            enableDelete
            appVersion
            site {
                id
                subscription {
                    id
                    name
                    description
                    maxOrders
                    pricePerMonth
                    pricePerYear
                    currency
                    isDefault
                    stripeMonthlyPlanId
                    stripeYearlyPlanId
                    v2Plans
                    maxProducts
                    royalMail
                    stripeMonthlyPlanIdLt
                    stripeYearlyPlanIdLt
                    addOn
                    provider
                }
                stripeCustomerId
                externalStripeSubscriptionId
                created
                limitReached
                ordersCount
                siteOrdersCount
                localOrdersCount
                customOrdersLimit
                customProductsLimit
                selfMergeLimit
                componentsPerBundleLimit
                componentToBundlesLimit
                multiWarehouseEnabled
                subBoughtDate
                subscribtionValidUntil
                nextSubscriptionDate
                royalMailSubscriptionId
                refUrl
                name
                website
                siteCountry
                whereSell
                whichCarriers
                describeUsage
                companySize
                industry
                jobRole
                featuresLooking
                planningProducts
                responsibilities
                useShopifyBilling
                phone
                tin
                planningMonthlyOrders
            }
        }
    }
`;

export { INTERCOM_QUERY };
