import REQUEST_METHODS from "@services/api/constants/REQUEST_METHODS";
import LocalStorageService from "@services/local-storage/LocalStorage.service";
import StaticDataService from "@services/static-data/StaticData.service";
import ITimeZone from "@services/static-data/types/ITimeZone";
import HttpClientUtility from "@utilities/http-client/HttpClient.utility";
import IHttpClientOptions from "@utilities/http-client/types/IHttpClientOptions";
import TimeUtility from "@utilities/Time/Time.utility";
import { Inject, Service } from "typedi";
import ITimezondeDetails from "./types/ITimezoneDetails";

@Service()
export default class TimezoneService {
    public constructor(
        @Inject() private readonly _localStorageService: LocalStorageService,
        @Inject() private readonly _staticDataService: StaticDataService,
        @Inject() private readonly _httpClientUtility: HttpClientUtility,
        @Inject() private readonly _timeUtility: TimeUtility
    ) {}

    // ---> Variables
    private readonly _apiUrl = "https://ipapi.co/json";
    private readonly _expirationTimeLong = 1000 * 60 * 60 * 24;
    private readonly _expirationTimeShort = 1000 * 60 * 60;

    // ---> Getters
    public async getTimezoneDetails(): Promise<ITimezondeDetails> {
        const timezondeData = this._localStorageService.getTimezoneDetails();

        if (timezondeData === null) {
            return await this.setupTimezoneDetails();
        }

        return timezondeData;
    }

    public async getTimezone(): Promise<ITimeZone | null> {
        const timezoneDetails: ITimezondeDetails =
            await this.getTimezoneDetails();
        let timezone: ITimeZone | null = null;

        if (timezoneDetails.timezone === undefined) {
            return this.getDefaultTimezone();
        }

        const timezoneTarget = timezoneDetails.timezone;

        this._staticDataService.getTimeZones().some(function (zone, idx) {
            if (zone.text.includes(timezoneTarget)) {
                timezone = zone;
                timezone.town_field = zone.utc.length > 0 ? zone.utc[0] : null;
            }

            zone.utc.some(function (town, idx2) {
                if (town.includes(timezoneTarget)) {
                    timezone = zone;
                    timezone.town_field = town;
                }
            });
        });

        return timezone;
    }

    public getDefaultTimezone(): ITimeZone | null {
        let timezone: ITimeZone | null = null;

        this._staticDataService
            .getTimeZones()
            .some(function (zone: ITimeZone, idx) {
                zone.utc.some(function (town, idx2) {
                    if (idx === 35 && idx2 === 8) {
                        timezone = zone;
                        timezone.town_field = town;

                        return true;
                    }
                });
            });

        return timezone;
    }

    private getDefaultTimezoneDetails(): ITimezondeDetails {
        return {
            country_name: "United Kingdom",
            country_code: "GB",
            expirationDate:
                this._timeUtility.getCurrentTimestamp() +
                this._expirationTimeShort,
        };
    }

    // ---> Fetchers
    public async fetchTimezoneDetails(): Promise<ITimezondeDetails> {
        const options: IHttpClientOptions = {
            method: REQUEST_METHODS.GET,
            url: this._apiUrl,
        };

        try {
            const result = await this._httpClientUtility.send(options);

            if (result.status === 200) {
                return result.data;
            }

            return this.getDefaultTimezoneDetails();
        } catch (e) {
            return this.getDefaultTimezoneDetails();
        }
    }

    // ---> Actions
    public async build(): Promise<void> {
        const timezondeDetails = await this.getTimezoneDetails();

        this.updateTimezoneDetails(timezondeDetails);
    }

    private async updateTimezoneDetails(
        timezondeDetails: ITimezondeDetails
    ): Promise<void> {
        if (this._timeUtility.isExpired(timezondeDetails.expirationDate)) {
            timezondeDetails = await this.setupTimezoneDetails();
        }
    }

    private async setupTimezoneDetails(): Promise<ITimezondeDetails> {
        const timezondeData = await this.fetchTimezoneDetails();

        timezondeData.expirationDate =
            this._timeUtility.getCurrentTimestamp() + this._expirationTimeLong;

        this._localStorageService.setTimezoneDetails(timezondeData);

        return timezondeData;
    }
}
