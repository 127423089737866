enum API_ENDPOINTS {
    LOGIN = "/login",
    ADMIN_LOGIN_BY_SITE_ID = "/admin/login/site-id",
    AUTHENTICATE = "/authenticate",
    AUTHORIZE_SESSION = "/authorize-session",
    BETA = "/beta",
    CREATE_ACCOUNT = "/account/create",
    LOGIN_V3 = "",
}

export default API_ENDPOINTS;
