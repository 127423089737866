import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-a0c30cb0")
const _hoisted_1 = { class: "px-8 border-end border-2 d-flex align-items-center justify-content-center min-w-auto" }
const _hoisted_2 = {
  type: "button",
  class: "btn btn-sm btn-bg"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: '/settings/' + _ctx.sectionRaw + '/' + _ctx.subsection
    }, {
      default: _withCtx(() => [
        _createElementVNode("button", _hoisted_2, _toDisplayString(_ctx.sectionClean) + " Options ", 1)
      ]),
      _: 1
    }, 8, ["to"])
  ]))
}