
import { defineComponent, inject } from "vue";
import dayjs from "dayjs";

const dateColumns = ['created', 'updated', 'lastImport', 'scheduledImport', 'purchased', 'ship_by_date'];

export default defineComponent({
    name: "SupportToolTable",
    props: ['tableData'],
    data() {
        let $mitt;

        return {
            $mitt,
        }
    },
    mounted() {
        this.$mitt = inject("$mitt");
    },
    methods: {
        getDateIfTimestamp(key, value: string) {
            const isDateColumn = dateColumns.includes(key);
            if (!isDateColumn) { return value };

            const date = new Date(isNaN(parseFloat(value)) ? value : parseFloat(value));
            const isValidTimestamp = !isNaN(parseFloat(String(date.getTime())));

            return isValidTimestamp ? (dayjs(date).format("YYYY/MM/DD[ ]HH:mm:ss") !== '1970/01/01 03:00:02' ? dayjs(date).format("YYYY/MM/DD[ ]HH:mm:ss") : value) : value;
        },
        handleOpenDataModal(header, body) {
            this.$mitt.emit("open-data-modal", { header, body })
        },
        isObject(a) {
            return (!!a) && (a.constructor === Object);
        },
        isArray(a) {
            return (!!a) && (a.constructor === Array);
        },
        isObjectOrArray(x) {
            return this.isArray(x) || this.isObject(x);
        }
    }
});
