import SITE_USER_ROLES from "@services/user/constants/SITE_USER_ROLES";
import UserRole from "@services/user/models/UserRole.model";
import { Mutations } from "@/frameworks/vue/store/enums/StoreEnums";
import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import UserModel from "@services/user/models/User.model";
import Services from "@services/Services";

@Module
export default class UserModule extends VuexModule {
    userUpdated = 0;
    /**
     * Get current user object
     * @returns User
     */
    get getUser(): UserModel | null {
        return null;
    }

    get getUserUpdated(): number {
        return this.userUpdated;
    }

    get getUserRole(): UserRole | null {
        return null;
    }

    @Mutation
    [Mutations.SET_USER_ROLE](role: SITE_USER_ROLES) {
        return;
    }

    @Mutation
    [Mutations.USER_UPDATED]() {
        this.userUpdated++;
    }
}
