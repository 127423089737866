import IframeModel from "@services/iframe/models/Iframe.model";
import { Service } from "typedi";

@Service()
export default class IframeSetuperModule {
    // ---> Actions
    public appendIframe(iframe: IframeModel): void {
        // 1. Get kt-content element and validate it
        const ktContent: HTMLElement | null =
            document.getElementById("kt_content");

        if (ktContent === null) {
            throw new Error("kt_content element not found");
        }

        // 2. Get and validate iframe element
        const iframeElement = iframe.getElement();

        if (iframeElement === null) {
            throw new Error("Iframe element not found");
        }

        // 3. Append iframe to kt-content element
        ktContent.appendChild(iframeElement);
    }

    public createIframe(iframe: IframeModel): HTMLIFrameElement {
        const iframeElement = document.createElement("iframe");

        iframeElement.id = "iframeView";
        iframeElement.classList.add("iframe-global");

        // Setup src
        iframeElement.src = iframe.getUrlInit();

        iframe.setElement(iframeElement);

        return iframeElement;
    }
}
