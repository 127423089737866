import NODE_ENV from "../constants/NODE_ENV";

export default class ConfigsModel {
    private _env: NODE_ENV = NODE_ENV.DEVELOP;
    private _host = "localhost";

    // ---> Getters
    public getEnv(): NODE_ENV {
        return this._env;
    }

    public getHost(): string {
        return this._host;
    }

    // ---> Setters
    public setEnv(env: NODE_ENV): void {
        this._env = env;
    }

    public setHost(host: string): void {
        this._host = host;
    }

    // ---> Booleans
    public isTesting(): boolean {
        return this._env === NODE_ENV.STAGING || this._env === NODE_ENV.DEVELOP;
    }

    public isStaging(): boolean {
        return this._env === NODE_ENV.STAGING;
    }

    public isPbe(): boolean {
        return this._env === NODE_ENV.PBE;
    }

    public isDevelop(): boolean {
        return this._env === NODE_ENV.DEVELOP;
    }
}
