import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-09983e52")
const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "d-flex ms-auto" }
const _hoisted_3 = { class: "d-flex d-lg-none justify-content-end" }
const _hoisted_4 = { class: "\n                                                                                d-flex\n                                                                                align-items-stretch\n                                                                                justify-content-between\n                                                                                flex-lg-grow-1\n                                                                                d-lg-none\n                                                                                " }
const _hoisted_5 = {
  class: "d-flex align-items-center d-lg-none ms-n3 me-1",
  title: "Show aside menu"
}
const _hoisted_6 = {
  class: "btn btn-icon btn-active-light-primary",
  id: "kt_aside_mobile_toggle"
}
const _hoisted_7 = { class: "svg-icon svg-icon-2x mt-1" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_SectionButtons = _resolveComponent("SectionButtons")!
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", {
    id: "kt_header",
    class: _normalizeClass([{ 'd-lg-none': !_ctx.subsections }, "header align-items-stretch position-relative pt-0 top-0 start-0"])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass([{
            'container-fluid': _ctx.headerWidthFluid,
            container: !_ctx.headerWidthFluid,
        }, "d-flex align-items-center justify-content-start"])
    }, [
      _createVNode(_component_router_link, {
        to: _ctx.defaultRoute,
        class: "d-flex d-lg-none align-items-center me-8"
      }, {
        default: _withCtx(() => [
          _createElementVNode("a", null, [
            _createElementVNode("img", {
              alt: "Logo",
              src: _ctx.originUrl + _ctx.themeLightLogo,
              class: "h-35px"
            }, null, 8, _hoisted_1)
          ])
        ]),
        _: 1
      }, 8, ["to"]),
      _createVNode(_component_SectionButtons, { subsections: _ctx.subsections }, null, 8, ["subsections"]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("button", {
            class: "btn",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showSearch && _ctx.showSearch(...args)))
          }, "Search")
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("span", _hoisted_7, [
                _createVNode(_component_inline_svg, {
                  src: _ctx.originUrl + '/media/icons/duotune/abstract/abs015.svg'
                }, null, 8, ["src"])
              ])
            ])
          ])
        ])
      ])
    ], 2)
  ], 2))
}