
import { defineComponent } from "vue";
import { useLazyQuery } from "@vue/apollo-composable";
import { INTERCOM_QUERY } from "./IntercomQueries";
import UserService from "@services/user/User.service";
import IntercomService from "@services/intercom/Intercom.service";
import Container from "typedi";

export default defineComponent({
    name: "IntercomProvider",
    watch: {
        $route(to, from) {
            if (this.isComponentMounted) {
                this.$nextTick(() => {
                    this.handleIntercom();
                })
            }
        },
    },
    setup() {
        const Window: any = window;

        return {
            Window,
            isComponentMounted: false,
        };
    },
    mounted() {
        this.handleIntercom();
        this.isComponentMounted = true;
    },
    methods: {
        //this function updates intercom user and checks if user is logged in, if not intercom user gets logged out
        handleIntercom() {
            if (this.Window.Intercom === undefined) {
                return;
            }

            const intercomQuery = useLazyQuery(INTERCOM_QUERY, {
                fetchPolicy: "cache-and-network",
            });

            intercomQuery.onResult((intercomQueryResult: any) => {
                const intercomData = {
                    /** USER / INTERCOM USER DATA */
                    user_id: intercomQueryResult.data.me.id,
                    id: intercomQueryResult.data.me.id,
                    email: intercomQueryResult.data.me.email,
                    user_username: intercomQueryResult.data.me.username,
                    user_image: intercomQueryResult.data.me.image,
                    user_name: intercomQueryResult.data.me.name,
                    user_first_name: intercomQueryResult.data.me.firstName,
                    user_last_name: intercomQueryResult.data.me.lastName,
                    user_timezone: intercomQueryResult.data.me.timezone,
                    user_is_online: intercomQueryResult.data.me.isOnline,
                    user_last_online: intercomQueryResult.data.me.lastOnline,
                    user_registration_date: intercomQueryResult.data.me.registrationDate,
                    user_enable_delete: intercomQueryResult.data.me.enableDelete,
                    user_app_version: intercomQueryResult.data.me.appVersion,
                    /** SITE DATA */
                    site_id: intercomQueryResult.data.me.site.id,
                    site_stripe_customer_id: intercomQueryResult.data.me.site.stripeCustomerId,
                    site_external_stripe_subscription_id: intercomQueryResult.data.me.site.externalStripeSubscriptionId,
                    site_created: intercomQueryResult.data.me.site.created,
                    site_limit_reached: intercomQueryResult.data.me.site.limitReached,
                    site_orders_count: intercomQueryResult.data.me.site.ordersCount,
                    site_local_orders_count: intercomQueryResult.data.me.site.localOrdersCount,
                    site_custom_orders_limit: intercomQueryResult.data.me.site.customOrdersLimit,
                    site_custom_products_limit: intercomQueryResult.data.me.site.customProductsLimit,
                    site_self_merge_limit: intercomQueryResult.data.me.site.selfMergeLimit,
                    site_components_per_bundle_limit: intercomQueryResult.data.me.site.componentsPerBundleLimit,
                    site_component_to_bundles_limit: intercomQueryResult.data.me.site.componentToBundlesLimit,
                    site_multi_warehouse_enabled: intercomQueryResult.data.me.site.multiWarehouseEnabled,
                    site_sub_bought_date: intercomQueryResult.data.me.site.subBoughtDate,
                    site_subscription_valid_until: intercomQueryResult.data.me.site.subscribtionValidUntil,
                    site_next_subscription_date: intercomQueryResult.data.me.site.nextSubscriptionDate,
                    site_royal_mail_subscription_id: intercomQueryResult.data.me.site.royalMailSubscriptionId,
                    site_ref_url: intercomQueryResult.data.me.site.refUrl,
                    site_website: intercomQueryResult.data.me.site.website,
                    site_country: intercomQueryResult.data.me.site.siteCountry,
                    site_where_sell: intercomQueryResult.data.me.site.whereSell,
                    site_which_carriers: intercomQueryResult.data.me.site.whichCarriers,
                    site_describe_usage: intercomQueryResult.data.me.site.describeUsage,
                    site_company_size: intercomQueryResult.data.me.site.companySize,
                    site_industry: intercomQueryResult.data.me.site.industry,
                    site_job_role: intercomQueryResult.data.me.site.jobRole,
                    site_features_looking: intercomQueryResult.data.me.site.featuresLooking,
                    site_planning_products: intercomQueryResult.data.me.site.planningProducts,
                    site_responsibilities: intercomQueryResult.data.me.site.responsibilities,
                    site_use_shopify_billing: intercomQueryResult.data.me.site.useShopifyBilling,
                    site_phone: intercomQueryResult.data.me.site.phone,
                    site_tin: intercomQueryResult.data.me.site.tin,
                    site_planning_monthly_orders: intercomQueryResult.data.me.site.planningMonthlyOrders,
                    /** SUBSCRIPTION DATA */
                    subscription_id: intercomQueryResult.data.me.site.subscription.id,
                    subscription_name: intercomQueryResult.data.me.site.subscription.name,
                    subscription_description: intercomQueryResult.data.me.site.subscription.description,
                    subscription_max_orders: intercomQueryResult.data.me.site.subscription.maxOrders,
                    subscription_price_per_month: intercomQueryResult.data.me.site.subscription.pricePerMonth,
                    subscription_price_per_year: intercomQueryResult.data.me.site.subscription.pricePerYear,
                    subscription_currency: intercomQueryResult.data.me.site.subscription.currency,
                    subscription_is_default: intercomQueryResult.data.me.site.subscription.isDefault,
                    subscription_stripe_monthly_plan_id: intercomQueryResult.data.me.site.subscription.stripeMonthlyPlanId,
                    subscription_stripe_yearly_plan_id: intercomQueryResult.data.me.site.subscription.stripeYearlyPlanId,
                    subscription_v_2_plans: intercomQueryResult.data.me.site.subscription.v2Plans,
                    subscription_max_products: intercomQueryResult.data.me.site.subscription.maxProducts,
                    subscription_royal_mail: intercomQueryResult.data.me.site.subscription.royalMail,
                    subscription_stripe_monthly_plan_id_lt: intercomQueryResult.data.me.site.subscription.stripeMonthlyPlanIdLt,
                    subscription_stripe_yearly_plan_id_lt: intercomQueryResult.data.me.site.subscription.stripeYearlyPlanIdLt,
                    subscription_add_on: intercomQueryResult.data.me.site.subscription.addOn,
                    subscription_provider: intercomQueryResult.data.me.site.subscription.provider,
                    /** COMPANY DATA */
                    company: {
                        id: intercomQueryResult.data.me.site.id,
                        created_at: new Date(intercomQueryResult.data.me.registrationDate).valueOf(),
                        name: intercomQueryResult.data.me.site.name,
                        plan: intercomQueryResult.data.me.site.subscription.name,
                        monthly_spend: Math.round((intercomQueryResult.data.me.site.subscription.pricePerMonth + 1) / 100),
                    },
                };

                //assign an owner to leads and users if isOwner
                const user = Container.get(UserService).getUser();
                const userRole = user.getRole();
                const isUserOwner = userRole.isOwner();
                if (isUserOwner) {
                    intercomData['owner_id'] = intercomData.user_id;
                }

                //intercom tab is closed (must be closed for updating or the update will only appear after reopening the tab) and then intercom user gets updated (or logged in) updated
                Container.get(IntercomService).hideIntercom();
                Container.get(IntercomService).updateIntercom(intercomData);
            });

            intercomQuery.onError((e) => {
                //intercom is shutdown and booted with no data (intercom user logged out)
                Container.get(IntercomService).logoutIntercom();
            });

            intercomQuery.load();
        },
    },
});
