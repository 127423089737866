import { Service } from "typedi";

@Service()
export default class IntercomService {
    private Window: any = window;

    public logoutIntercom() {
        this.Window.Intercom("shutdown");
        this.bootIntercom();
    }

    public bootIntercom() {
        this.Window.Intercom("boot", {
            app_id: "rylosbme",
            email: undefined,
            user_id: undefined,
            ID: undefined,
            siteId: undefined,
        });
    }

    public updateIntercom(data) {
        this.Window.Intercom("update", { ...data });
    }

    public showIntercom() {
        this.Window.Intercom("show");
    }
    public hideIntercom() {
        this.Window.Intercom("hide");
    }
}
