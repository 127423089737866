import ApiService from "./api/Api.service";
import ConfigsService from "./configs/Configs.service";
import DebugService from "./debug/Debug.service";
import IframeService from "./iframe/Iframe.service";
import LocalStorageService from "./local-storage/LocalStorage.service";
import { Container } from "typedi";
import DeviceService from "./device/Device.service";
import StaticDataService from "./static-data/StaticData.service";
import TimezoneService from "./timezone/Timezone.service";
import { FeatureFlagService } from "./feature-flags/FeatureFlags.service";

export default class Services {
    public static getDevice(): DeviceService {
        return Container.get(DeviceService);
    }

    public static getDebug(): DebugService {
        return Container.get(DebugService);
    }

    public static getIframe(): IframeService {
        return Container.get(IframeService);
    }

    public static getConfigs(): ConfigsService {
        return Container.get(ConfigsService);
    }

    public static getLocalStorage(): LocalStorageService {
        return Container.get(LocalStorageService);
    }

    public static getApi(): ApiService {
        return Container.get(ApiService);
    }

    public static getStaticData(): StaticDataService {
        return Container.get(StaticDataService);
    }

    public static getTimezone(): TimezoneService {
        return Container.get(TimezoneService);
    }

    public static getFeatureFlags(): FeatureFlagService {
        return Container.get(FeatureFlagService);
    }
}
