import Services from "@services/Services";
import { Service } from "typedi";
import StatisticsInteractor from "@interactors/statistics/Statistics.interactor";
@Service()
export default class DashboardInteractor {
    // dashboard
    public getChartComponentName(chartType: string): string | undefined {
        const categories =
            Services.getStaticData().getDashboardChartCategories();

        for (const category in categories) {
            if (categories[category].types.includes(chartType)) {
                switch (category) {
                    case "AverageBarChart":
                        return "BarChart";
                    default:
                        return category;
                }
            }
        }

        return undefined;
    }

    public getChartOptions(chartCategory: string): any {
        if (!chartCategory) {
            return;
        }

        switch (chartCategory) {
            case "StatusCounters":
                chartCategory = "StatusCounters";
                break;
            case "GeneralCards":
                chartCategory = "GeneralCards";
                break;
            case "AverageCards":
                chartCategory = "AverageCards";
                break;
            case "HeatmapChart":
                chartCategory = "HeatmapChart";
                break;
            case "StackedBarChart":
                chartCategory = "BarChart";
                break;
            case "DoubleBarChart":
                chartCategory = "BarChart";
                break;
            case "DonutChart":
                chartCategory = "DonutChart";
                break;
            case "PolarAreaChart":
                chartCategory = "DonutChart";
                break;
            case "AverageBarChart":
                chartCategory = "AverageBarChart";
                break;
            case "AverageSplineChart":
                chartCategory = "AverageBarChart";
                break;
        }

        return Services.getStaticData().getDashboardChartCategories()[
            chartCategory
        ];
    }

    // dashboard async
    public async getUserChannels(): Promise<string[] | Error> {
        const data = await StatisticsInteractor.getChannels().catch(
            (err: Error) => {
                console.error(err);
                return err;
            }
        );

        return data;
    }
}
