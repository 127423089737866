
import { defineComponent, ref, inject } from "vue";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import Utilities from "@utilities/Utilities";

export default defineComponent({
    name: "DateFilter",
    props: ['dropdownHandler', 'mutateFilterRef', 'filterRef'],
    components: { Datepicker },
    setup() {
        const originUrl = window.location.origin;

        return {
            originUrl,
            id: Utilities.getIdGenerator().generateV4(),
        };
    },
    data() {
        const $mitt: any = null;

        let predefinedSelection = 'Today';
        const dateValue = ref();
        dateValue.value = Utilities.getDates().getTodayDate(true);

        if (this.filterRef['dateFilter']) {
            dateValue.value = this.filterRef['dateFilter'];
        }

        //!JSON.stringify offsets date so before that we compensate for that change
        const fromDate = new Date(dateValue.value[0]);
        let hoursDiff = fromDate.getHours() - fromDate.getTimezoneOffset() / 60;
        let minutesDiff = (fromDate.getHours() - fromDate.getTimezoneOffset()) % 60;
        fromDate.setHours(hoursDiff);
        fromDate.setMinutes(minutesDiff);

        const toDate = new Date(dateValue.value[1]);
        hoursDiff = toDate.getHours() - toDate.getTimezoneOffset() / 60;
        minutesDiff = (toDate.getHours() - toDate.getTimezoneOffset()) % 60;
        toDate.setHours(hoursDiff);
        toDate.setMinutes(minutesDiff);

        sessionStorage.setItem('dashboard-date-period', JSON.stringify([fromDate, toDate]));

        if (this.filterRef['dateFilterSelectionName']) {
            predefinedSelection = this.filterRef['dateFilterSelectionName'];
        }


        return {
            predefinedSelection,
            dateValue: dateValue.value,
            isCalendarOpen: false,
            $mitt,
        };
    },
    mounted() {
        this.$mitt = inject('$mitt');

        const dropdown = document.getElementById('date-filter-dropdown');

        dropdown?.addEventListener('show.bs.dropdown', event => {
            this.dropdownHandler(true, 'DateFilter');
        });
        dropdown?.addEventListener('hide.bs.dropdown', event => {
            this.dropdownHandler(false, 'DateFilter');
        });
    },
    beforeUnmount() {
        const dropdown = document.getElementById('date-filter-dropdown');

        dropdown?.removeEventListener('show.bs.dropdown', event => {
            this.dropdownHandler(true, 'DateFilter');
        });
        dropdown?.removeEventListener('hide.bs.dropdown', event => {
            this.dropdownHandler(false, 'DateFilter');
        });
    },
    watch: {
        dateValue() {
            //!JSON.stringify offsets date so before that we compensate for that change
            const fromDate = new Date(this.dateValue[0]);
            let hoursDiff = fromDate.getHours() - fromDate.getTimezoneOffset() / 60;
            let minutesDiff = (fromDate.getHours() - fromDate.getTimezoneOffset()) % 60;
            fromDate.setHours(hoursDiff);
            fromDate.setMinutes(minutesDiff);

            const toDate = new Date(this.dateValue[1]);
            hoursDiff = toDate.getHours() - toDate.getTimezoneOffset() / 60;
            minutesDiff = (toDate.getHours() - toDate.getTimezoneOffset()) % 60;
            toDate.setHours(hoursDiff);
            toDate.setMinutes(minutesDiff);


            sessionStorage.setItem('dashboard-date-period', JSON.stringify([fromDate, toDate]));
            this.$mitt.emit('dashboard-date-update', '');
        }
    },
    methods: {
        openMenuMethod() {
            const datepicker: any = this.$refs.datepicker;
            datepicker.openMenu();
            this.isCalendarOpen = true;
            return;
        },
        menuCloseHandler() {
            this.isCalendarOpen = false;
            return;
        },
        handlePredefinedDateSelection(event: any) {
            switch (event.target.innerHTML) {
                case "Today":
                    this.dateValue = Utilities.getDates().getTodayDate(true);
                    break;
                case "Yesterday":
                    this.dateValue = Utilities.getDates().getYesterdayDate(true);
                    break;
                case "This Week":
                    this.dateValue = Utilities.getDates().getThisWeekDate();
                    break;
                case "Last 7 Days":
                    this.dateValue = Utilities.getDates().getLastSevenDaysDate();
                    break;
                case "Last Week":
                    this.dateValue = Utilities.getDates().getLastWeekDate();
                    break;
                case "This Month":
                    this.dateValue = Utilities.getDates().getThisMonthDate();
                    break;
                case "Last 31 Days":
                    this.dateValue = Utilities.getDates().getLastThirtyOneDaysDate();
                    break;
                case "Last Month":
                    this.dateValue = Utilities.getDates().getLastMonthDate();
                    break;
                case "Custom":
                    this.openMenuMethod();
                    return;
                default:
                    this.dateValue = Utilities.getDates().getTodayDate(true);
                    this.mutateFilterRef('dateFilter', this.dateValue);
            }

            const datepicker: any = this.$refs.datepicker;
            datepicker.openMenu();
            datepicker.closeMenu();
            datepicker.selectDate();
            this.handleCustomDateSelection();
            return;
        },
        handleCustomDateSelection() {
            if (Utilities.getDates().getIsToday(this.dateValue)) {
                this.predefinedSelection = 'Today';
                this.mutateFilterRef('dateFilterSelectionName', this.predefinedSelection);
                this.mutateFilterRef('dateFilter', this.dateValue);
                return;
            } else if (Utilities.getDates().getIsYesterday(this.dateValue)) {
                this.predefinedSelection = 'Yesterday';
                this.mutateFilterRef('dateFilterSelectionName', this.predefinedSelection);
                this.mutateFilterRef('dateFilter', this.dateValue);
                return;
            } else if (Utilities.getDates().getIsThisWeek(this.dateValue)) {
                this.predefinedSelection = 'This Week';
                this.mutateFilterRef('dateFilterSelectionName', this.predefinedSelection);
                this.mutateFilterRef('dateFilter', this.dateValue);
                return;
            } else if (Utilities.getDates().getIsLastSevenDays(this.dateValue)) {
                this.predefinedSelection = 'Last 7 Days';
                this.mutateFilterRef('dateFilterSelectionName', this.predefinedSelection);
                this.mutateFilterRef('dateFilter', this.dateValue);
                return;
            } else if (Utilities.getDates().getIsLastWeek(this.dateValue)) {
                this.predefinedSelection = 'Last Week';
                this.mutateFilterRef('dateFilterSelectionName', this.predefinedSelection);
                this.mutateFilterRef('dateFilter', this.dateValue);
                return;
            } else if (Utilities.getDates().getIsThisMonth(this.dateValue)) {
                this.predefinedSelection = 'This Month';
                this.mutateFilterRef('dateFilterSelectionName', this.predefinedSelection);
                this.mutateFilterRef('dateFilter', this.dateValue);
                return;
            } else if (Utilities.getDates().getIsLastThirtyOneDays(this.dateValue)) {
                this.predefinedSelection = 'Last 31 Days';
                this.mutateFilterRef('dateFilterSelectionName', this.predefinedSelection);
                this.mutateFilterRef('dateFilter', this.dateValue);
                return;
            } else if (Utilities.getDates().getIsLastMonth(this.dateValue)) {
                this.predefinedSelection = 'Last Month';
                this.mutateFilterRef('dateFilterSelectionName', this.predefinedSelection);
                this.mutateFilterRef('dateFilter', this.dateValue);
                return;
            }

            this.predefinedSelection = 'Custom';
            this.mutateFilterRef('dateFilterSelectionName', this.predefinedSelection);
            this.mutateFilterRef('dateFilter', this.dateValue);
            return;
        },
    },
});
