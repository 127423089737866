
import { defineComponent, inject } from "vue";
import DashboardInteractor from "@interactors/dashboard/Dashboard.interactor";
import Container from "typedi";


export default defineComponent({
    name: "ChannelFilter",
    props: ['dropdownHandler', 'mutateFilterRef', 'filterRef'],
    components: {},
    data() {
        let allUsersChannels: any;
        const $mitt: any = null;

        return {
            selected: 'All',
            allUserChannels: allUsersChannels,
            checkedChannels: allUsersChannels,
            $mitt,
        }
    },
    async mounted() {
        this.$mitt = inject('$mitt');

        this.allUserChannels = await Container.get(DashboardInteractor).getUserChannels();
        this.checkedChannels = this.allUserChannels;

        const dropdown = document.getElementById('channel-filter-dropdown');

        dropdown?.addEventListener('show.bs.dropdown', event => {
            this.dropdownHandler(true, 'ChannelFilter');

        });
        dropdown?.addEventListener('hide.bs.dropdown', event => {
            this.dropdownHandler(false, 'ChannelFilter');
        });

        if (this.filterRef['channelFilter']) {
            this.checkedChannels = this.filterRef['channelFilter'];
        }
        sessionStorage.setItem('dashboard-active-channels', JSON.stringify(this.checkedChannels))
    },
    beforeUnmount() {
        const dropdown = document.getElementById('channel-filter-dropdown');

        dropdown?.removeEventListener('show.bs.dropdown', event => {
            this.dropdownHandler(true, 'ChannelFilter');

        });
        dropdown?.removeEventListener('hide.bs.dropdown', event => {
            this.dropdownHandler(false, 'ChannelFilter');
        });
    },
    watch: {
        checkedChannels() {
            if (this.allUserChannels.length === this.checkedChannels.length) {
                this.selected = "All";
            } else {
                this.selected = "Custom";
            }

            sessionStorage.setItem('dashboard-active-channels', JSON.stringify(this.checkedChannels))
            this.$mitt.emit('dashboard-channels-update', '');
            console.log("channels_update");

            this.mutateFilterRef('channelFilter', this.checkedChannels)
        },
    },
    methods: {
        isLastCheckedItem(ch) {
            if (this.checkedChannels.length === 1 && this.checkedChannels.includes(ch)) {
                return true;
            }
            return false;
        },
        dropdownMutationHandler(classList: Array<string>) {
            if (classList.includes('show')) {
                this.dropdownHandler(true);
                return;
            }

            this.dropdownHandler(false);
            return;
        }
    },
});
