
import { defineComponent, inject } from "vue";
import Sortable from 'sortablejs';

export default defineComponent({
    name: "Columns",
    props: ['dropdownHandler'],
    setup() {
        const originUrl = window.location.origin;

        return {
            originUrl,
        };
    },
    data() {
        let $mitt: any;
        const headers = [
            "ID",
            "Picking Status",
            "Picker",
            "Orders",
            "Totes",
            "Tags",
            "Comment",
            "Event Log",
            "Action"
        ]

        const tableOptions = {
            'ID': {
                enabled: true,
                order: 0,
                width: 200,
            },
            'Picking Status': {
                enabled: true,
                order: 1,
                width: 100,
            },
            'Picker': {
                enabled: true,
                order: 2,
                width: 200,
            },
            'Orders': {
                enabled: true,
                order: 3,
                width: 200,
            },
            'Totes': {
                enabled: true,
                order: 4,
                width: 200,
            },
            'Tags': {
                enabled: true,
                order: 5,
                width: 200,
            },
            'Comment': {
                enabled: true,
                order: 6,
                width: 200,
            },
            'Event Log': {
                enabled: true,
                order: 7,
                width: 200,
            },
            'Action': {
                enabled: true,
                order: 8,
                width: 200,
            }
        }

        const frozenLeft = 1;
        const frozenRight = 0;

        return {
            $mitt,
            headers,
            tableOptions,
            frozenLeft,
            frozenRight
        }
    },
    async mounted() {
        this.$mitt = inject('$mitt');

        const dropdown = document.getElementById('columns-dropdown');

        dropdown?.addEventListener('show.bs.dropdown', event => {
            this.dropdownHandler(true, 'columns-dropdown');

        });
        dropdown?.addEventListener('hide.bs.dropdown', event => {
            this.dropdownHandler(false, 'columns-dropdown');
        });

        this.initSortableList();
    },
    methods: {
        initSortableList() {
            new Sortable(document.getElementById('columns-dropdown-list'), {
                'sort': true,
                onUpdate: function (evt) {
                    return;
                },
                ignore: 'ul'
            });
            return;
        },
    },
    beforeUnmount() {
        const dropdown = document.getElementById('columns-dropdown');

        dropdown?.removeEventListener('show.bs.dropdown', event => {
            this.dropdownHandler(true, 'columns-dropdown');

        });
        dropdown?.removeEventListener('hide.bs.dropdown', event => {
            this.dropdownHandler(false, 'columns-dropdown');
        });
    },
});
