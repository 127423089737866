import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardOptionsRestoreModal = _resolveComponent("DashboardOptionsRestoreModal")!
  const _component_HomeOptionsRestoreModal = _resolveComponent("HomeOptionsRestoreModal")!
  const _component_SupportToolModal = _resolveComponent("SupportToolModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isDashboardOptionsModal)
      ? (_openBlock(), _createBlock(_component_DashboardOptionsRestoreModal, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.isHomeOptionsModal)
      ? (_openBlock(), _createBlock(_component_HomeOptionsRestoreModal, { key: 1 }))
      : _createCommentVNode("", true),
    _createVNode(_component_SupportToolModal)
  ], 64))
}