
import { defineComponent } from "vue";
import {
    themeLightLogo,
    headerWidthFluid,
    headerLeft,
    asideDisplay,
} from "@frameworks/metronic/core/helpers/config";
import Services from "@services/Services";
import { Mutations } from "@frameworks/vue/store/enums/StoreEnums";
import store from "@frameworks/vue/store";
import Container from "typedi";
import UserService from "@services/user/User.service";
import WindowService from "@services/window/Window.service";
import SectionButtons from '@components/navbar/headerComponents/SectionButtons.vue'
import RoutingInteractor from "@interactors/routing/Routing.interactor";
import { FeatureFlagInteractor } from "@interactors/feature-flags/FeatureFlags.interactor";

export default defineComponent({
    name: "KTHeader",
    props: {
        isIframe: Boolean,
    },
    components: {
        SectionButtons,
    },
    watch: {
        '$route'(to, from) {
            this.handleDesktopNavbarFeatureFlag();
        },
    },
    setup() {
        const originUrl = window.location.origin;

        return {
            themeLightLogo,
            originUrl,
            headerWidthFluid,
            headerLeft,
            asideDisplay,
        };
    },
    data() {
        const defaultRoute = "/order-list/new/1";

        let subsections;
        this.handleDesktopNavbarFeatureFlag();

        return {
            defaultRoute,
            appVersion: true,
            subsections
        }
    },
    methods: {
        handleDesktopNavbarFeatureFlag() {
            if (this.$route.path === '/onboarding/first-time-setup' || this.$route.path === '/onboarding/advanced-guide') {
                this.subsections = RoutingInteractor.getSectionSubsections();
            } else if (FeatureFlagInteractor.hasflag('desktop-navbar')) {
                this.subsections = RoutingInteractor.getSectionSubsections();
            } else {
                this.subsections = false;
            }
        },
        showSearch() {
            store.commit(Mutations.SET_SEARCH_VISIBILITY, true);
        },
        changeAppVersion: async function () {
            const version: number = this.appVersion ? 1 : 0;
            const user = Container.get(UserService).getUser();
            const switchResponse = await Services.getApi().setBetaVersionByUserId(
                Number(user.getId()),
                version,
            );

            console.log(this.appVersion, version, user);

            if (switchResponse === null) {
                this.appVersion = true;
            }

            if (this.appVersion) {
                return false;
            }

            const url = Services.getConfigs().getByEnv().iframeViewAddress + window.location.pathname + window.location.search;
            Container.get(WindowService).openByLocation(url);

            /*const version: number = this.appVersion ? 1 : 0;
            const user = store.getters.currentUser;
            const switchResponse = await Services.getApi().setBetaVersionByUserId(
                Number(user.getId()),
                version,
            );

            if (switchResponse === null) {
                this.appVersion = true;
            }

            console.log(this.appVersion, version, user);

            if (this.appVersion) {
                return false;
            }

            const url = Services.getConfigs().getByEnv().iframeViewAddress + window.location.pathname + window.location.search;
            RouterIframeSync.getInstance().openExternalLink(url, EXTERNAL_LINK_METHOD.LOCATION);*/
        }
    }
});
