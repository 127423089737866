import Services from "@services/Services";
import SearchInteractor from "./search/Search.interactor";
export default class Interactors {
    public static getSearch(): SearchInteractor {
        return SearchInteractor.getInstance(
            Services.getDebug(),
            Services.getConfigs()
        );
    }
}
