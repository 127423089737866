import { Service } from "typedi";
import Services from "@services/Services";
import OnboardingInteractor from "@interactors/onboarding/Onboarding.interactor";

@Service()
export default class HomeInteractor {
    public static getHomeData(): any {
        return Services.getStaticData().getHomeData();
    }

    public static async getOnboardingProgress(): Promise<any> {
        const onboardingFirstTimeSetupProgress =
            await OnboardingInteractor.getFirstTimeSetupProgress();

        let firstTimeComplete = 0,
            firstTimeIncomplete = 0;

        for (const key in onboardingFirstTimeSetupProgress) {
            if (onboardingFirstTimeSetupProgress[key]) {
                firstTimeComplete++;
            } else {
                firstTimeIncomplete++;
            }
        }

        if (firstTimeComplete === 0) {
            return {
                percentage: 10,
                title: "Your “First Time Set-Up” Progress",
                route: "first-time-setup",
            };
        } else if (firstTimeIncomplete !== 0) {
            const total = firstTimeComplete + firstTimeIncomplete;
            const percentage = Math.floor((100 / total) * firstTimeComplete);

            if (percentage !== 100) {
                return {
                    percentage,
                    title: "Your “First Time Set-Up” Progress",
                    route: "first-time-setup",
                };
            }
        }

        const onboardingAdvancedGuideProgress =
            await OnboardingInteractor.getAdvancedGuideProgress();
        let advancedGuideComplete = 0;

        for (const key in onboardingAdvancedGuideProgress) {
            if (onboardingAdvancedGuideProgress[key]) {
                advancedGuideComplete++;
            }
        }

        if (advancedGuideComplete === 0) {
            return {
                percentage: 4,
                title: "Your “Advanced Guide” Progress",
                route: "advanced-guide",
            };
        } else {
            const percentage = Math.floor(
                (100 / Object.keys(onboardingAdvancedGuideProgress).length) *
                    advancedGuideComplete
            );

            if (percentage === 100) {
                return {
                    percentage: 100,
                    title: "Your “Advanced Guide” Progress",
                    route: "advanced-guide",
                    disableProgressBar: true,
                };
            }

            return {
                percentage,
                title: "Your “Advanced Guide” Progress",
                route: "advanced-guide",
            };
        }
    }
}
